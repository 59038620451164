
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  fixDate,
  getHebDayByDate,
  getSafe,
  thousandSeparator,
} from "../../helpers";
import { SummeryBox } from "./SummeryBox";
import { isEmpty, mapKeys } from "lodash";
import { COLOR_SUBMIT } from "../../const/colors";
import BtnTooltip from "../../components/tooltips/BtnTooltip";
import { ClipLoader } from "react-spinners";
import { Fragment } from "react";
import { VAT } from "../orders/manageOrders/constants";
import GoalsForm from "./modals/GoalsForm";
import { HiOutlineChevronDoubleDown,HiOutlineChevronDoubleUp } from "react-icons/hi";
import { BsPrinter, BsPrinterFill, BsTagsFill} from "react-icons/bs";
import { FaUserTag} from "react-icons/fa";
import ReactTable from "../../components/tables/ReactTable";
import { useScreenSize } from "../../Hooks";
import { userColumnsMobile} from "./userColumnsMobile";
export const SummeryBoxTable = ({
  data = [],
  dates = [],
  showTablesData = {},
  setShowTablesData,
  showDateData,
  setShowDateData,
  expenses,
  onClickBox,
  selectedBox,
  last_4_days,
  total,
  totalExtra,
  dayAvg,
  lastYearIncome,
  loading,
  workingDaysUntillEndOfMonth,
  totalIncome,
  incomes=[],
  datTable,
  setDatTable,
  depBreakdownColumns,
  userColumns,
  dateBreakdownColumns,
  supplierAggregatesMemo,
  openDisplayTable,
  allData
}) => {
  //console.log(allData);
  const dapeartmentData = Object.values(expenses);
  const dateData =  useMemo(
    () => {
      if(allData?.userByDate!==undefined) return allData?.userByDate;
      else return[];
    },
    [allData]
  );
  const hebFormatDate = (date)=>{
    let dateArr = date.split("-");
    if(dateArr.length>2)
      return dateArr[2]+"/"+dateArr[1]+"/"+dateArr[0];
    else return false;
  };
  //console.log(dateData);
  //const depTots = allData["boxes"][1];
  const totalDateCost = (wdate)=>{
    const cost =wdate.reduce( (a,{total_cost_after_employer_cost}) => {
      //console.log(a,total_cost_after_employer_cost);
      if(typeof a =="object")
        return a.total_cost_after_employer_cost+total_cost_after_employer_cost;
      return a+total_cost_after_employer_cost;
    });
    if(typeof cost =="object")
      return 0;

    return cost;
  };
  const depTots = useMemo(
    () => {
      if(allData?.boxes && allData?.boxes.length)
        return allData["boxes"][1];
    },
    [allData]
  );
  const getPrecentage =(cost)=>{
    let av =totalIncome/cost*100;
    return av.toFixed(0);
  };
  
  // const data_obj_by_id = useMemo(
  //   () => {
  //     const dataOb = {};
  //     dapeartmentData.forEach( ({id}) => dataOb[id]=false  );
      
  //     //console.log(dataOb);
  //     setShowTablesData(dataOb);
  //   },
  //   [dapeartmentData,showTablesData,setShowTablesData]
  // );
  //console.log(dateArray,data_obj_by_date,selectedDateData);
  // if(dateArray.length && isEmpty(selectedDateData))
  //   setSelectedDateData({ ...data_obj_by_date[dateArray.length - 1] }); 
 
  // useEffect(() => {
  //   if (dateArray.length && !isEmpty(data_obj_by_date)) {
  //     setSelectedDateData({
  //       ...data_obj_by_date[dateArray[dateArray.length - 1]],
  //       index: dateArray.length - 1,
  //     });
  //   }
  // }, [dateArray, data_obj_by_date]);

  return (
    <div >
      {" "}
      <div >
        {loading ? (
          <div style={{ height: "100%" }} className="flex-center">
            <ClipLoader size={60} color="#00a2ea" loading={true} />
          </div>
        ) : (
          <>
            {datTable ? (
              <>   
                <div style={{marginTop:"0px"}} className="flex-between-center" >
                  <i
                  onClick={() => {
                    setDatTable(!datTable);
                  }}
                  style={{ color: COLOR_SUBMIT, fontSize: "1.8rem" }}
                  className=     "fa fa-chevron-right"
                />
                
                <h1  className="summeryBoxByDate" style={{ fontSize: "1.9rem" }}><i >ניתוח לפי מחלקות</i></h1>
                <h1></h1>
              </div>
              
                <div className="summeryBoxContainer " style={{button:{width:"unset"}}}   >
                    {
                    dapeartmentData.map( (depart)=>(  
                      <>
                      <SummeryBox key={ "td"+depart.id } style={{}}selected={false} onClick={() => {}}>
                        <h1  className="margin-y-05"  >
                          {depart.department_name}
                          <FaUserTag onClick={() =>{ 
                            //openDisplayTable(depart,"מידע נוסף",userColumnsMobile,false)
                          }
                          
                        }
                    //console.log("asdasdasd");
                             
                        
                   style={{marginRight:"0.3em"}} fill="#00A2DE"></FaUserTag>
                        </h1>
                        
                        <div >
                          <div  style={{float:"right",marginLeft:"2em"}}>
                            <h2>
                              <b>
                              { "   "+depart.total_cost_after_employer_cost.toFixed(0) }</b>
                              <label>   שכר כולל ע״מ</label>
                    
                              
                            </h2>
                          </div>
                          <div  style={{float:"left"}}>
                            <h2 style={{}}>
                              <b>
                              { depart.total_cost_after_employer_cost==0 ? 0:getPrecentage(depart.total_cost_after_employer_cost) }
                              </b>
                              <label>% עלות מעביד</label>
                          
                              
                            </h2>
                          </div>
                        </div>
                        <div  onClick={() => {
                          const clonesd ={...{showTablesData}};
                          clonesd[depart.id]= !showTablesData[depart.id];                   
                          setShowTablesData(clonesd);
                        }}>
                          { (showTablesData[depart.id]) ? (
                            <HiOutlineChevronDoubleUp style={{fontSize: "3em"}}></HiOutlineChevronDoubleUp>
                          ):
                          (
                              <HiOutlineChevronDoubleDown style={{fontSize: "3em"}}></HiOutlineChevronDoubleDown>
                          )}
                          
                          
                        </div>
                      </SummeryBox>
                      { showTablesData[depart.id] && 
                      <ReactTable 
                      {...{
                        styles: {
                          direction:"inherit  !important",
                          width:"100%",
                          
                          wrapper: { borderLeft:"unset", borderRight:"unset",borderBottom:"unset",borderTop:"unset",width:"100%",borderRadius:"0px",direction:"rtl  !important"},
                          container: { borderLeft:"unset", borderRight:"unset",borderBottom:"unset",borderTop:"unset",width:"100%",borderRadius:"0px",direction:"rtl  !important" },
                        },
                        collapsedSearch: true,
                        translation_endpoint: "departmentTable",
                        title: "",
                        //columns: supplierColumnsMemo,
                        columns: userColumnsMobile,
                        //hiddenColumns: supplierHiddenColumnsMemo,
                        aggregates: supplierAggregatesMemo,
                       
                        showFilters:false,
                        data: depart["subPaRows"],
                        isExportedToExcel: false,
                        loading: loading,
                        isPaginate: false,
                        showFooter: false,
                        showCheckbox: false,
                        sortBy: [{ id: "date" }],
                        overflow: true,
                        
                        
                      }}
                    />
                  }
                    </>
                    ))
                    }
                </div>
              </>   
             
            ):
            (

       
              <> 
                  <div style={{marginTop:"0px"}} className="flex-between-center" >
                    <h1></h1>
                  
                  <h1  className="summeryBoxByDate" style={{ fontSize: "1.9rem" }}><i >ניתוח לפי תאריכים</i></h1>
                  <i
                    onClick={() => {
                      setDatTable(!datTable);
                    }}
                    style={{ color: COLOR_SUBMIT, fontSize: "1.8rem" }}
                    className=     "fa fa-chevron-left"
                  />
                </div>
                <div  className="summeryBoxContainer " style={{button:{width:"unset"},}}  >
                    {
                    dateData.slice(1,dateData.length).map( (wdate)=>( 
                      
                      <>
                      
                      <SummeryBox style={{}}selected={false} onClick={() => {}} key={ Math.floor(Math.random() * 300000)}>
                        <div>
                          <h1  className="flex-between-center  margin-y-05">
                            {"יום "  +getHebDayByDate(wdate[0].date)+"  "+hebFormatDate(wdate[0].date)}
                            <FaUserTag  onClick={() => {
                                //</h1>openDisplayTable(wdate,"נתונים נוספים",userColumnsMobile,false)
                              }
                            } fill="#00A2DE"></FaUserTag>
                          </h1>
                        </div>
                        <div >
                          <div  style={{float:"right",marginLeft:"2em"}}>
                            <h2>
                              <b>
                              { "   "+totalDateCost(wdate).toFixed(1) }</b>
                              <label>   שכר כולל ע״מ</label>
                    
                              
                            </h2>
                          </div>
                          <div  style={{float:"left"}}>
                            <h2 style={{}}>
                              <b>
                              { totalDateCost(wdate)==0 ? 0:getPrecentage(totalDateCost(wdate)) }
                              </b>
                              <label>% עלות מעביד</label>
                          
                              
                            </h2>
                          </div>
                        </div>
                        <div   onClick={() => {
                           
                          const dclones ={...{showDateData}};
                          //console.log(wdate);
                          dclones[wdate[0].date]= !showDateData[wdate[0].date];       
                          //console.log(dclones);            
                          setShowDateData(dclones);
                        }}>
                          { (showDateData[wdate[0].date]) ? (
                            <HiOutlineChevronDoubleUp style={{fontSize: "3em"}}></HiOutlineChevronDoubleUp>
                          ):
                          (
                              <HiOutlineChevronDoubleDown style={{fontSize: "3em"}}></HiOutlineChevronDoubleDown>
                          )}
                          
                          
                        </div>
                      </SummeryBox>
                      { showDateData[wdate[0].date] && 
                      <ReactTable 
                      {...{
                        styles: {
                          direction:"rtl  !important",
                          width:"100%",
                          wrapper: { borderLeft:"unset", borderRight:"unset",borderBottom:"unset",borderTop:"unset",width:"100%",borderRadius:"0px",direction:"rtl  !important" },
                          container: { borderLeft:"unset", borderRight:"unset",borderBottom:"unset",borderTop:"unset",width:"100%",borderRadius:"0px",direction:"rtl  !important" },
                        },
                        collapsedSearch: true,
                        translation_endpoint: "departmentTable",
                        title: "",
                        //columns: supplierColumnsMemo,
                        columns: userColumnsMobile,
                        //hiddenColumns: supplierHiddenColumnsMemo,
                        aggregates: supplierAggregatesMemo,
                       
                        showFilters:false,
                        data: wdate,
                        isExportedToExcel: false,
                        loading: loading,
                        isPaginate: false,
                        showFooter: false,
                        showCheckbox: false,
                        sortBy: [{ id: "date" }],
                        overflow: true,
                        
                        
                            }}
                          />
                        }
                      </>
                    ))
                    }
                </div>
              </>
            )
            }
          </>
        )}
      </div>
      
     
    </div>
  );
};