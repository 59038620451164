import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

// ======== REDUX ========
import { connect, Provider, useDispatch, useSelector } from "react-redux";

import {
  Button,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { default as Grid } from "@mui/material/Unstable_Grid2";

import Dropdown from "../../components/dropdown/Dropdown";
import DateRangePicker from "../../components/datepicker/DateRangePicker";
import moment from "moment";
import MultiSelectCheckbox from "../../components/dropdown/MultiSelectCheckBox";
import ButtonIcon from "../../components/buttons/ButtonIcon";
import { FaSearchMinus } from "react-icons/fa";
import Axios from "axios";
import {
  BRANCH_GOALS,
  EXTRA_FILTER_OPTIONS,
  GROUPED_SOLD_DISHES,
  SALE_EVENTS,
  SOLD_DISH_TYPE_DISH,
  SOLD_DISH_TYPE_MEAL,
  SOLD_DISH_TYPE_RAW,
  SOLD_DISH_TYPE_UNLINKED,
  THROW_EVENTS,
  UNLINKED_ITEMS,
} from "./const";
import DropdownSelect from "../../components/dropdown/DropdownSelect";
import { submitWrapper } from "../../helpers";
import { setError } from "../../redux/actions/errorAction";
import { INVENTORY_TYPE_THROW } from "../inventory/constants";

export const Filters = ({ setData }) => {
  // ======== CONSTANTS ========
  const initialSelectedDates = {
    startDate: new Date(moment().startOf("month")),
    endDate: new Date(moment()),
    key: "selection",
    label: `החודש`,
  };

  // ======== HOOKS ========

  const formRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const dispatch = useDispatch();

  const { branches, token } = useSelector((state) => {
    const { branches, auth } = state;
    return {
      branches: branches.branches.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
      token: auth.token,
    };
  });

  // ======== STATES ========

  const [selectedDates, setSelectedDates] = useState(
    () => initialSelectedDates
  );
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedExtraFilter, setSelectedExtraFilter] = useState(
    EXTRA_FILTER_OPTIONS[0]
  );

  // ======== HANDLERS ========
  const handleRsetFilters = () => {
    setSelectedBranches([]);
    setSelectedExtraFilter(EXTRA_FILTER_OPTIONS[0]);
    setSelectedDates(initialSelectedDates);
  };

  const getSoldDishes = () => {
    return Axios.get(GROUPED_SOLD_DISHES, {
      params: {
        start_date: moment(selectedDates.startDate).format("YYYY-MM-DD"),
        end_date: moment(selectedDates.endDate).format("YYYY-MM-DD"),
        branch_ids: selectedBranches,
        link_status: selectedExtraFilter.id,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const getUnlinkedItems = () => {
    return Axios.get(UNLINKED_ITEMS, {
      params: {
        branchId: selectedBranches,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const getInventoryThrowEvents = () => {
    return Axios.get(THROW_EVENTS, {
      params: {
        branchId: selectedBranches,
        start_date: moment(selectedDates.startDate).format("YYYY-MM-DD"),
        end_date: moment(selectedDates.endDate).format("YYYY-MM-DD"),
        typeFilter: [INVENTORY_TYPE_THROW],
        without_logs: true,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const getFoodCostBranchGoals = () => {
    return Axios.get(BRANCH_GOALS, {
      params: {
        name: "Food Cost",
        branch_ids: selectedBranches,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const getInventorySaleEvents = () => {
    return Axios.get(SALE_EVENTS, {
      params: {
        start_date: moment(selectedDates.startDate).format("YYYY-MM-DD"),
        end_date: moment(selectedDates.endDate).format("YYYY-MM-DD"),
        branch_ids: selectedBranches,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const getReducedData = (soldDishes, by_date, throwEvents) => {
    const reducedData = soldDishes.reduce(
      (acum, soldDish) => {
        const { total, amount, worth, type, date } = soldDish;

        switch (type) {
          case SOLD_DISH_TYPE_UNLINKED:
            acum.unlinkedDishes += total;
            break;
          case SOLD_DISH_TYPE_MEAL:
            acum.mealsWorth += total;
          case SOLD_DISH_TYPE_RAW:
          case SOLD_DISH_TYPE_DISH:
            acum.comulative += total;
            acum.amount += amount;
            acum.worth += worth;
        }

        return acum;
      },
      {
        comulative: 0,
        mealsWorth: 0,
        unlinkedDishes: 0,
        amount: 0,
        worth: 0,
      }
    );

    reducedData.throws = throwEvents.reduce(
      (sum, throwEvent) => sum + throwEvent.worth,
      0
    );

    const { minDate, maxDate } = by_date.reduce(
      (acum, soldDishByDate, i) => {
        const dateObject = moment(soldDishByDate.date);

        if (i === 0) {
          acum.minDate = dateObject;
          acum.maxDate = dateObject;
        } else if (dateObject.isAfter(acum.maxDate)) {
          acum.maxDate = dateObject;
        } else if (dateObject.isBefore(acum.minDate)) {
          acum.minDate = dateObject;
        }

        return acum;
      },
      { minDate: null, maxDate: null }
    );

    reducedData.minDate = minDate;
    reducedData.maxDate = maxDate;

    return reducedData;
  };

  const handleSubmit = async () => {
    const response = await Axios.all([
      getSoldDishes(),
      getFoodCostBranchGoals(),
      getUnlinkedItems(),
      getInventoryThrowEvents(),
      getInventorySaleEvents(),
    ]);

    const [
      { data: soldDishesResponse },
      { data: foodCostGoalsResponse },
      { data: unlinkedItemsResponse },
      { data: throwEventsResponse },
      { data: saleEventsResponse },
    ] = response;

    const { by_branch, by_code, by_date } = soldDishesResponse;

    if (by_code.length === 0) {
      dispatch(setError("", "לא נמצאו נתונים בשרת", false, true));
      return;
    }

    const reducedData = getReducedData(by_code, by_date, throwEventsResponse);

    setData({
      soldDishes: soldDishesResponse,
      branchesFoodCostGoal: foodCostGoalsResponse,
      unlinkedItems: unlinkedItemsResponse,
      throwEvents: throwEventsResponse,
      saleEvents: saleEventsResponse,
      reducedData,
    });
  };

  // ======== CONSTANTS ========

  // ======== EFFECTS ========

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  return (
    <Grid
      container
      spacing={2}
      sx={{
        margin: "20px",
      }}
    >
      <Grid xs={12} md={4} lg={2}>
        <Dropdown text={selectedDates.label}>
          <DateRangePicker
            selectedRange={selectedDates}
            setSelectedRange={setSelectedDates}
          />
        </Dropdown>
      </Grid>
      <Grid xs={12} md={4} lg={2}>
        <MultiSelectCheckbox
          title="סניפים"
          checked={selectedBranches}
          setChecked={setSelectedBranches}
          options={branches}
        />
      </Grid>
      <Grid xs={12} md={4} lg={2}>
        <DropdownSelect
          options={EXTRA_FILTER_OPTIONS}
          selected={selectedExtraFilter}
          setSelected={(value) => setSelectedExtraFilter(value)}
        />
      </Grid>
      <Grid md={1} lg={0.5}>
        <ButtonIcon
          icon={FaSearchMinus}
          className="btn--normal"
          style={{ height: "100%" }}
          onClick={handleRsetFilters}
          alt="איפוס סינון"
        />
      </Grid>
      <Grid xs={12} md={2}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => submitWrapper(handleSubmit, dispatch)}
        >
          בצע
        </Button>
      </Grid>
    </Grid>
  );
};

export default Filters;
