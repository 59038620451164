import { getDay, isAfter, isBefore, isEqual } from "date-fns";
import {
  mapKeys,
  cloneDeep,
  isUndefined,
  reduce,
  isEqual as isEqualLodash,
  isArray,
} from "lodash";
import moment from "moment";
import { useEffect, useRef } from "react";
import { PRECENT_SIGN, SHEKEL_SIGN } from "../const";
import { startLoading, stopLoading } from "../redux/actions/loaderAction";
import { setError } from "../redux/actions/errorAction";

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

/*
  Arguments: a: object, b: object
  Returns: if objects is equal (boolean) FALSE 
           else (array) array of differences
*/
export const objectComapre = (a, b) =>
  !isEqualLodash(a, b) &&
  reduce(
    a,
    (result, value, key) =>
      isEqualLodash(value, b[key])
        ? result
        : result.concat({ [key]: { old: value, new: b[key] } }),
    []
  );

export const getPriceAfterDiscount = (price, discount) => {
  return price * (1 - discount / 100);
};

//return the absolute position class for component
export const getClassAbsPos = (locale) => (locale === "heb" ? "right" : "left");

export function getSafe(fn, defaultVal = false) {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}

//return the direction class for component
export const getClassDirection = (locale) =>
  locale === "heb" ? "right-to-left" : "left-to-right";

export const fixHour = (hour) => {
  if (hour) {
    const arr = hour.split(":");
    return `${arr[0]}:${arr[1]}`;
  }
  return "";
};

export const getDisassembledHour = (hour) => {
  if (hour !== null) {
    const arr = hour.split(":");
    return { hour: Number(arr[0]), min: Number(arr[1]) };
  }
  return { hour: 0, min: 0 };
};

export const toSeconds = (time_str) => {
  // Extract hours, minutes and seconds
  if (time_str === null) return 0;
  if (time_str.length === 8) time_str = time_str.slice(0, 5);

  var parts = time_str.split(":");
  // compute  and return total seconds
  return (
    parts[0] * 3600 + // an hour has 3600 seconds
    parts[1] * 60
  );
};

export const getTotalHours = (start, end, fixed = 1) => {
  if (!(start && end)) return 0;
  const start_seconds = toSeconds(start);
  const end_seconds = toSeconds(end);
  let diff = Math.abs(end_seconds - start_seconds);
  if (start_seconds > end_seconds) {
    diff = toSeconds("24:00") - diff;
  }
  return Number(Number(diff / 3600).toFixed(fixed)); // HOURS
};

export const getHebDay = (index) => {
  const daysTitle = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"];
  return daysTitle[index];
};
export const getShortHebDay = (index) => {
  const daysTitle = ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"];
  return daysTitle[index];
};
export const addTax = (value) => Number((value * 1.17).toFixed(0));
export const decreaseTax = (value) => Number((value * 0.864).toFixed(0));
export const getHebDayByDate = (date) => {
  const index = getDay(new Date(date));
  return getHebDay(index);
};

export const checkIfDateInsideRange = (date, range) => {
  const dateToCheck = new Date(date);
  const start = new Date(range.start);
  const end = new Date(range.end);
  if (isEqual(dateToCheck, start) || isEqual(dateToCheck, end)) return true;
  return isAfter(dateToCheck, start) && isBefore(dateToCheck, end);
};

export const fixDate = (date, format = "DD/MM/YY") => {
  return moment(date).format(format);
};

export const fixDateHours = (date, format = "DD/MM/YY HH:mm:ss") => {
  return moment(date).format(format);
};

export const dateReqFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const dateAndTime = (
  dateAndTimeString,
  dateFormat = "DD.MM.YY",
  timeFormat = "HH:mm"
) => {
  const dateObj = moment(dateAndTimeString);
  return [dateObj.format(dateFormat), dateObj.format(timeFormat)];
};

export const dateSubtract = (
  date = new Date(),
  value = 1,
  subtract = "days"
) => {
  return moment(date).subtract(value, subtract);
};
export const getDatesDiff = (date1, date2, diffBy = "days") => {
  return moment(date1).diff(date2, diffBy);
};

export const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export async function printView(viewContent) {
  var newWin = window.open("", "Print-Window");
  newWin.document.open();
  newWin.document.write(viewContent);
  newWin.window.onafterprint = newWin.window.close;

  setTimeout(function () {
    newWin.print();
  }, 200);
}

export const twoZeroAfterDot = (param) => {
  return Number(param).toFixed(2);
};

export const numberFormat = (
  param,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
  style = "decimal" // decimal | currency | percent | unit
) => {
  const num = Number(param);

  if (isNaN(num)) {
    console.error("unexpected type of ", param);
    return param;
  }

  return num.toLocaleString("he-IL", {
    minimumFractionDigits,
    maximumFractionDigits,
    style,
    currency: "ILS",
  });
};

export const typingNumberFormat = (
  param,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) => {
  let num,
    stringEnd = "",
    foundDot = false;

  switch (typeof param) {
    case "string":
      param = param.replace(/\D/g, (match) => {
        if (match === "." && !foundDot) {
          foundDot = true;
          return match;
        }
        return "";
      });
      if (param.endsWith(".")) stringEnd = ".";
      num = Number(param);
      break;
    case "number":
      num = param;
      break;
    default:
      console.error("unexpected type of ", param);
      return param;
  }

  const formatted = num.toLocaleString("he-IL", {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formatted + stringEnd;
};

export const thousandSeparator = (param) => {
  let num = Number(Number(param).toFixed(2));

  return getSafe(
    () => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ""
  );
};

export const filterObjectArrayBy = (array, key, value) => {
  if (!(array && key)) {
    throw new Error("array or key are missing");
  }
  return array.filter((x) => x[key] === value);
};

export const setEmployeeHierarchy = (employees) => {
  let departments = mapKeys(employees, "department_id");
  departments = Object.values(departments);
  departments = departments.map((department) => {
    return {
      name: department.department_name,
      id: department.department_id,
      employees: employees
        .map((employee) => {
          if (employee.department_id === department.department_id) {
            return employee;
          } else return null;
        })
        .filter((el) => el != null),
    };
  });
  return departments;
};

const checkMatrixLength = (notPlaced_matrix) => {
  for (const key in notPlaced_matrix) {
    if (notPlaced_matrix[key].length) {
      return true;
    }
  }
  return false;
};

export const compareString = (a, b) => {
  var A = (a || "").toUpperCase();
  var B = (b || "").toUpperCase();
  if (A < B) {
    return -1;
  }
  if (A > B) {
    return 1;
  }
  return 0;
};

export const compareHours = (a, b, desc = false) => {
  const A = toSeconds(a);
  const B = toSeconds(b);
  return (A - B) * (desc ? -1 : 1);
};

export const setHierarchy = async (schedule, notPlaced_matrix = null) => {
  //Unique array of shifts
  let schedule_clone = await cloneDeep(schedule);
  schedule_clone.sort((a, b) => a.shift.sequence - b.shift.sequence);
  // var shifts = [...new Set(schedule.map((item) => item.shift.name))];
  var shifts = mapKeys(schedule_clone, "shift.id");

  shifts = Object.values(shifts).sort(
    (a, b) => a.shift.sequence - b.shift.sequence
  );

  var departmentsInfo = mapKeys(schedule_clone, "department.id");

  schedule_clone = schedule_clone.sort(
    (a, b) => a.department?.sequence - b.department?.sequence
  );

  //Assigns to the shifts array the departments that work in them
  shifts = shifts.map((item) => {
    const { shift } = item;
    return {
      name: shift.name,
      id: shift.id,
      departments: [
        ...new Set(
          schedule_clone
            .map((employee) => {
              if (shift.id === employee.shift.id) {
                return employee.department.id;
              }
              return null;
            })
            .filter((el) => el !== null)
        ),
      ],
    };
  });
  schedule_clone = schedule_clone.sort(
    (a, b) => a.role?.sequence - b.role?.sequence
  );
  //Assigns to the shifts array the departments that work in them
  shifts.forEach((shift) => {
    shift.departments = shift.departments.map((id) => {
      return {
        name: departmentsInfo[id].department.name,
        id,
        sequence: [
          ...new Set(
            schedule_clone
              .map((employee) => {
                if (
                  id === employee.department.id &&
                  shift.id === employee.shift.id
                )
                  return employee.role?.sequence;
                return null;
              })
              .filter((el) => el !== null)
          ),
        ],
      };
    });
  });

  //Assigns to the departments array inside shifts the employees that work in them
  shifts.forEach((shift) => {
    shift.departments.forEach((department) => {
      department.sequence = department?.sequence.map((sequence) => {
        return {
          name: sequence,
          employees: schedule_clone.filter(
            (employee) =>
              shift.id === employee.shift.id &&
              department.id === employee.department.id &&
              sequence === employee.role?.sequence
          ),
        };
      });
    });
  });

  if (!notPlaced_matrix) return shifts;

  const clone_notPlaced_matrix = await cloneDeep(notPlaced_matrix);

  let counter = -1;

  var notPlaced = {
    name: "עובדים לא משובצים",
    departments: [
      {
        name: null,
        sequence: [],
      },
    ],
  };

  while (checkMatrixLength(clone_notPlaced_matrix)) {
    let employees = [];
    for (let i = 0; i < 7; i++) {
      if (!clone_notPlaced_matrix[i].length) continue;

      employees.push(clone_notPlaced_matrix[i].pop());
    }

    notPlaced.departments[0].sequence.push({ name: counter, employees });
    --counter;
  }

  shifts.push(notPlaced);
  return shifts;
};

export const changeKeyObjects = (arr, replaceKeys) => {
  return arr.map((item) => {
    const newItem = {};
    Object.keys(item).forEach((key) => {
      if (!isUndefined(replaceKeys[key]))
        newItem[replaceKeys[key]] = item[[key]];
    });
    return newItem;
  });
};

export const handleEnter = (event) => {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
};

export const fixPrice = (price = 0, afterDot = 0, round = true) => {
  let fixedPrice;
  const numericPrice = Number(price);

  if (round) {
    fixedPrice = numericPrice.toFixed(afterDot);
  } else {
    const stringPrice = numericPrice.toString();
    const parts = stringPrice.split(".");
    parts[1] = parts[1]?.slice(0, afterDot);
    fixedPrice = parts.join(".");
  }

  return `${SHEKEL_SIGN}${thousandSeparator(fixedPrice)}`;
};

export const fixPriceAndNothing = (price = "", fixed = 0) => {
  if (price == null || price == "") {
    return "";
  } else {
    return `${SHEKEL_SIGN}${thousandSeparator(Number(price).toFixed(fixed))}`;
  }
};

export const fixPricePrecent = (price = 0, fixed = 0) => {
  return `${thousandSeparator(Number(price).toFixed(fixed))}${PRECENT_SIGN}`;
};
export const fixPercentage = (price = 0, fixed = 0) =>
  `${Number(price).toFixed(fixed)}%`;

export const fixNegativePrice = (price = 0, fixed = 0) => {
  if (price < 0) {
    price = Math.abs(price);
    return `(${SHEKEL_SIGN}${thousandSeparator(Number(price).toFixed(fixed))})`;
  }
  return `${SHEKEL_SIGN}${thousandSeparator(Number(price).toFixed(fixed))}`;
};

export function deepCompare() {
  var i, l, leftChain, rightChain;

  function compare2Objects(x, y) {
    var p;

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (
      isNaN(x) &&
      isNaN(y) &&
      typeof x === "number" &&
      typeof y === "number"
    ) {
      return true;
    }

    // Compare primitives and functions.
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true;
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if (
      (typeof x === "function" && typeof y === "function") ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)
    ) {
      return x.toString() === y.toString();
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      return false;
    }

    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    if (x.prototype !== y.prototype) {
      return false;
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false;
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false;
      } else if (typeof y[p] !== typeof x[p]) {
        return false;
      }
    }

    for (p in x) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false;
      } else if (typeof y[p] !== typeof x[p]) {
        return false;
      }

      switch (typeof x[p]) {
        case "object":
        case "function":
          leftChain.push(x);
          rightChain.push(y);

          if (!compare2Objects(x[p], y[p])) {
            return false;
          }

          leftChain.pop();
          rightChain.pop();
          break;

        default:
          if (x[p] !== y[p]) {
            return false;
          }
          break;
      }
    }

    return true;
  }

  if (arguments.length < 1) {
    return true; //Die silently? Don't know how to handle such case, please help...
    // throw "Need two or more arguments to compare";
  }

  for (i = 1, l = arguments.length; i < l; i++) {
    leftChain = []; //Todo: this can be cached
    rightChain = [];

    if (!compare2Objects(arguments[0], arguments[i])) {
      return false;
    }
  }

  return true;
}

export const isContainHebrew = (string) => {
  return /[\u0590-\u05FF]/.test(string);
};

export const formatNumber = (num, decimalPlaces) => {
  if (isNaN(num)) {
    return NaN;
  }

  const multiplier = Math.pow(10, decimalPlaces);
  return num === 0 ? 0 : Math.floor(num * multiplier) / multiplier;
};

export const getSafeNumber = (input) => {
  const converted = Number(input);
  return isNaN(converted) ? 0 : converted;
};

export const getSafeDivision = (numerator, denominator) => {
  if (
    isNaN(numerator) ||
    isNaN(denominator) ||
    numerator === 0 ||
    denominator === 0
  )
    return 0;

  return numerator / denominator;
};

export const dynamicToFixed = (number, decimalPlaces = 2) => {
  return Number(Number(number).toFixed(decimalPlaces));
};

export const changeFileExtension = (filename, newExtension) => {
  let lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    // If there's no dot in the filename, simply append the new extension
    return filename + "." + newExtension;
  } else {
    // Otherwise, replace the existing extension with the new one
    return filename.substring(0, lastDotIndex + 1) + newExtension;
  }
};

//useful to calculate all types of "צפי סוף תקופה"
export const predictByDates = (
  startDate,
  endDate,
  value,
  daysOfWork = ["1", "2", "3", "4", "5", "6", "7"],
  endOfPeriod = moment(endDate).endOf("month")
) => {
  startDate = moment(startDate);
  endDate = moment(endDate);
  endOfPeriod = moment(endOfPeriod);
  const currentDate = startDate.clone();

  let daysCountStartToEnd = 0;
  let daysCountEndToEndOfPeriod = 0;

  while (currentDate.isSameOrBefore(endOfPeriod)) {
    const currentDay = (currentDate.weekday() + 1).toString();
    if (daysOfWork.includes(currentDay)) {
      if (currentDate.isAfter(endDate)) {
        daysCountEndToEndOfPeriod++;
      } else {
        daysCountStartToEnd++;
      }
    }

    currentDate.add(1, "day");
  }

  const avgPerDayInGivenPeriod = getSafeDivision(value, daysCountStartToEnd);

  return avgPerDayInGivenPeriod * daysCountEndToEndOfPeriod + value;
};

export const submitWrapper = async (cb, dispatch, params = []) => {
  try {
    dispatch(startLoading("טוען..."));
    return await cb(...params);
  } catch (e) {
    console.error(e);
    dispatch(setError("אנא פנה לתמיכה של רסטיגו"));
  } finally {
    dispatch(stopLoading());
  }
};
