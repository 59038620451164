import React, { useEffect, useMemo, useState } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import { observer } from "mobx-react";

import { logout } from "../../redux/actions/loginAction";
import Button from "../../components/buttons/Button";
import {
  setActive,
  setIsFrame,
  setTitle,
} from "../../redux/actions/publicAction";
import ButtonIcon from "../../components/buttons/ButtonIcon";
import { SummeryContainer } from "./SummeryContainer";
import he from "date-fns/locale/he";
import { useStore } from "../../stores/setupContext";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";

import LayoutContent from "../../layouts/LayoutContent";
import { setError } from "../../redux/actions/errorAction";
import DisplayTable from "./modals/DisplayTable";
import SimpleDisplayTable from "./modals/SimpleDisplayTable";
import GoalsForm from "./modals/GoalsForm";
import { decodeHtml } from "../../helpers";
import { fixDate } from "../../helpers";
import { ClipLoader } from "react-spinners";
import { hebStaticRanges } from "../../components/datepicker/const";
import DateRangePicker from "../../components/datepicker/DateRangePicker";
import DefinedRange from "../../components/datepicker/date-range-picker/DefinedRange";
import Dropdown from "../../components/dropdown/Dropdown";
import { COLOR_ABORT, COLOR_CONFIRM } from "../../const/colors";

import { Skeleton } from "@mui/lab";
import _, { isEmpty } from "lodash";
import ReactTable from "../../components/tables/ReactTable";

import { SummeryBox } from "./SummeryBox";

import {
  depBreakdownColumns,
  dHiddenColumns,
  dDefaultVisibleColumns,
} from "./columns";
import {
  dateBreakdownColumns,
  dateHiddenColumns,
  dateDefaultVisibleColumns,
} from "./columnsDate";

import { IconButton, Tooltip, Button as MuiButton } from "@mui/material";

import {
  dateReqFormat,
  decreaseTax,
  getHebDayByDate,
  thousandSeparator,
} from "../../helpers";

import ViewOrder from "../orders_analysis/components/ViewOrder";
import { startOfMonth } from "date-fns";
import { FaTimes } from "react-icons/fa";

import { FiTarget } from "react-icons/fi";
import BtnTooltip from "../../components/tooltips/BtnTooltip";

import { Pie } from "../../components/graphs/Pie";
import { setDialogModal } from "../../redux/actions/DialogAction";
import { userColumnsByDepartment } from "./userColumnsByDepartment";
import { userColumns } from "./userColumns";
import { useScreenSize } from "../../Hooks";
import moment from "moment";

import { SummeryBoxTable } from "./SummeryBoxTable";
import { hiddenColumns } from "../orders_analysis/columns";
import MultiSelectCheckbox from "../../components/dropdown/MultiSelectCheckBox";
import CostsModal from "./modals/CostsModal";

const Dashboard = ({
  token,
  setError,
  logout,
  selected_branch,
  branches,
  privileges_buttons,
  employeeTypes,
}) => {
  const { isMobile } = useScreenSize();
  const [allData, setAllData] = useState({});

  const [supplierData, setSupplierData] = useState([]);
  const [editData, setEditData] = useState({});

  const [showTablesData, setShowTablesData] = useState({});
  const [precentageEmployerCost, setPrecentageEmployerCost] = useState(0);
  const [viewToShow, setViewToShow] = useState(null);
  const [datTable, setDatTable] = useState(true);
  const [displayTable, setDisplayTable] = useState(false);
  const [modalHiddenColumns, setModalHiddenColumns] = useState([
    ...dDefaultVisibleColumns,
  ]);
  const [dateTableBreakdownColumns, setDateTableBreakdownColumns] = useState([
    ...dateDefaultVisibleColumns,
  ]);
  const [depTableBreakdownColumns, setDepTableBreakdownColumns] = useState([
    ...dDefaultVisibleColumns,
  ]);
  const [accGoal, setAccGoal] = useState("");
  const [showDateData, setShowDateData] = useState({});
  const [expenses, setExpenses] = useState({});
  const [expensesArray, setExpensesArray] = useState([]);
  const [lastYearIncome, setLastYearIncome] = useState(0);
  const [selectedDateData, setSelectedDateData] = useState({});
  const [displayArray, setDisplayArray] = useState({});
  const [incomeExtras, setIncomeExtras] = useState({});
  const [userHours, setUserHours] = useState({});
  const [depHours, setDepHours] = useState({});
  const [last4DaysAverage, setLast4DaysAverage] = useState(null);
  const [data, setData] = useState([]);
  const [filterByDep, setFilterByDep] = useState([]);
  const [showWarningAccu, setShowWarningAccu] = useState(false);
  const [employerCosts, setEmployerCosts] = useState({});
  const [calendarData, setCalendarData] = useState({
    lastYear: {
      sum: {},
      totals: {
        amount: 0,
        count: 0,
        average: 0,
      },
    },
    sum: {},
    totals: {
      amount: 0,
      count: 0,
      average: 0,
    },
  });

  const [loading, setLoading] = useState(false);
  const [selectedTypeOfData, setSelectedTypeOfData] = useState(4);

  const excelAgg = [
    { field: "travel_cost", aggregate: "sum" },
    { field: "meal_cost", aggregate: "sum" },
    { field: "health_cost", aggregate: "sum" },
    { field: "holiday_cost", aggregate: "sum" },
    { field: "global_cost", aggregate: "sum" },
    { field: "hours_200", aggregate: "sum" },
    { field: "hours_100", aggregate: "sum" },
    { field: "hours_125", aggregate: "sum" },
    { field: "hours_150_shabat", aggregate: "sum" },
    { field: "hours_150", aggregate: "sum" },
    { field: "hours_175", aggregate: "sum" },
    { field: "hours_200", aggregate: "sum" },
    { field: "break", aggregate: "sum" },
    { field: "hours_total", aggregate: "sum" },
    { field: "tip_complete_cost", aggregate: "sum" },
    { field: "bonus_cost", aggregate: "sum" },
    // { field: "monthly_bonus_amount", aggregate: "sum" },
    { field: "total_cost_after_employer_cost", aggregate: "sum" },
    { field: "employer_cost", aggregate: "sum" },
    { field: "total_cost_before_employer_cost", aggregate: "sum" },
  ];
  const serverSaveTitle = "DashboardExpenses";
  const departmentOptions = {
    אחר: 0,
    מנהלים: 1,
    טבחים: 2,
    מלצרים: 3,
    "מנהלי משמרת": 5,
    מארחות: 6,
    ברמנים: 7,
    שליחים: 8,
    טלפנים: 9,
    "הנהלת חשבונות": 10,
    "עובדים זרים": 11,
    קונדיטוריה: 12,
    "מטבח - הכנות": 13,
    "שוטפי כלים": 14,
    "צ'קרים": 15,
    מנקים: 16,
    "עובדים כלליים": 17,
    "עובדי דלפק": 23,
    קופאי: 24,
    "עובד ייצור": 25,
    "מלצרי אירועים": 26,
    מצילים: 27,
    ראנר: 28,
    מתלמד: 29,
    תגבור: 30,
    אריזה: 31,
    מאפיה: 32,
    מאפים: 33,
    עוגיות: 34,
    שונות: 35,
    טאבון: 36,
  };
  for (const et in employeeTypes) {
    if (!(employeeTypes[et].title in departmentOptions))
      departmentOptions[employeeTypes[et].title] = employeeTypes[et].id;
  }

  //compare
  let yday = new Date();
  yday.setDate(yday.getDate() - 1);
  //let tday = startOfMonth(new Date());
  let tday = startOfMonth(new Date());
  const [selectedDates, setSelectedDates] = useState({
    startDate: tday.getDate() === new Date().getDate() ? yday : tday,
    endDate: yday,
    key: "selection",
    label: tday.getDate() !== new Date().getDate() ? "החודש" : "אתמול",
  });
  const [openGoalsForm, setOpenGoalsForm] = useState(false);
  const [goalsData, setGoalsData] = useState([]);
  const getExpenseSummary = useMemo(
    () => async () => {
      const startDate = dateReqFormat(selectedDates.startDate);
      const endDate = dateReqFormat(selectedDates.endDate);

      const res = await Axios.post(
        `/branchs/${selected_branch.id}/getExpenseSummary`,
        {
          date: startDate,
          start_date: startDate,
          end_date: endDate,
          section: "LaborCost",
          // headers: { Authorization: `Bearer ${token}` },
        }
      );

      return res;
    },
    [token, setGoalsData, selected_branch, selectedDates]
  );

  const departmentOptionsObj = useMemo(() => {
    const departmentOptionsObjArray = [];

    for (const dp in departmentOptions) {
      if (departmentOptions[dp] == 0)
        departmentOptionsObjArray.push({ label: dp, value: 999 });
      else
        departmentOptionsObjArray.push({
          label: dp,
          value: departmentOptions[dp],
        });
    }
    return departmentOptionsObjArray;
  }, [departmentOptions]);
  const updateBranchGoals = useMemo(
    () => async () => {
      const res = await Axios.post(`/newGoals/${selected_branch.id}/update`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      let departments = expenses; //Object.values(expenses);//[...expenses];
      let id = 1;

      let result = {};
      for (var i = 0; i < departments.length; i++) {
        result[departments[i].department_name] = departments[i];
      }
      const editors = {};
      res.data.forEach((ob) => {
        editors[ob.id] = false;
        ob.show = false;
      });

      setEditData({ ...editors });
      setGoalsData(res.data);
      //setExpenses(result);
      //setOpenGoalsForm(true);
    },
    [token, setGoalsData, selected_branch]
  );
  const createBranchGoals = useMemo(
    () => async (data) => {
      const res = await Axios.post(`/newGoals/${selected_branch.id}/create`, {
        params: data,
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    [token, setGoalsData, selected_branch]
  );
  const fetchEmployerCosts = useMemo(
    () => async () => {
      if (!selected_branch?.id) return;
      //setLoading(true);

      const res = await Axios.get(
        `/employerCostsByDate/${selected_branch.id}/show`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.data.length && "costs" in res.data[0]) {
        let costs = JSON.parse(res.data[0].costs);
        if ("בחר/י" in costs) delete costs["בחר/י"];
        setEmployerCosts({ ...costs, id: res.data[0].id });
      }
    },
    [employerCosts]
  );
  useEffect(() => {
    // fetchEmployerCosts();
  }, []);
  const fetchBranchGoals = useMemo(
    () => async () => {
      if (!selected_branch?.id) return;
      setLoading(true);

      const res = await Axios.get(`/newGoals/${selected_branch.id}/show`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      let departments = { ...expenses }; //Object.values(expenses);//[...expenses];
      let id = 1;

      const editors = {};
      const cleanData = [];
      for (let d in departments) {
        departments[d].avg = 0;
        departments[d].navg = 0;
      }
      res.data.forEach((ob) => {
        if (ob.type == 3) {
          editors[ob.id] = false;
          ob["show"] = false;
          cleanData.push(ob);

          for (let d in departments) {
            if (departments[d].employee_type === ob.employee_type_id) {
              if (ob.precent === 1) {
                departments[d].avg = ob.goal;
                departments[d].navg = 0;
              } else if (ob.precent === 0) {
                departments[d].navg = ob.goal;
                departments[d].avg = 0;
              }
              break;
            }
          }

          //}
        } else if (ob.type == 6) {
          const obc = { ...ob };
          obc.department_name = "מצטבר";
          obc.employee_type_id = 999;
          cleanData.push(obc);
          const total = allData?.totalPie;
          const tpr = (total / lastYearIncome) * 100;
          const tprecent = isNaN(tpr) || !isFinite(tpr) ? 0 : tpr;
          if (obc.precent == 0 && obc.goal < total) {
            setShowWarningAccu(true);
            setAccGoal("₪" + thousandSeparator(obc.goal));
          } else if (obc.precent == 1 && obc.goal < tprecent) {
            setShowWarningAccu(true);
            setAccGoal(obc.goal + "%");
          } else {
            setShowWarningAccu(false);
            const stringAccGoal =
              obc.precent == 0
                ? "₪" + thousandSeparator(obc.goal)
                : obc.goal + "%";
            setAccGoal(stringAccGoal);
          }
        }
      });
      setExpenses({ ...departments });
      setExpensesArray(Object.values(departments));
      setEditData({ ...editors });
      setGoalsData([...cleanData]);
      setLoading(false);
    },
    [
      token,
      selected_branch,
      expenses,
      allData,
      lastYearIncome,
      loading,
      accGoal,
      showWarningAccu,
      allData,
    ]
  );
  // useEffect(() => {
  //   //fetchBranchGoals();
  // }, []);
  const openDisplayTable = (d, t, c, m) => {
    setDisplayTable(true);

    setDisplayArray({
      data: d,
      title: t,
      columns: c,
      open: displayTable,
      message: m,
      onClose: () => {
        setDisplayArray({});
      },
      mob: true,
    });
  };
  const openDisplayTableMemo = useMemo(
    () =>
      (d, t, c, m, p, h = true) => {
        if (h)
          setDisplayArray({
            data: Object.values(d),
            title: t,
            columns: c,
            message: m,
            hour: h, //true,
            parentTitle: p,
            onClose: () => {
              setDisplayArray({});
            },
          });
        else
          setDisplayArray({
            data: departmentOptions,
            costs: !h,
            title: t,
            columns: c,
            message: m,
            parentTitle: p,
            onClose: () => {
              setDisplayArray({});
            },
          });
      },
    [displayArray, departmentOptions]
  );
  const fetchCompleteCalendar = useMemo(
    () => async (calendarParent, diff) => {
      try {
        if (!selected_branch) return;

        const calendar = { ...calendarParent };

        let startDate = new Date(selectedDates.endDate);
        let endDate = new Date(selectedDates.endDate);
        endDate.setMonth(startDate.getMonth());
        endDate.setDate(startDate.getDay());
        startDate.setDate(endDate.getDay() - diff);

        startDate = await dateReqFormat(startDate);
        endDate = await dateReqFormat(endDate);

        const res = await Axios.post(
          `/branchs/${selected_branch.id}/getExpenseSummary`,
          {
            date: startDate,
            start_date: startDate,
            end_date: endDate,
            section: "LaborCost",
            // headers: { Authorization: `Bearer ${token}` },
          }
        );
        const calendar_data = Object.values(res.data["summary_calendar"]);
        calendar_data.forEach((dateWeek) => {
          dateWeek.forEach((dateSum) => {
            if (!dateSum?.date) return;
            if (!(dateSum.date in calendar)) {
              calendar[dateSum.date] = { ...dateSum };
            }
            const hebName = getHebDayByDate(dateSum.date);
            if (!(hebName in calendar.sum)) {
              calendar.sum[hebName] = {
                count: 1,
                amount: dateSum.value,
                average: dateSum.value,
              };
              calendar.totals.count += 1;
              calendar.totals.amount += dateSum.value;
              calendar.totals.average =
                calendar.totals.amount / calendar.totals.count;
            } else if (calendar.sum[hebName].count < 4) {
              calendar.sum[hebName].count += 1;
              calendar.sum[hebName].amount += dateSum.value;
              calendar.sum[hebName].average =
                calendar.sum[hebName].amount / calendar.sum[hebName].count;
              calendar.totals.count += 1;
              calendar.totals.amount += dateSum.value;
              calendar.totals.average =
                calendar.totals.amount / calendar.totals.count;
            }
          });
        });
        setCalendarData({ ...calendar });
      } catch (e) {
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logout();
          } else if (e.response.status === 500) {
            setError("", "ארעה שגיאה בשרת");
          }
      }
    },
    [token, logout, selected_branch, selectedDates, calendarData]
  );
  const fetchData = useMemo(
    () => async (calendarParent) => {
      try {
        //setLoading(true);
        if (!selected_branch) return;

        const calendar = { ...calendarParent };

        let startDate = new Date(selectedDates.startDate);
        let endDate = new Date(selectedDates.endDate);
        startDate.setFullYear(startDate.getFullYear() - 1);
        endDate.setFullYear(endDate.getFullYear() - 1);
        startDate = await dateReqFormat(startDate);
        endDate = await dateReqFormat(endDate);

        const res = await Axios.post(
          `/branchs/${selected_branch.id}/getExpenseSummary`,
          {
            date: startDate,
            start_date: startDate,
            end_date: endDate,
            section: "LaborCost",
            // headers: { Authorization: `Bearer ${token}` },
          }
        );
        let totals = {
          noTotal: 0,
          total: 0,
        };

        const calendar_data = Object.values(res.data["summary_calendar"]);

        const legdata = Object.values(res.data["employees_summary"]);
        legdata.forEach((expenseLatYear) => {
          totals.noTotal += expenseLatYear.accumulate_value; //(expenseLatYear.cost) ? expenseLatYear.cost:0;
          totals.total += expenseLatYear.accumulate_value; //(expenseLatYear.total_including_tips) ? expenseLatYear.cost_total+expenseLatYear.calculate_tip_as_cost:0;
        });

        calendar_data.forEach((dateWeek) => {
          dateWeek.forEach((dateSum) => {
            if (!dateSum?.date) return;
            const hebName = getHebDayByDate(dateSum.date);
            if (!(hebName in calendar.lastYear.sum)) {
              calendar.lastYear.sum[hebName] = {
                count: 1,
                amount: dateSum.value,
                average: dateSum.value,
              };
              calendar.lastYear.totals.count += 1;
              calendar.lastYear.totals.amount += dateSum.value;
              calendar.lastYear.totals.average =
                calendar.lastYear.totals.amount /
                calendar.lastYear.totals.count;
            } else {
              calendar.lastYear.sum[hebName].count += 1;
              calendar.lastYear.sum[hebName].amount += dateSum.value;
              calendar.lastYear.sum[hebName].average =
                calendar.lastYear.sum[hebName].amount /
                calendar.lastYear.sum[hebName].count;
              calendar.lastYear.totals.count += 1;
              calendar.lastYear.totals.amount += dateSum.value;
              calendar.lastYear.totals.average =
                calendar.lastYear.totals.amount /
                calendar.lastYear.totals.count;
            }
          });
        });

        setSupplierData({ ...totals });
        setCalendarData({ ...calendar });
        return [calendar];
      } catch (e) {
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logout();
          } else if (e.response.status === 500) {
            setError("", "ארעה שגיאה בשרת");
          }
      }
      setLoading(false);
    },

    [token, logout, selected_branch, selectedDates, calendarData]
  );

  const newUserHourCompare = useMemo(
    () => async (results) => {
      try {
        if (!selected_branch) return;
        const startDate = await dateReqFormat(selectedDates.startDate);
        const endDate = await dateReqFormat(selectedDates.endDate);

        const oc = Object.keys(results[2]).join(",");

        const res = await Axios.get(`laborCosts/hours`, {
          params: {
            employeeId: oc,
            startDate,
            endDate,
          },
          headers: { Authorization: `Bearer ${token}` },
        });
        const dHours = {};
        let dateEx = results[0];
        let depEx = results[1];
        for (let uHours in res.data) {
          for (let uh in res.data[uHours]) {
            let uval = res.data[uHours][uh];
            let eid = uval.employee_id;
            if (uval["working_date"] in dateEx) {
              dateEx[uval["working_date"]]["hours"] = [{ ...uval }];
              if ("subPaRows" in dateEx[uval["working_date"]]) {
                dateEx[uval["working_date"]]["subPaRows"].forEach((e) => {
                  e.hours = [{ ...uval }];
                  e["subDubRows"].forEach((r) => {
                    if (r.employee_id == eid) {
                      r.hours = [{ ...uval }];
                    }
                  });
                  e["sub_rows"] = e["subDubRows"];
                });
                dateEx[uval["working_date"]]["sub_rows"] =
                  dateEx[uval["working_date"]]["subPaRows"];
                //dateEx[uval["working_date"]]["subPaRows"] = Object.values(dateEx[uval["working_date"]]["sub_rows"]);
              }
            }

            //by department
            for (let expart in depEx) {
              ///
              const dpi = parseInt(expart);
              dHours[dpi] = {};
              dHours[dpi].id = dpi;

              dHours[dpi].pl = depEx[expart].hours_100;
              dHours[dpi].plannedGlobal = depEx[expart].hours_100_global;
              dHours[dpi].actualGlobal = depEx[expart].hours_total_global;
              dHours[dpi].ac = depEx[expart].hours_total;
              ////
              for (const sb in depEx[expart]["sub_rows"]) {
                if (depEx[expart]["sub_rows"][sb].employee_id == eid)
                  depEx[expart]["sub_rows"][sb].hours = [...res.data[uHours]];
                for (const sdr in depEx[expart]["sub_rows"][sb].subDubRows) {
                  depEx[expart]["sub_rows"][sb].subDubRows[sdr].hours = [
                    ...res.data[uHours],
                  ];
                }
              }
              for (const sb in depEx[expart]["subPaRows"]) {
                if (depEx[expart]["subPaRows"][sb].employee_id == eid)
                  depEx[expart]["subPaRows"][sb].hours = [...res.data[uHours]];
                for (const sdr in depEx[expart]["subPaRows"][sb].subDubRows) {
                  depEx[expart]["subPaRows"][sb].subDubRows[sdr].hours = [
                    ...res.data[uHours],
                  ];
                }
              }
            }
          }
        }
        dHours.done = true;
        // setUserHours(plUserHours);
        setData(Object.values(dateEx));
        setExpenses({ ...depEx });
        setExpensesArray(Object.values(depEx));
        setDepHours(dHours);
      } catch (e) {
        setLoading(false);
        console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logout();
          }
      }
    },
    [
      selectedDates,
      selected_branch,
      setData,
      data,
      setExpenses,
      expenses,
      setExpensesArray,
      expensesArray,
      setDepHours,
      depHours,
    ]
  );

  const getDaysArray = (ad, scale, delimit) => {
    let a = moment([
      ad.startDate.getFullYear(),
      ad.startDate.getMonth(),
      ad.startDate.getDate(),
    ]);
    let b = moment([
      ad.endDate.getFullYear(),
      ad.endDate.getMonth(),
      ad.endDate.getDate(),
    ]);
    let dates = [];
    let tuples = [];
    for (let m = a; m.isSameOrBefore(b); m.add(delimit, scale)) {
      dates.push(m.format("YYYY-MM-DD"));
    }

    return dates;
  };
  const uniteTransfers = async (obj, resultObj) => {
    if (!("data" in obj) || !resultObj.length) return;
    const res = obj["data"];

    const fields = [
      //"cost",
      "hours_100",
      "hours_125",
      "hours_150",
      "total_cost_after_employer_cost",
      "total_cost_before_employer_cost",
      "hours_150_shabat",
      "hours_175",
      "hours_200",
      "bonus_cost",
      "monthly_bonus_amount",
      "monthly_dashboard_bonus",
      "hours_total",
      "meal_cost",
      "travel_cost",
      // "global_cost",
      // "health_cost",
      // "holiday_cost",
      "break",
    ];
    const users = res["date"][0];
    const departments = res["department"][1];
    // let uDates = res["date"][0];
    // let department = res["department"][1];
    // let userDepSum = res["department"][0];
    for (const user in users) {
      const dates = users[user].labor;
      const employee_name = users[user].employee_name;
      for (const date in dates) {
        if (date == "accumulate") continue;
        dates[date].full_name = employee_name;
        const did = dates[date].employee_type;
        const dname = did in departments ? departments[did] : "";
        dates[date].transfer_branch_id = Object.values(
          dates[date].sub
        )[0].transfer_branch_id;
        if (dates[date].transfer_branch_id !== selected_branch.id) {
          for (let ic in fields) {
            dates[date][fields[ic]] *= -1;
          }
        }

        dates[date].destination_branch_name = Object.values(branches).filter(
          ({ id }) => id == dates[date].transfer_branch_id
        )[0]?.name;

        if (!(date in resultObj[2])) {
          const clone = { ...dates[date] };
          clone["sub_rows"] = [{ ...clone }];
          dates[date]["sub_rows"] = {};
          dates[date]["sub_rows"][did] = clone;
          dates[date]["sub_rows"][did].department_name = dname;
          resultObj[2][date] = clone;
        } else {
          for (let ic in fields) {
            if (!dates[date][fields[ic]] || isNaN(dates[date][fields[ic]]))
              dates[date][fields[ic]] = 0;
            if (isNaN(resultObj[2][date][fields[ic]]))
              resultObj[2][date][fields[ic]] = 0;
            resultObj[2][date][fields[ic]] +=
              dates[date][fields[ic]] > 0 ? dates[date][fields[ic]] : 0;
          }

          //dates
          if (
            "sub_rows" in resultObj[2][date] &&
            !(did in resultObj[2][date]["sub_rows"])
          ) {
            const clone = { ...dates[date] };
            clone["sub_rows"] = [{ ...clone }];
            clone["subDubRows"] = Object.values(clone["sub_rows"]);
            resultObj[2][date]["sub_rows"][did] = clone;

            //resultObj[2][date]["sub_rows"][did]={...dates[date]};
            resultObj[2][date]["sub_rows"][did].department_name = dname;
            resultObj[2][date]["subPaRows"] = Object.values(
              resultObj[2][date]["sub_rows"]
            );
          } else {
            for (let ic in fields) {
              if (!dates[date][fields[ic]] || isNaN(dates[date][fields[ic]]))
                dates[date][fields[ic]] = 0;
              // if(isNaN( resultObj[2][date][fields[ic]]))
              //   resultObj[2][date][fields[ic]]=0;
              resultObj[2][date]["sub_rows"][did][fields[ic]] +=
                dates[date][fields[ic]] > 0 ? dates[date][fields[ic]] : 0;
            }
            resultObj[2][date]["sub_rows"][did]["sub_rows"].push({
              ...dates[date],
            });
            resultObj[2][date]["sub_rows"][did]["subDubRows"] = Object.values(
              resultObj[2][date]["sub_rows"][did]["sub_rows"]
            );
            resultObj[2][date]["subPaRows"] = Object.values(
              resultObj[2][date]["sub_rows"]
            );
          }
        }

        ///end dates
        //departments
        if (!(did in resultObj[0])) {
          const cloned = { ...dates[date] };
          cloned["date"] = date;
          dates[date]["sub_rows"] = [{ ...cloned }];
          dates[date]["subDubRows"] = Object.values(dates[date]["sub_rows"]);
          resultObj[0][did] = {};
          resultObj[0][did][user] = { ...dates[date] };
          resultObj[0][did].department_name = dname;
          resultObj[0][did]["subPaRows"] = Object.values(
            resultObj[0][did]["sub_rows"]
          );
        } else {
          for (let ic in fields) {
            if (!dates[date][fields[ic]] || isNaN(dates[date][fields[ic]]))
              dates[date][fields[ic]] = 0;

            resultObj[0][did][fields[ic]] +=
              dates[date][fields[ic]] > 0 ? dates[date][fields[ic]] : 0;
          }
          if (!(user in resultObj[0][did])) {
            const cloned = { ...dates[date] };
            cloned["date"] = date;
            dates[date]["sub_rows"] = [{ ...cloned }];
            dates[date]["subDubRows"] = Object.values(dates[date]["sub_rows"]);
            resultObj[0][did][user] = { ...dates[date] };
          } else {
            for (let ic in fields) {
              if (!dates[date][fields[ic]] || isNaN(dates[date][fields[ic]]))
                dates[date][fields[ic]] = 0;

              resultObj[0][did][user][fields[ic]] +=
                dates[date][fields[ic]] > 0 ? dates[date][fields[ic]] : 0;
            }
            resultObj[0][did][user]["sub_rows"].push({ ...dates[date] });
            resultObj[0][did][user]["subDubRows"] = Object.values(
              resultObj[0][did][user]["sub_rows"]
            );
          }
          resultObj[0][did]["subPaRows"] = Object.values(
            resultObj[0][did]["sub_rows"]
          );
        }
        //////////////
      }
    }
    for (let edate in resultObj[2]) {
      if ("sub_rows" in resultObj[2][edate])
        resultObj[2][edate]["subPaRows"] = Object.values(
          resultObj[2][edate]["sub_rows"]
        );
    }
    const expensesByDateArray = Object.values(resultObj[2]).sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    setUserHours({ ...resultObj[2] });
    setExpenses({ ...resultObj[0] });
    setExpensesArray(Object.values(resultObj[0]));
    setData(expensesByDateArray);
  };
  const fetchBranchViewOptions = useMemo(
    () => async () => {
      try {
        const res = await Axios.get(
          `branch/${selected_branch.id}/reportsOptionsPage`,
          {
            params: {
              report_title: serverSaveTitle,
            },
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (!Object.keys(res.data).length) {
          // saveViewOptions(dDefaultVisibleColumns);
          // saveViewOptions(dateDefaultVisibleColumns,"Date");
          // saveViewOptions(dDefaultVisibleColumns,"Dep");
        } else {
          const modalCols = [];
          const dateCols = [];
          const depCols = [];
          for (let col in res.data) {
            if (!res.data[col].active) continue;
            if (res.data[col].report_title == "DashboardExpenses")
              modalCols.push(res.data[col].column_title);
            else if (res.data[col].report_title == "DashboardExpensesDate")
              dateCols.push(res.data[col].column_title);
            else if (res.data[col].report_title == "DashboardExpensesDep")
              depCols.push(res.data[col].column_title);
          }
          setModalHiddenColumns([...modalCols]);
          setDateTableBreakdownColumns([...dateCols]);
          setDepTableBreakdownColumns([...depCols]);
        }
      } catch (e) {
        console.error(e);

        if (e.response.status === 401) {
          logout();
        } else if (e.response.status === 500) {
          setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת");
        }
      }
    },
    [selected_branch, serverSaveTitle]
  );

  const saveViewOptions = useMemo(
    () =>
      async (cols, report = "", unchecked) => {
        try {
          const columns = [];

          cols.forEach((col) => {
            const co = {
              active: unchecked.includes(col) ? 0 : 1,
              title: col,
            };
            columns.push(co);
          });
          if (!selected_branch) return;
          const res = await Axios.post(
            `branch/${selected_branch.id}/reportsOptions`,
            {
              report_title: serverSaveTitle + report,
              columns,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        } catch (e) {
          console.error(e);
          if (e.response.status === 401) {
            logout();
          } else if (e.response.status === 500) {
            setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת");
          }
        }
      },
    [selected_branch, serverSaveTitle, modalHiddenColumns]
  );

  const createExpenseDate = (
    expensesByDate,
    expenseDate,
    labor,
    total_cost,
    fields
  ) => {
    if (expenseDate in expensesByDate) {
      for (let ic in fields) {
        if (isNaN(labor[fields[ic]])) labor[fields[ic]] = 0;
        expensesByDate[expenseDate][fields[ic]] += labor[fields[ic]];
      }
      //return expensesByDate;
    } else {
      expensesByDate[expenseDate] = {
        ...labor,
      };
      expensesByDate[expenseDate].index =
        Object.values(expensesByDate).length - 1;
      expensesByDate[expenseDate].cost = labor.total_cost_after_employer_cost;
      expensesByDate[expenseDate].date = expenseDate;
      expensesByDate[expenseDate].hebrewDate = fixDate(expenseDate);
      expensesByDate[expenseDate].hebrewDateName = getHebDayByDate(expenseDate);
      expensesByDate[expenseDate]["total_emp_cost"] = total_cost;
      expensesByDate[expenseDate].transfer_branch_id = Object.values(
        expensesByDate[expenseDate].sub
      )[0].transfer_branch_id;
      expensesByDate[expenseDate].original_branch_name = Object.values(
        branches
      ).filter(
        ({ id }) => id == expensesByDate[expenseDate].transfer_branch_id
      )[0]?.name;
      //if(typeof userByDate[expenseDate] !== undefined){
      expensesByDate[expenseDate].sub_rows = {}; //userByDate[expenseDate];
      //}
    }

    //return expensesByDate;
  };
  const calcLaborUser = (ulabor, dateUser, fields, eName) => {
    ulabor[dateUser].date = dateUser;
    ulabor[dateUser].hebrewDate = fixDate(dateUser); //+" "+getHebDayByDate(dateUser);
    ulabor[dateUser].hebrewDateName = getHebDayByDate(dateUser);
    ulabor[dateUser].full_name = eName;
    ulabor[dateUser].transfer_branch_id = Object.values(
      ulabor[dateUser].sub
    )[0].transfer_branch_id;
    if (
      ulabor[dateUser].transfer_branch_id > 0 &&
      ulabor[dateUser].transfer_branch_id != selected_branch.id
    ) {
      if (!ulabor[dateUser]?.destination_branch_name) {
        ulabor[dateUser].original_branch_name = Object.values(branches).filter(
          ({ id }) => id == ulabor[dateUser].transfer_branch_id
        )[0].name;
      }
      // for(let ic in fields){
      //   ulabor[dateUser][fields[ic]] *=  -1;
      // }
    }
    return ulabor[dateUser];
  };

  const addDateSubRow = (
    expensesByDate,
    uobj,
    uds,
    expenseDate,
    total_cost,
    fields,
    uName
  ) => {
    const depart_id = uobj.employee_type;
    if (!(depart_id in expensesByDate[expenseDate]["sub_rows"])) {
      expensesByDate[expenseDate]["sub_rows"][depart_id] = {
        ...uobj,
      };
      //subrow
      //uDates[uds]["labor"].expenseDate["full_name"]=uName;
      uobj["employee_id"] = uds;
      //expensesByDate[expenseDate]["sub_rows"][depart_id]["subPaRows"]=[];
      //expensesByDate[expenseDate]["sub_rows"][depart_id]["subPaRows"].push(uDates[uds]["labor"][expenseDate]);
      //dup subPaRows 2 sub_rows for modal
      expensesByDate[expenseDate]["sub_rows"][depart_id]["sub_rows"] = [];
      expensesByDate[expenseDate]["sub_rows"][depart_id]["sub_rows"].push(uobj);
      //
    } else {
      //aggregate
      for (let ic in fields) {
        if (isNaN(uobj[fields[ic]])) uobj[fields[ic]] = 0;
        expensesByDate[expenseDate]["sub_rows"][depart_id][fields[ic]] +=
          uobj[fields[ic]];
      }
      //subrow
      uobj["full_name"] = uName;
      uobj["employee_id"] = uds;
      uobj["total_emp_cost"] = total_cost;
      //expensesByDate[expenseDate]["sub_rows"][depart_id]["subPaRows"].push(uDates[uds]["labor"][expenseDate]);
      //dup subPaRows 2 sub_rows for modal
      expensesByDate[expenseDate]["sub_rows"][depart_id]["sub_rows"].push(uobj);
      //
    }
  };

  const getTotal = (expenseExtra, totals) => {
    totals.cost_raw += isNaN(expenseExtra?.total_cost_before_employer_cost)
      ? 0
      : expenseExtra.total_cost_before_employer_cost;
    totals.cost_total += isNaN(expenseExtra?.total_cost_after_employer_cost)
      ? 0
      : expenseExtra.total_cost_after_employer_cost;
    totals.total_including_tips += expenseExtra?.total_including_tips
      ? expenseExtra?.total_including_tips
      : expenseExtra?.total_cost_after_employer_cost;
    totals.cost += expenseExtra?.Total
      ? expenseExtra.Total
      : isNaN(expenseExtra?.total_cost_after_employer_cost)
      ? 0
      : expenseExtra.total_cost_after_employer_cost;
    return { ...totals };
  };
  const getTotals = (legdata, totals, calcNew = false) => {
    legdata.forEach((expenseExtra) => {
      expenseExtra.total_cost_before_employer_cost = calcNew
        ? expenseExtra.raw_cost
        : expenseExtra.total_cost_before_employer_cost;

      expenseExtra.total_cost_after_employer_cost = calcNew
        ? expenseExtra.total_cost
        : expenseExtra.total_cost_after_employer_cost;
      totals.cost_raw += isNaN(expenseExtra?.total_cost_before_employer_cost)
        ? 0
        : expenseExtra.total_cost_before_employer_cost;
      totals.cost_total += isNaN(expenseExtra?.total_cost_after_employer_cost)
        ? 0
        : expenseExtra.total_cost_after_employer_cost;
      totals.total_including_tips += expenseExtra?.total_including_tips
        ? expenseExtra?.total_including_tips
        : expenseExtra?.total_cost_after_employer_cost;
      totals.cost += expenseExtra?.Total
        ? expenseExtra.Total
        : isNaN(expenseExtra?.total_cost_after_employer_cost)
        ? 0
        : expenseExtra.total_cost_after_employer_cost;
    });
    return totals;
  };
  const initExpenseObj = (obj, type = 1) => {
    let costTotal = 0;
    let globalHours = 0;
    let totalGlobalHours = 0;
    let cost = 0;
    let globalWorker = false;
    let totalPrecent = 0;

    if (type == 1) {
      costTotal = obj?.total_cost;
      cost = obj?.total_cost;
    } else if (type == 2) {
      costTotal = 0; //obj?.accumulate_value;
      cost = obj?.accumulate_value;

      totalPrecent = Number(obj?.percent_from_income).toFixed(2);
    } else if (type == 3) {
      costTotal = 0; //obj?.value;
      cost = obj?.value;
      totalPrecent = Number(obj?.extra_value?.value).toFixed(2);
    }

    let salaryType = "שעתי";
    switch (obj?.salary_type) {
      case 1:
        salaryType = "לפי תפקיד";

        break;
      case 2:
        salaryType = "שכר משתנה";
        break;
      case 3:
        salaryType = "שכר בסיס+תוספת";

        break;
    }
    if (obj?.global_salary) {
      salaryType = "גלובאלי";
      globalWorker = true;
      obj.raw_cost = obj.global_salary + getRealTravelCost(obj);
      obj.total_cost = obj.employer_cost_factor * obj.raw_cost;
      obj.cost = obj.employer_cost_factor * obj.raw_cost;
      globalHours = obj.hours_structure["100"];
      totalGlobalHours = obj.hours_structure["total"];
      costTotal = obj.employer_cost_factor * obj.raw_cost;
    }
    const wd = obj?.actualWorkDays;
    const randId = Math.floor(Math.random() * 100000) + 1;
    const costPerDay = obj.current_salary?.salary
      ? obj.current_salary?.salary / wd
      : obj.current_salary?.global_salary / wd;
    return {
      globalWorker: globalWorker,
      costPerDay: costPerDay,
      sum: obj?.sum ? [...obj.sum, obj] : [obj],
      id: randId,
      key: randId,
      bonus_cost: obj?.bonus,
      monthly_bonus_amount: obj?.monthly_bonus_amount,
      monthly_dashboard_bonus: obj?.monthly_dashboard_bonus,
      break: 0,

      const_travel_cost: obj?.const_travel_cost,
      const_travel_cost_overwrite: obj?.const_travel_cost_overwrite,
      cost: cost,

      date: obj?.date,
      department_name: obj?.type_name,
      department_id: obj?.type_name ? departmentOptions[obj.type_name] : "אחר",
      employee_id: obj?.employee_id,
      employee_name:
        obj?.employee_name +
        (obj.type == "transfer" ? "(" + obj.subtype_name + ")" : ""),

      employee_type: parseInt(obj?.type),
      free_monthly: obj?.free_monthly,
      full_name:
        obj?.employee_name +
        (obj.type == "transfer" ? "(" + obj.subtype_name + ")" : ""),

      global_cost: obj?.global_cost,
      health_cost: obj?.health_cost,
      hebrewDate: fixDate(obj?.date),
      hebrewDateName: getHebDayByDate(obj?.date),
      holiday_cost: obj?.holiday_cost,
      hours_100:
        obj?.hours_structure &&
        "100" in obj?.hours_structure &&
        !obj.global_salary
          ? obj.hours_structure["100"]
          : 0,
      hours_100_global: globalHours,
      hours_total_global: totalGlobalHours,
      hours_125:
        obj?.hours_structure && "125" in obj?.hours_structure
          ? obj.hours_structure["125"]
          : 0,
      hours_150:
        obj?.hours_structure && "150" in obj?.hours_structure
          ? obj.hours_structure["150"]
          : 0,
      hours_150_shabat:
        obj?.hours_structure && "150_shabat" in obj?.hours_structure
          ? obj.hours_structure["150_shabat"]
          : 0,
      hours_175:
        obj?.hours_structure && "175" in obj?.hours_structure
          ? obj.hours_structure["175"]
          : 0,
      hours_200:
        obj?.hours_structure && "200" in obj?.hours_structure
          ? obj.hours_structure["200"]
          : 0,

      hours_cost: obj?.hours_cost,
      hours_night: 0,
      hours_shabat: 0,
      hours_shabat_after_shabat: 0,
      hours_total:
        obj?.hours_structure &&
        "total" in obj?.hours_structure &&
        !obj.global_salary
          ? obj.hours_structure["total"]
          : 0,

      meal_cost: obj?.meal_cost,
      original_branch_name: "",
      real_shabat_hours: 0,
      salary: obj.current_salary?.salary
        ? obj.current_salary?.salary
        : obj.current_salary?.global_salary,
      salary_type: salaryType,

      subPaRows: [],
      sub_rows: {},
      tip_complete_cost: obj?.tip_cost,
      tip_cost: obj?.tip_cost,
      ///before after costs
      Total: costTotal,
      total_cost_after_employer_cost: costTotal,
      total_cost_before_employer_cost: obj?.raw_cost,
      //////
      total_emp_cost: obj?.total_emp_cost,
      total_precent: totalPrecent,

      transfer_branch_id: 0,
      travel_cost: getRealTravelCost(obj),
      waiting_cost: 0,
      waiting_regular: 0,
      waiting_shabat: 0,
      working_days: 1,
    };
  };
  const joinUserRows = (expenseByUser, uempOb, summery = false) => {
    const fields = [
      //"cost",
      "hours_100",
      "hours_100_global",
      "hours_total_global",
      "hours_125",
      "hours_150",
      "total_cost_after_employer_cost",
      "total_cost_before_employer_cost",
      "hours_150_shabat",
      "hours_175",
      "hours_200",
      "bonus_cost",
      "monthly_bonus_amount",
      "monthly_dashboard_bonus",
      "hours_total",
      "meal_cost",
      "break",
      "tip_complete_cost",
      "working_days",
      "tip_complete",
      "tip_cost",
      "total_precent",
      "travel_cost",
      "const_travel_cost",
      "health_cost",
      "holiday_cost",
      "global_cost",
    ];
    for (let ic in fields) {
      if (ic === "total_precent") continue;

      if (isNaN(expenseByUser[fields[ic]])) expenseByUser[fields[ic]] = 0;

      if (isNaN(uempOb[fields[ic]])) uempOb[fields[ic]] = 0;

      if (uempOb.salary_type === "גלובאלי" && !summery) {
        if (
          fields[ic] !== "cost" &&
          fields[ic] !== "total_cost_after_employer_cost" &&
          fields[ic] !== "total_cost_before_employer_cost"
        ) {
          expenseByUser[fields[ic]] += uempOb[fields[ic]];
        }
      } else {
        expenseByUser[fields[ic]] += uempOb[fields[ic]];
      }
    }
    return expenseByUser;
  };
  const groupBy = (collection, property) => {
    let i = 0,
      val,
      index,
      values = [],
      result = [];
    for (i; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  };
  const objMerge = (objOn, objTw) => {
    const objOne = { ...objOn };
    const objTwo = { ...objTw };
    const fields = [
      //"cost",
      "hours_100",
      "hours_125",
      "hours_150",
      "total_cost_after_employer_cost",
      "total_cost_before_employer_cost",
      "hours_150_shabat",
      "hours_100_global",
      "hours_total_global",
      "hours_175",
      "hours_200",
      "bonus_cost",
      // "monthly_dashboard_bonus",
      "monthly_bonus_amount",
      "hours_total",
      "meal_cost",
      "break",
      "tip_complete_cost",
      "working_days",
      "tip_complete",
      "tip_cost",
      //"total_precent",
      "travel_cost",
      "const_travel_cost",
      "health_cost",
      "holiday_cost",
      "global_cost",
    ];
    for (let fi in objOne) {
      const tempVal = objOne[fi];
      if (fields.includes(fi) && fi in objTwo) {
        objOne[fi] += objTwo[fi];
      }
      if (isNaN(objOne[fi]) && !isFinite(objOne[fi])) {
        objOne[fi] = tempVal;
      }
    }
    return objOne;
  };
  const sumDepartment = (department, totalCost = 0, msg) => {
    let depClone = { ...department };
    let summery = initExpenseObj({});
    for (const urow in department.subPaRows) {
      summery = joinUserRows(summery, { ...department.subPaRows[urow] }, true);
    }
    summery.total_emp_cost = totalCost;
    depClone = joinUserRows(depClone, summery, true);
    depClone.total_emp_cost = totalCost;

    return depClone;
  };
  const sumDateObj = (dataOb, totalCost = 0) => {
    let dateClone = { ...dataOb };
    let summery = initExpenseObj({});

    for (const egroup in dateClone.sub_rows) {
      const group = dateClone.sub_rows[egroup];
      let innerSummery = initExpenseObj({});

      for (let grow in group) {
        innerSummery = objMerge(group[grow], innerSummery);
      }
      innerSummery.total_emp_cost = totalCost;

      dateClone.subPaRows[egroup] = innerSummery;
      //dateClone.subPaRows[egroup].subDubRows=group;
    }
    for (const urow in dateClone.subPaRows) {
      summery = objMerge(dateClone.subPaRows[urow], summery);

      summery.total_emp_cost = totalCost;
    }
    dateClone = objMerge(dateClone, summery);
    dateClone.total_emp_cost = totalCost;

    return dateClone;
  };
  const isFuture = (dateToBeCompared) => {
    return moment(dateToBeCompared, "DD/MM/YY").isAfter(
      moment(new Date(), "DD/MM/YY"),
      "day"
    );
  };
  const sumGroup = (dep) => {
    let co = 0;
    let summery = {};
    for (const urow in dep) {
      if (co == 0) summery = dep[urow];
      else summery = objMerge(summery, dep[urow]);

      co++;
    }
    return summery;
  };
  const checkDateValidCost = (data) => {
    const now = new Date(data);

    const month =
      now.getMonth() + 1 < 10 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
    const year = now.getYear().toString().slice(-2);
    const currentMonth = month + "/" + year;
    if (employerCosts[currentMonth]) {
      if (!parseFloat(employerCosts[currentMonth]?.precent))
        return employerCosts[currentMonth].value;
      return true;
    }
    return false;
  };
  const newUniteExpenseObj = async (obj, sDates, filterOptions) => {
    let totals = {
      cost_raw: 0,
      cost_total: 0,
      total_including_tips: 0,
      cost: 0,
    };
    if (filterOptions.includes(999) && !filterOptions.includes(0))
      filterOptions.push(0);
    const expenseByUser = {};
    const userByDate = {};
    const expenseByDepartment = {};
    const expensesByDate = {};
    const dataOb = {};
    const allDatesBol = {};
    const globalWorkers = {};
    let newCostFactor = false;
    let NewTotalCosts = false;
    let employer_cost_factor = 0;
    let old_employer_cost_factor = 0;
    ///sum days///
    // const userDaysSum ={};
    // const depDaysSum ={};
    const allDays = getDaysArray(sDates, "days", 1);
    let datesByEmp = [];
    allDays.forEach((date) => {
      if (!(date in datesByEmp)) datesByEmp[date] = [];
    });
    //////////////
    const calendar = {
      lastYear: {
        sum: {},
        totals: {
          amount: 0,
          count: 0,
          average: 0,
        },
      },
      sum: {},
      datesByEmp: datesByEmp,
      totals: {
        amount: 0,
        count: 0,
        average: 0,
      },
    };
    let totalIncomeSum = 0;
    let totalCost = 0;
    ////new prediction value
    let predectionValue = 0;

    obj.forEach((obLine) => {
      //const resArray = Object.values(obLine.data);

      const depBreakdown = Object.values(obLine.data.employees_summary);
      const dateBreakdown = obLine.data.summary_calendar;
      const userByDay = obLine.data.employees;
      //const branchSettings = obLine.data.branch_settings;
      //const branchGoal = obLine.data.branch_goals;
      const totalIncome = obLine.data.total_income;
      totalIncomeSum += totalIncome;
      //totals = getTotals(userByDay,totals,true);
      predectionValue += depBreakdown.reduce(
        (accumulator, { predectionValue }) => accumulator + predectionValue,
        0
      );
      totalCost += depBreakdown.reduce(
        (accumulator, { accumulate_value }) => accumulator + accumulate_value,
        0
      );

      //global employer cost facotr
      if ("id" in employerCosts) {
        let now = new Date(selectedDates.endDate);
        const month =
          now.getMonth() + 1 < 10
            ? "0" + (now.getMonth() + 1)
            : now.getMonth() + 1;
        const year = now.getYear().toString().slice(-2);
        const currentMonth = month + "/" + year;
        let rawCost = 0; //totalCost/userByDay[0].employer_cost_factor;
        if (userByDay[0]?.employer_cost_factor) {
          rawCost = totalCost / userByDay[0].employer_cost_factor;
        }
        if (employerCosts[currentMonth]) {
          if (rawCost && !parseFloat(employerCosts[currentMonth]?.precent)) {
            //number value
            old_employer_cost_factor = employer_cost_factor;
            //employer_cost_factor = (employerCosts[currentMonth].value+rawCost)/rawCost;
            newCostFactor = true;
            NewTotalCosts = true;
            //totalCost =rawCost+employerCosts[currentMonth].value;
          } else if (
            rawCost &&
            parseFloat(employerCosts[currentMonth]?.precent)
          ) {
            //precent value
            old_employer_cost_factor = employer_cost_factor;
            const diff = (employerCosts[currentMonth].value / 100) * rawCost;
            const NewTotalCots = rawCost + diff;
            employer_cost_factor = NewTotalCots / rawCost;
            newCostFactor = true;
            totalCost = NewTotalCots;
          } else if (userByDay.length && !employer_cost_factor) {
            if (userByDay[0]?.employer_cost_factor)
              employer_cost_factor = userByDay[0].employer_cost_factor;
          }
        }
      } else if (userByDay.length && !employer_cost_factor) {
        if (userByDay[0]?.employer_cost_factor)
          employer_cost_factor = userByDay[0].employer_cost_factor;
      }

      ///
      userByDay.forEach((uByDay) => {
        const dateUser = uByDay.date;
        const emId = uByDay.employee_id;
        if (filterOptions.length)
          if (
            !filterOptions.includes(uByDay.type) &&
            !filterOptions.includes(parseInt(uByDay.type))
          )
            return;
        uByDay.total_emp_cost = totalCost;
        uByDay.actualWorkDays = allDays.length;
        const uempOb = initExpenseObj(uByDay);
        const TestEmploterCost = checkDateValidCost(uempOb.date);
        if (newCostFactor && TestEmploterCost) {
          if (TestEmploterCost && NewTotalCosts) {
            uempOb.employer_cost = TestEmploterCost;
            uempOb.total_cost_after_employer_cost =
              uempOb.total_cost_before_employer_cost + TestEmploterCost;
            uempOb.Total =
              uempOb.total_cost_before_employer_cost + TestEmploterCost;
          } else {
            uempOb.total_cost_after_employer_cost =
              uempOb.total_cost_before_employer_cost * employer_cost_factor;
            uempOb.Total =
              uempOb.total_cost_before_employer_cost * employer_cost_factor;
          }
        }

        //totals
        totals = getTotal(uempOb, totals);

        //check emp work days
        if (!(dateUser in calendar.datesByEmp))
          calendar.datesByEmp[dateUser] = [emId];
        else calendar.datesByEmp[dateUser].push(emId);
        //collect globals workers
        if (uempOb.globalWorker && !(emId in globalWorkers))
          globalWorkers[emId] = {
            ...uByDay,
            // cost_raw:uempOb.costPerDay*precentageEmployerCost,
            // cost_total:uempOb.costPerDay,
            hours_100_global: uempOb.hours_100_global,
            hours_total_global: uempOb.hours_total_global,
            raw_cost: 0,
            total_cost: 0,
            total_cost_before_employer_cost: 0,
            total_cost_after_employer_cost: 0,
            total_emp_cost: 0,
          };
        /////
        allDatesBol[dateUser] = false;
        if (!(dateUser in userByDate)) {
          userByDate[dateUser] = [uempOb];
        } else {
          userByDate[dateUser].push(uempOb);
        }

        if (!(emId in expenseByUser)) {
          expenseByUser[emId] = { ...uempOb };
          expenseByUser[emId].subDubRows = [{ ...uempOb }];
        } else {
          expenseByUser[emId] = objMerge(expenseByUser[emId], uempOb);
          expenseByUser[emId].subDubRows.push({ ...uempOb });

          //expenseByUser[emId].push(uByDay);
        }
      });
      //get new total
      if (newCostFactor) {
        // totals.cost_raw = totals.cost_raw*employer_cost_factor;
        // totals.Total = totals.cost_raw*employer_cost_factor;
      }
      ////check all calendar dates
      for (const date in allDays) {
        const udate = allDays[date];
        for (const wid in globalWorkers) {
          if (!calendar.datesByEmp[udate].includes(parseInt(wid))) {
            globalWorkers[wid].date = udate;
            const owid = initExpenseObj(globalWorkers[wid]);
            const TestEmploterCost = checkDateValidCost(owid.date);
            if (newCostFactor && TestEmploterCost) {
              if (TestEmploterCost && NewTotalCosts) {
                owid.employer_cost = TestEmploterCost;
                owid.total_cost_after_employer_cost =
                  owid.total_cost_before_employer_cost + TestEmploterCost;
                owid.Total =
                  owid.total_cost_before_employer_cost + TestEmploterCost;
              } else {
                owid.total_cost_after_employer_cost =
                  owid.total_cost_before_employer_cost * employer_cost_factor;
                owid.Total =
                  owid.total_cost_before_employer_cost * employer_cost_factor;
              }
            }

            totals = getTotal(owid, totals);

            if (udate in userByDate) userByDate[udate].push(owid);
            else userByDate[udate] = [owid];

            if (!(wid in expenseByUser)) {
              expenseByUser[wid] = { ...owid };
              expenseByUser[wid].subDubRows = [{ ...owid }];
            } else {
              expenseByUser[wid] = objMerge(expenseByUser[wid], owid);
              expenseByUser[wid].subDubRows.push({ ...owid });
            }
          }
        }
      }
      //new totals
      if (NewTotalCosts) {
        totalCost = totals.cost_total;
        employer_cost_factor = totals.cost_total / totals.cost_raw;
      }

      depBreakdown.forEach((depSum) => {
        if (filterOptions.length)
          if (!filterOptions.includes(depSum.category)) return;
        depSum.total_emp_cost = totalCost;
        const dsOb = initExpenseObj(depSum, 2);
        const depSumId = depSum.category;
        if (!(depSumId in expenseByDepartment)) {
          expenseByDepartment[depSumId] = { ...dsOb };

          expenseByDepartment[depSumId].department_name = depSum.category_name;
          expenseByDepartment[depSumId].employee_type = depSum.category;

          expenseByDepartment[depSumId].subPaRows = Object.values(
            expenseByUser
          ).filter((ex) => depSumId === ex.employee_type);

          expenseByDepartment[depSumId] = sumDepartment(
            expenseByDepartment[depSumId],
            totalCost
          );

          expenseByDepartment[depSumId].avg = 0;
          //expenseByDepartment[depSumId].cost=labor.total_cost_after_employer_cost;
          expenseByDepartment[depSumId].sum =
            expenseByDepartment[depSumId].total_cost_after_employer_cost;
          expenseByDepartment[depSumId].cost_raw =
            expenseByDepartment[depSumId].total_cost_after_employer_cost;
          expenseByDepartment[depSumId].cost_total =
            expenseByDepartment[depSumId].total_cost_after_employer_cost;
          expenseByDepartment[depSumId].total_including_tips =
            expenseByDepartment[depSumId].total_cost_after_employer_cost;
        } else {
          expenseByDepartment[depSumId] = sumDepartment(
            expenseByDepartment[depSumId],
            totalCost
          );

          //expenseByDepartment[depSumId].cost+=labor.total_cost_after_employer_cost;
          expenseByDepartment[depSumId].sum +=
            expenseByDepartment[depSumId].total_cost_after_employer_cost;
          expenseByDepartment[depSumId].cost_raw +=
            expenseByDepartment[depSumId].total_cost_after_employer_cost;
          expenseByDepartment[depSumId].cost_total +=
            expenseByDepartment[depSumId].total_cost_after_employer_cost;
          expenseByDepartment[depSumId].total_including_tips +=
            expenseByDepartment[depSumId].total_cost_after_employer_cost;
        }

        expenseByDepartment[depSumId].total_emp_cost = totalCost;
        //expenseByDepartment[depSumId].total_cost_after_employer_cost += depSum.accumulate_value;
        //expenseByDepartment[depSumId].total_emp_cost = expenseByDepartment[depSumId].total_cost_after_employer_cost-expenseByDepartment[depSumId].total_cost_before_employer_cost;
        // expenseByDepartment[depSumId].total_precent  =
        //   (expenseByDepartment[depSumId]["total_cost_after_employer_cost"] && totalIncome) ?
        //   (expenseByDepartment[depSumId]["total_cost_after_employer_cost"]/totalIncome*100).toFixed(2):0;
      });

      dateBreakdown.forEach((dateWeek) => {
        dateWeek.forEach((dateSum) => {
          if (!dateSum?.date) return;

          dateSum.total_emp_cost = totalCost;
          //calendar
          if (!(dateSum.date in calendar)) {
            calendar[dateSum.date] = { ...dateSum };
            if (
              "extra_value" in calendar[dateSum.date] &&
              calendar[dateSum.date].extra_value?.value
            ) {
              const prec = calendar[dateSum.date].extra_value?.value;
              calendar[dateSum.date].income =
                (100 / prec) * calendar[dateSum.date].value;
            } else calendar[dateSum.date].income = 0;
          }
          const hebName = getHebDayByDate(dateSum.date);
          if (!isFuture(fixDate(dateSum.date))) {
            if (!(hebName in calendar.sum)) {
              calendar.sum[hebName] = {
                count: 1,
                amount: dateSum.value,
                average: dateSum.value,
              };
              calendar.totals.count += 1;
              calendar.totals.amount += dateSum.value;
              calendar.totals.average =
                calendar.totals.amount / calendar.totals.count;
            } else {
              calendar.sum[hebName].count += 1;
              calendar.sum[hebName].amount += dateSum.value;
              calendar.sum[hebName].average =
                calendar.sum[hebName].amount / calendar.sum[hebName].count;
              calendar.totals.count += 1;
              calendar.totals.amount += dateSum.value;
              calendar.totals.average =
                calendar.totals.amount / calendar.totals.count;
            }
          }
          ///date analize
          const dsOb = initExpenseObj(dateSum, 3);
          const dateSumDate = dateSum.date;
          if (!(dateSumDate in expensesByDate)) {
            expensesByDate[dateSumDate] = { ...dsOb };

            expensesByDate[dateSumDate].index =
              Object.values(expensesByDate).length - 1;
            let depsTotals = [];

            if (dateSumDate in userByDate) {
              // expensesByDate[dateSumDate].sub_rows = groupBy(Object.values(userByDate[dateSumDate]),"employee_type");
              // expensesByDate[dateSumDate].subPaRows = groupBy(Object.values(userByDate[dateSumDate]),"employee_type");
              let depsBydate = groupBy(
                Object.values(userByDate[dateSumDate]),
                "employee_type"
              );
              for (let dep in depsBydate) {
                const depSum = sumGroup(depsBydate[dep]);
                depSum.subDubRows = [...depsBydate[dep]];
                depSum.subPaRows = depSum.subDubRows;
                depsTotals.push(depSum);
                expensesByDate[dateSumDate].subPaRows.push(depSum);
              }

              // const sumDep = sumGroup(dep);
              // sumDep.subDubRows = [];

              // expensesByDate[dateSumDate].sub_rows.push(sumDep);

              const flattenedArray = depsBydate.flat();

              // Sum the monthly_bonus_amount
              const totalBonus = flattenedArray.reduce((sum, current) => {
                return sum + (current.monthly_bonus_amount || 0);
              }, 0);
              expensesByDate[dateSumDate].monthly_bonus_amount = totalBonus;
            }

            //expensesByDate[dateSumDate].cost = dateSum.total_cost_after_employer_cost;
            expensesByDate[dateSumDate].date = dateSumDate;
            expensesByDate[dateSumDate].hebrewDate = fixDate(dateSumDate); //+" "+getHebDayByDate(dateSumDate);
            expensesByDate[dateSumDate].hebrewDateName =
              getHebDayByDate(dateSumDate);
            //expensesByDate[dateSumDate]=objMerge(expensesByDate[dateSumDate],sumGroup(depsTotals)) ;
            const summery = sumGroup(depsTotals);
            expensesByDate[dateSumDate] = joinUserRows(
              expensesByDate[dateSumDate],
              summery,
              true
            );
            //expensesByDate[dateSumDate]= sumDepartment(expensesByDate[dateSumDate],totalCost);
            expensesByDate[dateSumDate].cost = dateSum.value;
          }
        });
      });
    });
    //cleanUp//
    //dep sub_rows

    for (let dep in expenseByDepartment) {
      expenseByDepartment[dep]["sub_rows"] = {};
      for (let ebu in expenseByDepartment[dep]["subPaRows"]) {
        const uss = expenseByDepartment[dep]["subPaRows"][ebu];
        expenseByDepartment[dep]["subPaRows"][ebu]["total_precent"] = (
          (uss["total_cost_after_employer_cost"] / totalIncomeSum) *
          100
        ).toFixed(2);
        for (const euDate in uss.subDubRows) {
          const malDate = uss.subDubRows[euDate]["date"];
          const daysIncome = calendar[malDate]?.income;
          uss.subDubRows[euDate]["total_precent"] = (
            (uss.subDubRows[euDate]["total_cost_after_employer_cost"] /
              daysIncome) *
            100
          ).toFixed(2);
          if (
            isNaN(uss.subDubRows[euDate].total_precent) ||
            !isFinite(uss.subDubRows[euDate].total_precent)
          )
            uss.subDubRows[euDate].total_precent = 0;
        }

        expenseByDepartment[dep]["sub_rows"][
          expenseByDepartment[dep]["subPaRows"][ebu].employee_id
        ] = { ...expenseByDepartment[dep]["subPaRows"][ebu] };
        if ("subRows" in expenseByDepartment[dep]["subPaRows"][ebu])
          delete expenseByDepartment[dep]["subPaRows"][ebu]["subRows"];
      }
      if ("subRows" in expenseByDepartment[dep])
        delete expenseByDepartment[dep]["subRows"];
    }
    //date sub_rows
    for (let dat in expensesByDate) {
      expensesByDate[dat]["sub_rows"] = {};
      //expensesByDate[dat]["subPaRows"]=expensesByDate[dat]["subPaRows"].flat(1);
      for (let ebu in expensesByDate[dat]["subPaRows"]) {
        //totalPrecent
        const malDate = expensesByDate[dat]["subPaRows"][ebu]["date"];
        const daysIncome = calendar[malDate]?.income;

        expensesByDate[dat]["subPaRows"][ebu].total_precent = (
          (expensesByDate[dat]["subPaRows"][ebu]
            .total_cost_after_employer_cost /
            daysIncome) *
          100
        ).toFixed(2);
        if (
          isNaN(expensesByDate[dat]["subPaRows"][ebu].total_precent) ||
          !isFinite(expensesByDate[dat]["subPaRows"][ebu].total_precent)
        )
          expensesByDate[dat]["subPaRows"][ebu].total_precent = 0;
        ///sub_rows
        expensesByDate[dat]["subPaRows"][ebu]["sub_rows"] = {};
        expensesByDate[dat]["sub_rows"][
          expensesByDate[dat]["subPaRows"][ebu].department_id
        ] = { ...expensesByDate[dat]["subPaRows"][ebu] };
        for (let cbd in expensesByDate[dat]["subPaRows"][ebu]["subDubRows"]) {
          ///total precent
          expensesByDate[dat]["subPaRows"][ebu]["subDubRows"][
            cbd
          ].total_precent = (
            (expensesByDate[dat]["subPaRows"][ebu]["subDubRows"][cbd]
              .total_cost_after_employer_cost /
              daysIncome) *
            100
          ).toFixed(2);
          if (
            isNaN(
              expensesByDate[dat]["subPaRows"][ebu]["subDubRows"][cbd]
                .total_precent
            ) ||
            !isFinite(
              expensesByDate[dat]["subPaRows"][ebu]["subDubRows"][cbd]
                .total_precent
            )
          )
            expensesByDate[dat]["subPaRows"][ebu]["subDubRows"][
              cbd
            ].total_precent = 0;
          /////
          const employee_id =
            expensesByDate[dat]["subPaRows"][ebu]["subDubRows"][cbd]
              .employee_id;
          expensesByDate[dat]["subPaRows"][ebu]["sub_rows"][employee_id] = {
            ...expensesByDate[dat]["subPaRows"][ebu]["subDubRows"][cbd],
          };
          if (
            "subRows" in
            expensesByDate[dat]["subPaRows"][ebu]["subDubRows"][cbd]
          )
            delete expensesByDate[dat]["subPaRows"][ebu]["subDubRows"][cbd][
              "subRows"
            ];
        }
      }
      if ("subRows" in expensesByDate[dat])
        delete expensesByDate[dat]["subRows"];
      //expensesByDate[dat]["subPaRows"] = Object.values( expensesByDate[dat]["sub_rows"]);
    }
    //
    //goals///

    const resb = await Axios.get(`/newGoals/${selected_branch.id}/show`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const editors = {};
    const cleanData = [];
    resb.data.forEach((ob) => {
      if (ob.type == 3) {
        editors[ob.id] = false;
        ob["show"] = false;
        cleanData.push(ob);
        for (let ed in expenseByDepartment) {
          if (expenseByDepartment[ed].employee_type === ob.employee_type_id) {
            if (ob.precent === 1) {
              expenseByDepartment[ed].avg = ob.goal;
              expenseByDepartment[ed].navg = 0;
            } else if (ob.precent === 0) {
              expenseByDepartment[ed].navg = ob.goal;
              expenseByDepartment[ed].avg = 0;
            }
          }
        }
      } else if (ob.type == 6) {
        const obc = { ...ob };
        obc.department_name = "מצטבר";
        obc.employee_type_id = 999;
        cleanData.push(obc);
        const tpr = (totals.cost / totalIncomeSum) * 100;
        const tprecent = isNaN(tpr) || !isFinite(tpr) ? 0 : tpr;
        if (obc.precent == 0 && obc.goal < totals.cost) {
          setShowWarningAccu(true);
          setAccGoal("₪" + thousandSeparator(obc.goal));
        } else if (obc.precent == 1 && obc.goal < tprecent) {
          setShowWarningAccu(true);
          setAccGoal(obc.goal + "%");
        } else {
          setShowWarningAccu(false);
          const stringAccGoal =
            obc.precent == 0
              ? "₪" + thousandSeparator(obc.goal)
              : obc.goal + "%";
          setAccGoal(stringAccGoal);
        }
      }
    });
    setPrecentageEmployerCost(employer_cost_factor);
    setEditData({ ...editors });
    setGoalsData(cleanData);

    //settters
    Object.keys(expenseByDepartment).forEach((id) => (dataOb[id] = false));
    setShowTablesData(dataOb);
    const expensesByDateArray = Object.values(expensesByDate).sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );
    //const expensesByDateArray =Object.values(expensesByDate);

    //setShowDateData(allDatesBol);
    setLastYearIncome(totalIncomeSum);
    setUserHours({ ...expensesByDate });
    setExpenses({ ...expenseByDepartment });
    setExpensesArray(Object.values(expenseByDepartment));
    setShowDateData(allDatesBol);
    setData(expensesByDateArray);

    setCalendarData(calendar);

    setAllData({
      dateData: getDaysArray(selectedDates, "days", 1),
      userData: expenseByUser,
      boxes: obj,
      pieData: Object.values(expenseByDepartment),
      barsData: totals,
      totalPie: totals.cost,
      userByDate: Object.values(userByDate),
    });
    setSelectedDateData({
      ...expensesByDateArray[expensesByDateArray.length - 1],
    });

    return [
      { ...expensesByDate },
      { ...expenseByDepartment },
      { ...expenseByUser },
      { ...calendar },
    ];
  };

  const workingDays = useMemo(() => {
    let workingDays = 0;
    const branchDaysOfWOrk = selected_branch?.day_of_work
      ? selected_branch.day_of_work
      : 0;
    if (!branchDaysOfWOrk) return 0;
    const start = moment(selectedDates.startDate).add(1, "days");
    const end = moment(selectedDates.endDate);

    for (var m = moment(start); m.diff(end, "days") < 1; m.add(1, "days")) {
      if (branchDaysOfWOrk.includes((m.day() + 1).toString())) {
        workingDays++;
      }
    }
    return workingDays > 0 ? workingDays : 1;
  }, [selectedDates]);
  const workingDaysUntillEndOfMonth = useMemo(() => {
    let workingDays = 0;
    const branchDaysOfWOrk = selected_branch?.day_of_work
      ? selected_branch.day_of_work
      : 0;
    if (!branchDaysOfWOrk) return 0;
    const start = moment(selectedDates.startDate).add(1, "days");
    const end = moment(selectedDates.endDate).endOf("month");

    for (var m = moment(start); m.diff(end, "days") < 1; m.add(1, "days")) {
      if (branchDaysOfWOrk.includes((m.day() + 1).toString())) {
        workingDays++;
      }
    }
    return workingDays > 0 ? workingDays : 1;
  }, [selectedDates]);
  const fetchIncomeData = useMemo(
    () => async () => {
      try {
        if (!selected_branch || loading) return;
        setLoading(true);

        await fetchEmployerCosts();
        const exdata = await getExpenseSummary();

        const results = await newUniteExpenseObj(
          [exdata],
          selectedDates,
          filterByDep
        ); //pass as array for future date fetcher option
        setLoading(false);

        //fetch transfers//
        // const transfers = await Axios.get(`/laborCosts/costs`,{

        //   params: {
        //     branch: selected_branch.id,
        //     startDate,
        //     endDate,
        //     employeeId:-9
        //   },
        //   headers: { Authorization: `Bearer ${token}` },
        // });

        //   uniteTransfers(transfers,udata2);

        /////

        const newCal = await fetchData(results[3]);
        setDatTable(!datTable);
        //complete calendar
        if (
          workingDaysUntillEndOfMonth > results[3].totals.count &&
          results[3].totals.count < 28
        )
          await fetchCompleteCalendar(
            newCal[0],
            workingDaysUntillEndOfMonth - workingDays
          );
        //getRealIncome();
        await newUserHourCompare(results);
        await fetchBranchViewOptions();
      } catch (e) {
        setLoading(false);
        //console.error(e);
        if (e.response)
          if (e.response.status === 401) {
            logout();
          }
      }
    },
    [
      employerCosts,
      filterByDep,
      workingDays,
      workingDaysUntillEndOfMonth,
      token,
      logout,
      selected_branch,
      selectedDates,
      allData,
      selectedDateData,
      expenses,
      goalsData,
      depHours,
      data,
      calendarData,
      accGoal,
      showWarningAccu,
      precentageEmployerCost,
    ]
  );

  useEffect(() => {
    //fetchBranchGoals();
  }, []);
  useEffect(() => {
    fetchIncomeData();
  }, []);

  const calcDiffPercent = useMemo(
    () =>
      (current_amount = 0, amount_to_compare = 0) => {
        if (amount_to_compare === 0)
          return { diff: 0, negative: 0, percent: 0 };
        let percent = 0;
        let negative = false;
        let diff = current_amount - amount_to_compare;
        negative = diff > 0;

        if (current_amount === 0) {
          if (amount_to_compare > 0) {
            percent = 100;
          } else {
            percent = 0;
          }
        } else {
          percent = Math.abs((diff / current_amount) * 100).toFixed();
        }

        return {
          diff: Math.abs(diff),
          percent: percent,
          negative,
        };
      },
    []
  );

  const dayAvg = useMemo(() => {
    let res = 0;
    for (let item of data) {
      res += item.cost;
    }
    // for (let item of Object.values(expenses)) {
    //   res += item.cost;
    // }
    return workingDays ? res / workingDays : 0;
  }, [data, expenses]);

  const vs_dailyAvg = //{ diff: 0, percent: 0, negative: false };
    useMemo(() => {
      const incomeExtrasTotal = data[selectedDateData.date]
        ? incomeExtras[selectedDateData.date].cost
        : 0;
      if (isEmpty(selectedDateData) && !data) {
        return { diff: 0, percent: 0, negative: false };
      }
      return calcDiffPercent(dayAvg, selectedDateData.cost);
    }, [calcDiffPercent, dayAvg, selectedDateData, data]);

  const calcFoodCost = (mass) => {
    let accFoodCost = Object.values(mass).reduce((a, { food_cost }) => {
      if (typeof a == "object") return a.food_cost + food_cost;
      return a + food_cost;
    });

    return accFoodCost;
  };

  const total = useMemo(
    () =>
      Object.values(data).reduce(
        (sum, curr) => sum + curr.total_cost_before_employer_cost,
        0
      ),
    [data]
  );
  const totalExtra = useMemo(
    () =>
      Object.values(data).reduce(
        (sum, curr) => sum + curr.total_cost_after_employer_cost,
        0
      ),
    [data]
  );

  const vs_TotalLastYear = useMemo(() => {
    if (lastYearIncome == 0 || totalExtra == 0)
      return { diff: 0, negative: 0, percent: 0 };
    else return calcDiffPercent(lastYearIncome, totalExtra);
  }, [calcDiffPercent, lastYearIncome, totalExtra]);

  const getRealTravelCost = (onLine) => {
    const freeMonthly = onLine.free_monthly;
    const freeMonthlyCost = onLine.free_monthly_cost;
    const travelCost = onLine.travel_cost;
    const constTravelCost = onLine.const_travel_cost;
    const constTravelCostOverwrite = onLine.const_travel_cost_overwrite;
    if (constTravelCostOverwrite) {
      return travelCost;
    } else if (constTravelCost) {
      return constTravelCost;
    } else {
      //if(travelCost>freeMonthly)
      if (freeMonthly) return freeMonthlyCost / onLine.actualWorkDays;
      return travelCost;
    }
  };

  const getLast4DaysAvg = useMemo(() => {
    if (!data.length) return 0;
    let last = data.slice(-4);
    if (last.length) {
      let dayCost = last.reduce((prev, cur) => {
        if (!prev) return cur.cost;
        return prev.cost + cur.cost;
      });
      const dayFoureAvg = dayCost / 4;
      setLast4DaysAverage(dayFoureAvg);
      return dayFoureAvg;
    }
    return 0;
  }, [data, last4DaysAverage]);

  const vs_last4days = //{ diff: 10, percent: 10, negative: false };
    useMemo(() => {
      let incomeExtrasTotal;
      if (data.length > 3)
        incomeExtrasTotal = data.slice(-4).reduce((a, { cost }) => {
          if (typeof a == "object") return a.cost + cost;
          return a + cost;
        });

      if (isEmpty(data) && !incomeExtrasTotal) {
        return { diff: 0, percent: 0, negative: false };
      }
      let x = calcDiffPercent(getLast4DaysAvg, incomeExtrasTotal);
      //   incomeExtrasTotal);
      return x;
    }, [calcDiffPercent, data, incomeExtras, getLast4DaysAvg]);
  const pieData = useMemo(() => {
    if (!allData) return [];
    return allData?.pieData
      ?.sort((a, b) => b.cost_total - a.cost_total)

      .map((x) => ({
        label: decodeHtml(x.department_name ? x.department_name : "אחר"),
        percent: (x.cost_total / allData.totalPie) * 100,
        id: x.id,
        value: Number(x.cost_total).toFixed(1),
      }));
  }, [allData]);

  const {
    dateHiddenColumnsMemo,
    dateDefaultVisibleColumnsMemo,

    supplierCostumeValuePreparation,
    departmentsSortBy,
    dHiddenColumnsMemo,
    dDefaultVisibleColumnsMemo,
    oDisplayTableMemo,
    dateSortBy,
    depBreakdownColumnsMemo,
    userColumnsByDepartmentMemo,
    // getCheckedRows,
  } = useMemo(
    () => ({
      moreButtons: (
        <IconButton>
          <FaTimes />
        </IconButton>
      ),

      dHiddenColumnsMemo: dHiddenColumns,
      dDefaultVisibleColumnsMemo: dDefaultVisibleColumns,

      oDisplayTableMemo: openDisplayTableMemo,
      userColumnsByDepartmentMemo: userColumnsByDepartment,
      depBreakdownColumnsMemo: depBreakdownColumns,
      dateHiddenColumnsMemo: dateHiddenColumns,
      dateDefaultVisibleColumnsMemo: dateDefaultVisibleColumns,
      dateSortBy: [{ id: "date", desc: true }],
      departmentsSortBy: [{ id: "department_name" }],
    }),
    []
  );

  return (
    <LayoutContent>
      <ViewOrder
        {...{
          order_id: viewToShow ? viewToShow.new_order_id : null,
          onClose: () => setViewToShow(null),
        }}
      />
      <h1 style={isMobile ? {} : { padding: "1rem 2rem" }}>
        ניתוח הוצאות חודשי
      </h1>
      <h3>דשבורד</h3>
      <div className="flex-end flex-align-start">
        <h3>
          {" "}
          עברו
          {` ${workingDays} `}
          ימי עבודה מתוך
          {` ${workingDaysUntillEndOfMonth} `}
          החודש{" "}
        </h3>
      </div>
      <div style={{ padding: "0 1rem" }} className="flex-start flex-align-end">
        <label style={isMobile ? { width: "300%" } : {}}>
          תקופה
          <Dropdown
            style={
              isMobile
                ? {
                    minWidth: "100%",
                    fontSize: "1.4rem",
                    display: "block",
                    margin: "0",
                  }
                : {
                    width: "100%",
                    textAlign: "center",
                    fontSize: " 1.4rem",
                    fontWeight: "500",
                    maxWidth: "24rem",
                    minWidth: "16rem",
                    borderRadius: "5px",
                    color: "#747474",
                    backgroundColor: "#F7F7F7",
                    position: "relative",
                  }
            }
            contentStyle={{ width: "fit-content" }}
            text={selectedDates?.label}
          >
            {isMobile ? (
              <DefinedRange
                ranges={[selectedDates]}
                staticRanges={hebStaticRanges}
                local={he}
                style={{ width: "185px" }}
                onChange={(item) => {
                  setSelectedDates(item.selection);
                }}
              />
            ) : (
              <DateRangePicker
                maxDate={new Date()}
                selectedRange={selectedDates}
                setSelectedRange={setSelectedDates}
                futureDisable={true}
              />
            )}
          </Dropdown>
        </label>
        <div
          style={isMobile ? {} : { marginLeft: "2rem", marginRight: "2rem" }}
        >
          <label>
            מחלקה
            <MultiSelectCheckbox
              title="מחלקות"
              style={{ maxWidth: "unset" }}
              checked={filterByDep}
              setChecked={setFilterByDep}
              options={departmentOptionsObj}
            />
          </label>
        </div>
        <Button
          style={
            isMobile
              ? { height: "4rem", margin: "0" }
              : { height: "4rem", pointerEvents: !loading ? "auto" : "none" }
          }
          onClick={() => {
            if (!loading) {
              fetchIncomeData();
            }
          }}
          className={`btn--submit ${isMobile ? "fluid" : ""}`}
          textValue="בצע"
        />
      </div>

      <SummeryContainer
        {...{
          data,
          dates: allData.dateData,
          expenses,
          selectedDateData,
          setSelectedDateData,
          last_4_days: getLast4DaysAvg,
          total: !isNaN(total) ? total : allData.totalPie,
          totalExtra: !isNaN(totalExtra) ? totalExtra : allData.totalPie,
          workingDaysUntillEndOfMonth,
          dayAvg,
          lastYearIncome: supplierData,
          totalIncome: lastYearIncome,
          loading,
          incomes: incomeExtras,
          showWarningAccu,
          accGoal,
          openDisplayTable,
          precentageEmployerCost,
          calendarData,
        }}
        onClickBox={setSelectedTypeOfData}
        selectedBox={selectedTypeOfData}
      />

      {!isMobile && (
        <div style={{ padding: "3.5rem 0" }} className="flex-even">
          <div style={{ marginLeft: "0.5rem" }}>
            <SummeryBox
              className="summeryBox__long"
              height="640px"
              width="100%"
            >
              {loading ? (
                <div style={{ height: "100%" }} className="flex-center">
                  <ClipLoader size={60} color="#00a2ea" loading={true} />
                </div>
              ) : (
                <>
                  <h1>
                    <u>מדד יומי</u>
                  </h1>
                  <div className="summeryBox--section ">
                    <p className="summeryBox--section__child">
                      ממוצע 4 ימי {getHebDayByDate(selectedDateData.date)}{" "}
                      האחרונים
                    </p>
                    <div
                      className="summeryBox--section__child"
                      style={
                        vs_last4days.negative
                          ? { color: COLOR_ABORT }
                          : { color: COLOR_CONFIRM }
                      }
                    >
                      <p>
                        {getHebDayByDate(selectedDateData.date) in
                          calendarData.sum &&
                        !isNaN(
                          calendarData.sum[
                            getHebDayByDate(selectedDateData.date)
                          ].average / calendarData.totals.amount
                        )
                          ? (
                              (calendarData.sum[
                                getHebDayByDate(selectedDateData.date)
                              ].average /
                                calendarData.totals.amount) *
                              100
                            ).toFixed(0)
                          : 0}
                        %
                      </p>
                      <BtnTooltip placement="left" text='סה"כ הפרש ללא ע"מ'>
                        <p>
                          ₪
                          {getHebDayByDate(selectedDateData.date) in
                          calendarData.sum
                            ? thousandSeparator(
                                calendarData.sum[
                                  getHebDayByDate(selectedDateData.date)
                                ].average / precentageEmployerCost
                              )
                            : 0}
                        </p>
                      </BtnTooltip>

                      <BtnTooltip placement="left" text='סה"כ הפרש עם ע"מ'>
                        <p>
                          ₪
                          {getHebDayByDate(selectedDateData.date) in
                          calendarData.sum
                            ? thousandSeparator(
                                calendarData?.sum[
                                  getHebDayByDate(selectedDateData.date)
                                ].average
                              )
                            : 0}
                        </p>
                      </BtnTooltip>
                    </div>
                    {vs_last4days.negative ? (
                      <FaLongArrowAltDown color={COLOR_ABORT} size="4em" />
                    ) : (
                      <FaLongArrowAltUp color={COLOR_CONFIRM} size="4em" />
                    )}
                  </div>

                  <div className="summeryBox--section ">
                    <p className="summeryBox--section__child"> ממוצע יומי</p>
                    <div
                      className="summeryBox--section__child"
                      style={
                        vs_dailyAvg.negative
                          ? { color: COLOR_ABORT }
                          : { color: COLOR_CONFIRM }
                      }
                    >
                      <p>{vs_dailyAvg.percent}%</p>

                      <BtnTooltip placement="left" text='סה"כ ללא ע"מ'>
                        <p>
                          ₪
                          {thousandSeparator(
                            calendarData.totals.average / precentageEmployerCost
                          )}
                        </p>
                      </BtnTooltip>

                      <BtnTooltip placement="left" text='סה"כ עם ע"מ'>
                        <p>₪{thousandSeparator(calendarData.totals.average)}</p>
                      </BtnTooltip>
                    </div>
                    {vs_dailyAvg.negative ? (
                      <FaLongArrowAltDown color={COLOR_ABORT} size="4em" />
                    ) : (
                      <FaLongArrowAltUp color={COLOR_CONFIRM} size="4em" />
                    )}
                  </div>
                  <h1 style={{ marginTop: "1rem" }}>מדד חודשי</h1>
                  <div className="summeryBox--section ">
                    <p className="summeryBox--section__child">
                      מצטבר חודשי שנה שעברה
                    </p>
                    <div
                      className="summeryBox--section__child"
                      style={
                        vs_TotalLastYear.negative
                          ? { color: COLOR_ABORT }
                          : { color: COLOR_CONFIRM }
                      }
                    >
                      <p>
                        {!isNaN(vs_TotalLastYear.percent)
                          ? vs_TotalLastYear.percent
                          : 0}
                        %
                      </p>
                      <BtnTooltip placement="left" text='סה"כ הפרש ללא ע"מ'>
                        <p>
                          ₪
                          {thousandSeparator(
                            decreaseTax(calendarData.lastYear?.totals.average)
                          )}
                        </p>
                      </BtnTooltip>

                      <BtnTooltip placement="left" text='סה"כ הפרש עם ע"מ'>
                        <p>
                          ₪
                          {thousandSeparator(
                            calendarData.lastYear?.totals.average
                          )}
                        </p>
                      </BtnTooltip>
                    </div>
                    {vs_TotalLastYear.negative ? (
                      <FaLongArrowAltDown color={COLOR_ABORT} size="4em" />
                    ) : (
                      <FaLongArrowAltUp color={COLOR_CONFIRM} size="4em" />
                    )}
                  </div>

                  <h1 style={{ marginTop: "1rem" }}> הכנסות</h1>
                  <div className="summeryBox--section ">
                    <p
                      className="summeryBox--section__child"
                      style={{ color: COLOR_CONFIRM, width: "100%" }}
                    >
                      ₪{thousandSeparator(lastYearIncome.toFixed(2))}
                    </p>

                    <p
                      className="summeryBox--section__child"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      לא כולל מע״מ
                    </p>
                  </div>
                </>
              )}
            </SummeryBox>
          </div>
          <div
            className="u-center-text hiddenBar"
            style={{
              width: "42%",
              minWidth: "fit-content",
              maxHeight: "600px",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <div style={{ marginRight: "15rem" }}>
              {!loading && allData?.pieData ? (
                <SummeryBox
                  className="summeryBox__transperant"
                  style={{ boxShadow: "none !important" }}
                  height="600px"
                  width="60%"
                >
                  <h1>
                    <u>שעות תכנון מול פועל</u>
                  </h1>
                  <div>
                    <table
                      style={{
                        fontSize: "18px",
                        padding: "20px",
                        th: {
                          fontSize: "15px",
                          marginleft: "10px",
                        },
                        td: {
                          fontSize: "15px",
                          marginleft: "10px",
                        },
                      }}
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th>תכנון</th>
                          <th>פועל</th>
                          <th>הפרש</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allData.pieData.map((depart) => (
                          <tr
                            key={depart.id}
                            style={{
                              td: {
                                marginLeft: "8px",
                                padding: "8px",
                              },
                            }}
                          >
                            <td key={"td" + depart.id}>
                              {depart.department_name}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Tooltip title="שעתיים">
                                  <span>
                                    {depHours[depart.employee_type]?.pl
                                      ? depHours[
                                          depart.employee_type
                                        ]?.pl.toFixed(2)
                                      : 0}
                                  </span>
                                </Tooltip>
                                <Tooltip title="גלובאלים">
                                  <span>
                                    {depHours[depart.employee_type]
                                      ?.plannedGlobal
                                      ? `(${depHours[
                                          depart.employee_type
                                        ]?.plannedGlobal.toFixed(2)})`
                                      : `(0)`}
                                  </span>
                                </Tooltip>
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Tooltip title="שעתיים">
                                  <span>
                                    {depHours[depart.employee_type]?.ac
                                      ? depHours[
                                          depart.employee_type
                                        ]?.ac.toFixed(2)
                                      : 0}
                                  </span>
                                </Tooltip>
                                <Tooltip title="גלובאלים">
                                  <span>
                                    {depHours[depart.employee_type]
                                      ?.actualGlobal
                                      ? `(${depHours[
                                          depart.employee_type
                                        ]?.actualGlobal.toFixed(2)})`
                                      : `(0)`}
                                  </span>
                                </Tooltip>
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Tooltip title="שעתיים">
                                  <span>
                                    {depHours[depart.employee_type]?.ac &&
                                    depHours[depart.employee_type]?.pl
                                      ? (
                                          depHours[
                                            depart.employee_type
                                          ]?.ac.toFixed(2) -
                                          depHours[
                                            depart.employee_type
                                          ]?.pl.toFixed(2)
                                        ).toFixed(2)
                                      : 0}
                                  </span>
                                </Tooltip>
                                <Tooltip title="גלובאלים">
                                  <span>
                                    {depHours[depart.employee_type]
                                      ?.plannedGlobal &&
                                    depHours[depart.employee_type]?.actualGlobal
                                      ? `(${(
                                          depHours[
                                            depart.employee_type
                                          ]?.actualGlobal.toFixed(2) -
                                          depHours[
                                            depart.employee_type
                                          ]?.plannedGlobal.toFixed(2)
                                        ).toFixed(2)})`
                                      : "(0)"}
                                  </span>
                                </Tooltip>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </SummeryBox>
              ) : (
                <SummeryBox
                  className="summeryBox__transperant"
                  style={{ boxShadow: "none  !important" }}
                  height="640px"
                  width="60%"
                >
                  <div style={{ height: "100%" }} className="flex-center">
                    <ClipLoader size={60} color="#00a2ea" loading={true} />
                  </div>
                </SummeryBox>
              )}
            </div>
          </div>
          <div className="u-center-text" style={{ width: "42%" }}>
            {false ? (
              <div style={{ padding: "5rem 0" }} className="flex-center">
                <Skeleton
                  variant="circle"
                  width="calc(32vw - 150px)"
                  height="calc(32vw - 150px)"
                />
              </div>
            ) : (
              <Pie
                isPrice={true}
                total={allData?.totalPie}
                data={pieData}
                title={"התפלגות לפי מחלקה"}
                title_percent={``}
              />
            )}
          </div>
        </div>
      )}

      {!isMobile && (
        <div style={{ margin: "2rem 0" }}>
          {/*<ButtonIcon
          style={{
            //isMobile ? { height: "4rem", margin: "0" } : { height: "4rem" },
            height: "4rem",
            position:"relative",
            right: "calc(100vw - 800px)",
            top: "6.9rem",
            backgroundColor:"transparent"
          }}
          onClick={() => setOpenGoalsForm(true)}
          className={`btn--success ${isMobile ? "fluid" : ""}`}
          icon={FiTarget}
          alt="יעדים"
        />*/}
          {datTable ? (
            <>
              <ReactTable
                {...{
                  styles: {
                    wrapper: {
                      maxHeight: "calc(100vh - 10rem)",
                      direction: "rtl !important",
                    },
                    container: {
                      maxHeight: "calc(100vh - 23.4rem)",
                      direction: "rtl !important",
                    },
                    direction: "rtl  !important",
                  },
                  setModalHiddenColumns: (cols, unchecked) => {
                    setDateTableBreakdownColumns(cols);
                    saveViewOptions(cols, "Date", unchecked);
                  },
                  collapsedSearch: false,
                  translation_endpoint: "expenseDashboardDatesDepartments",
                  title: "ניתוח לפי מחלקות/ניתוח לפי תאריך",
                  titleDatesRange:
                    fixDate(selectedDates.startDate) +
                    "~" +
                    fixDate(selectedDates.endDate),
                  titleFunction: {
                    type: "arrows",
                    func: setDatTable,
                    params: datTable,
                    title: "ניתוח לפי תאריך",
                  },
                  customFooterTitle: "סה״כ",
                  aggregates: excelAgg,
                  cellsPropsById: (id) => {
                    if (["hebrewDate"].includes(id)) {
                      return {
                        oDisplayTableMemo,
                      };
                    }
                  },
                  subCellsPropsById: (id) => {
                    if (
                      ["full_name"].includes(id) ||
                      ["department_name"].includes(id)
                    ) {
                      return { oDisplayTableMemo };
                    }
                  },
                  subDubCellsPropsById: (id) => {
                    if (
                      ["full_name"].includes(id) ||
                      ["department_name"].includes(id)
                    ) {
                      return { oDisplayTableMemo };
                    }
                  },
                  columns: dateBreakdownColumns,
                  // hiddenColumns: [
                  //   "travel_cost",
                  //   "meal_cost"
                  // ],
                  // subPaRowstyle:{
                  //     MuiTableStickyHeader:{
                  //       minHeight: "398px",
                  //       margin: "auto",
                  //       width:"90%",
                  //     },
                  //     container: {
                  //       minHeight: "398px",
                  //       margin: "auto",
                  //       width:"90%",
                  //     },
                  // },
                  stickyHeader: true,
                  //subColumns: depBreakdownColumns,
                  //dubSubColumns:userColumns,
                  hiddenColumns: dateHiddenColumnsMemo,
                  defaultVisibleColumns: dateTableBreakdownColumns
                    ? dateTableBreakdownColumns
                    : dateDefaultVisibleColumnsMemo,

                  moreButtons: privileges_buttons.includes(69) ? (
                    <ButtonIcon
                      style={{
                        //isMobile ? { height: "4rem", margin: "0" } : { height: "4rem" },
                        borderRadius: "30px",
                        position: "relative",
                        top: "3px",
                        backgroundColor: "transparent",
                      }}
                      onClick={() => setOpenGoalsForm(true)}
                      className={`btn--success ${isMobile ? "fluid" : ""}`}
                      icon={FiTarget}
                      alt="יעדים"
                    />
                  ) : (
                    ""
                  ),
                  footerColor: "#eeeeef",
                  showFilters: false,
                  data: data ? data : [],
                  isExportedToExcel: true,
                  loading: loading,
                  isPaginate: true,
                  showFooter: true,
                  showCheckbox: false,
                  sortBy: dateSortBy,
                  overflow: false,
                }}
              />
            </>
          ) : (
            <>
              <ReactTable
                {...{
                  styles: {
                    wrapper: {
                      maxHeight: "calc(100vh - 10rem)",
                      direction: "rtl",
                    },
                    container: {
                      maxHeight: "calc(100vh - 23.4rem)",
                      direction: "rtl",
                    },
                    direction: "rtl",
                  },
                  setModalHiddenColumns: (cols, unchecked) => {
                    setDepTableBreakdownColumns(cols);
                    saveViewOptions(cols, "Dep", unchecked);
                  },
                  collapsedSearch: false,
                  translation_endpoint: "expenseDashboardDatesDepartments",
                  title: "ניתוח לפי מחלקות/ניתוח לפי תאריך",
                  titleFunction: {
                    type: "arrows",
                    func: setDatTable,
                    params: datTable,
                    title: "ניתוח לפי מחלקות",
                  },
                  customFooterTitle: "סה״כ",
                  aggregates: excelAgg,
                  titleDatesRange:
                    fixDate(selectedDates.startDate) +
                    "~" +
                    fixDate(selectedDates.endDate),
                  subCellsPropsById: (id) => {
                    if (
                      ["full_name"].includes(id) ||
                      ["department_name"].includes(id)
                    ) {
                      return { oDisplayTableMemo };
                    }
                  },
                  subDubCellsPropsById: (id) => {
                    if (
                      ["full_name"].includes(id) ||
                      ["department_name"].includes(id)
                    ) {
                      return { oDisplayTableMemo };
                    }
                  },
                  cellsPropsById: (id) => {
                    if (privileges_buttons.includes(70)) {
                      if (
                        ["department_name"].includes(id) ||
                        ["employer_cost"].includes(id)
                      ) {
                        return {
                          oDisplayTableMemo,
                        };
                      }
                    } else {
                      if (["department_name"].includes(id)) {
                        return {
                          oDisplayTableMemo,
                        };
                      }
                    }
                  },
                  columns: depBreakdownColumnsMemo,
                  hiddenColumns: dHiddenColumns,
                  defaultVisibleColumns: depTableBreakdownColumns
                    ? depTableBreakdownColumns
                    : dDefaultVisibleColumnsMemo,
                  moreButtons: privileges_buttons.includes(69) ? (
                    <ButtonIcon
                      style={{
                        //isMobile ? { height: "4rem", margin: "0" } : { height: "4rem" },
                        borderRadius: "30px",
                        backgroundColor: "transparent",
                        position: "relative",
                        top: "3px",
                      }}
                      onClick={() => setOpenGoalsForm(true)}
                      className={`btn--success ${isMobile ? "fluid" : ""}`}
                      icon={FiTarget}
                      alt="יעדים"
                    />
                  ) : (
                    ""
                  ),
                  //additionalData:goalsData,

                  showFilters: false,
                  //subColumns: userColumnsByDepartmentMemo,
                  //dubSubColumns:dateBreakdownColumns,
                  footerColor: "#eeeeef",

                  data: expensesArray,
                  isExportedToExcel: true,
                  loading: loading,
                  isPaginate: true,
                  showFooter: true,
                  showCheckbox: false,
                  stickyHeader: true,
                  sortBy: departmentsSortBy,
                  overflow: false,
                }}
              />
            </>
          )}
        </div>
      )}
      {displayArray?.mob && (
        <DisplayTable
          {...{
            data: displayArray?.data,
            title: displayArray?.title,
            columns: displayArray?.columns,
            open: displayArray?.open,
            message: displayArray?.message,
            onClose: () => setDisplayArray({}),
            setViewToShow,
          }}
        />
      )}
      {displayArray?.hour && (
        <SimpleDisplayTable
          {...{
            data: displayArray?.data,
            title: displayArray?.title,
            columns: displayArray?.columns,
            hour: displayArray?.hour,
            message: displayArray?.message,
            onClose: displayArray?.onClose,
            modalHiddenColumns,
            setModalHiddenColumns: (cols, unchecked) => {
              setModalHiddenColumns(cols);
              saveViewOptions(cols, "", unchecked);
            },
          }}
        />
      )}
      {displayArray?.costs && privileges_buttons.includes(70) && (
        <CostsModal
          {...{
            data: displayArray?.data,
            title: displayArray?.title,
            columns: displayArray?.columns,
            hour: displayArray?.hour,
            message: displayArray?.message,
            onClose: () => setDisplayArray({}),
            loading,
            setLoading,
            selected_branch,
            setEmployerCosts,
            employerCosts,
            fetchData: async () => await fetchIncomeData(),
            token,
          }}
        />
      )}
      {goalsData.length > 0 ? (
        <GoalsForm
          {...{
            title: `ניהול יעדים `,
            open: openGoalsForm,
            data: goalsData,
            setData: setGoalsData,
            setEditData: setEditData,
            selected_branch,
            expenses: expenses,
            setExpenses: setExpenses,
            token,
            fetchBranchGoals,
            loading,
            departmentOptions: { ...departmentOptions },
            onClose: () => setOpenGoalsForm(false),
            //setViewToShow,
          }}
        />
      ) : (
        <GoalsForm
          {...{
            title: `ניהול יעדים `,
            open: openGoalsForm,
            data: goalsData,
            setData: setGoalsData,
            expenses: expenses,
            setExpenses: setExpenses,
            token: token,
            selected_branch,
            fetchBranchGoals,
            loading,
            onClose: () => setOpenGoalsForm(false),
            //setViewToShow,
          }}
        />
      )}
      {isMobile && (
        <div
          style={{
            height: "9rem",
            marginTop: "15px",
          }}
        >
          <ButtonIcon
            style={
              isMobile
                ? { height: "4rem", margin: "0" }
                : { height: "0rem", right: "140rem" }
            }
            onClick={() => setOpenGoalsForm(true)}
            className={`btn--success ${isMobile ? "fluid" : ""}`}
            icon={FiTarget}
            alt="יעדים"
          />
          <SummeryBoxTable
            {...{
              data,
              dates: allData.dateData,
              expenses,
              showTablesData,
              setShowTablesData,
              showDateData,
              setShowDateData,
              last_4_days: getLast4DaysAvg,
              total,
              totalExtra,
              workingDaysUntillEndOfMonth,
              dayAvg,
              lastYearIncome: supplierData,
              totalIncome: lastYearIncome,
              loading,
              incomes: incomeExtras,
              datTable,
              setDatTable,
              depBreakdownColumns,
              userColumns,
              dateBreakdownColumns,
              allData,
            }}
            openDisplayTable={openDisplayTable}
            onClickBox={setSelectedTypeOfData}
            selectedBox={selectedTypeOfData}
          />
        </div>
      )}
    </LayoutContent>
  );
};

const Observed = observer(Dashboard);

const WithStoreConnection = (props) => {
  const { dateRangePicker, main } = useStore();
  const { dateRangeFormatted, labelFormatted, initDefaultValues } =
    dateRangePicker;
  // const { isMobile } = main;

  // useEffect(() => {
  //   initDefaultValues({
  //     startDate: startOfMonth(addMonths(new Date(), -1)),
  //     endDate: endOfMonth(addMonths(new Date(), -1)),
  //     key: "selection",
  //     label: "חודש שעבר",
  //   });
  // }, []);
  return (
    <Observed
      {...{
        setError,
        dateRangeFormatted,
        date_label: labelFormatted,
        // isMobile,
        ...props,
      }}
    />
  );
};

const DashObserver = observer((props) => WithStoreConnection(props));

const mapStateToProps = (state) => {
  const { token, user } = state.auth;
  const { selected_branch, branches } = state.branches;
  const { employeeTypes } = state.generalData;
  const { privileges_buttons } = state.user_privileges;
  return {
    token,
    network_id: user.network_id,
    selected_branch,
    branches,
    privileges_buttons,
    employeeTypes,
  };
};

export default connect(mapStateToProps, {
  logout,
  setError,
  setActive,
  setTitle,
  setIsFrame,
  setDialogModal,
})(DashObserver);
