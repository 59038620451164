import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import React, {
  forwardRef,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Dropdown from "../../components/dropdown/Dropdown";
import {
  decodeHtml,
  fixDate,
  getSafe,
  getSafeDivision,
  useDidMountEffect,
} from "../../helpers";
import { useDateFetcher } from "../../hooks/useDateFetcher";
import {
  setActive,
  setIsFrame,
  setTitle,
} from "../../redux/actions/publicAction";
import AutoCompleteSearch from "./components/AutoCompleteSearch";
import DateRangePicker from "../../components/datepicker/DateRangePicker";
import MultiSelectCheckbox from "../../components/dropdown/MultiSelectCheckBox";
import ButtonIcon from "../../components/buttons/ButtonIcon";
import { FaSearchMinus } from "react-icons/fa";
import Button from "../../components/buttons/Button";
import { Pie } from "./graphs/Pie";
import SegmentedControl from "../../components/SegmentedControl";
import Skeleton from "@mui/material/Skeleton";
import ReactTable from "../../components/tables/ReactTable";
import {
  aggregates,
  columns,
  hiddenColumns,
  monthsColumns,
  receivedColumns,
  receivedSubColumns,
  received_hiddenColumns,
  subColumns,
  excelCellsConfig,
  suppliersColumns,
  priceComapreColumns,
  ItemTooltip,
  categoriesColumns,
} from "./columns";
import { NETWORK_MARKUP } from "../../const/privilegesButtons";
// import ViewOrder from "../orders/manageOrders/ViewOrder";
import PageNav from "../../components/PageNav";
import { COLOR_ABORT } from "../../const/colors";
import ViewReceivedOrder from "./components/ViewReceivedOrder";
import ViewOrder from "./components/ViewOrder";
import { default as Grid } from "@mui/material/Unstable_Grid2";
import { getNetworkSuppliers } from "../../redux/actions/networkAction";
import { setDialogModal } from "../../redux/actions/DialogAction";
import { isEmpty, isNumber } from "lodash";
import { useScreenSize } from "../../Hooks";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Button as MuiButton,
  TextField,
  IconButton,
} from "@mui/material";
import SimpleTable from "../orders/manageOrders/receiveOrder/SimpleTable";
import { AiOutlineBranches } from "react-icons/ai";
import { PriceCell } from "../../components/tables/components/PriceFooter";
import ClearIcon from "@mui/icons-material/Clear";
import Axios from "axios";
import { startLoading, stopLoading } from "../../redux/actions/loaderAction";
import { setError } from "../../redux/actions/errorAction";
import moment from "moment/moment";
import { HEBREW_DAYS } from "../../const";
import { VAT } from "../orders/manageOrders/constants";
import { fetchCategories } from "../../redux/actions/ItemAction";

export const SELECTED_PAGE_NOT_ORDERED = 0;
export const SELECTED_PAGE_RECEIVED = 1;
export const SELECTED_PAGE_NOT_RECEIVED = 2;

export const SELECTED_TAB_ITEMS = 0;
export const SELECTED_TAB_COMAPRE_MONTHS = 3;
export const SELECTED_TAB_SUPPLIERS = 2;
export const SELECTED_TAB_COMPARE_PRICE = 1;
export const SELECTED_TAB_CATEGORIES = 4;

const ORDER_ITEMS_URI = "v2/items/orderedItems";
const INCOMES_URI = "income/comparison";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let cancelToken;

const OrdersAnalysis = ({
  name,
  title,
  setActive,
  setIsFrame,
  setTitle,
  branches,
  show_network_privilege_columns,
  suppliers,
  getNetworkSuppliers,
  setDialogModal,
  categories,
  fetchCategories,
}) => {
  // ======== HOOKS ========

  const { isMobile, isTablet, isLaptop, isDesktop } = useScreenSize();
  const dispatch = useDispatch();

  // ======== STATES ========

  const [selectedDates, setSelectedDates] = useState(() => {
    const startDate = startOfMonth(addMonths(new Date(), -1));
    const endDate = endOfMonth(addMonths(new Date(), -1));
    return {
      startDate,
      endDate,
      key: "selection",
      label: `חודש שעבר`,
    };
  });
  const [viewToShow, setViewToShow] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [data, setData] = useState([]);
  const [incomes, setIncomes] = useState({
    incomesWithVat: 0,
    incomesWithoutVat: 0,
  });
  const [selectedPage, setSelectedPage] = useState(SELECTED_PAGE_RECEIVED);
  const [checkedBranches, setCheckedBranches] = useState([]);
  const [checkedSuppliers, setCheckedSuppliers] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [selectedTab, setSelectedTab] = useState({
    index: 0,
    name: "מוצרים",
  });

  const [isUnorderedPage, setIsUnorderedPage] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [unorderedBranches, setUnorderedBranches] = useState([]);

  // ======== CONSTANTS ========

  const gridSpacing = useMemo(
    () => (isMobile || isTablet ? 1 : 2),
    [isMobile, isTablet]
  );

  const options = useMemo(
    () => [
      {
        text: "מוצרים שלא הוזמנו",
        onClick: () => setData([]),
      },
      {
        text: "הזמנות שנקלטו",
        onClick: () => setData([]),
      },
      {
        text: "הזמנות שנשלחו",
        onClick: () => setData([]),
      },
    ],
    []
  );

  const tabOptions = useMemo(
    () => [
      {
        index: SELECTED_TAB_ITEMS,
        name: "מוצרים",
      },
      {
        index: SELECTED_TAB_CATEGORIES,
        name: "קטגוריות",
      },
      {
        index: SELECTED_TAB_SUPPLIERS,
        name: "ספקים",
      },
      {
        index: SELECTED_TAB_COMPARE_PRICE,
        name: "השוואת מחירים",
      },
      {
        index: SELECTED_TAB_COMAPRE_MONTHS,
        name: "השוואת חודשים",
      },
    ],
    []
  );

  // ======== EFFECTS ========

  // const { categoriesNew } = useSelector(({ goods }) => {
  //   const categoriesNew = goods.categories.data.master;

  //   return { categoriesNew };
  // });

  useEffect(() => {
    setActive(name);
    setTitle(title);
    setIsFrame(false);
    getNetworkSuppliers();
    fetchCategories();
  }, []);

  useEffect(() => {
    setIsUnorderedPage(selectedPage === SELECTED_PAGE_NOT_ORDERED);
  }, [selectedPage]);

  // ======== HANDLERS ========

  const handlePageSelect = (page) => {
    setSelectedPage(page);
    setIsUnorderedPage(page === SELECTED_PAGE_NOT_ORDERED);
  };

  const [isOrdersQuerying, ordersApi] = useDateFetcher(
    ORDER_ITEMS_URI,
    selectedDates,
    setData
  );

  const [isIncomesQuerying, incomesApi] = useDateFetcher(
    INCOMES_URI,
    selectedDates,
    setIncomes
  );

  const isQuerying = isOrdersQuerying || isIncomesQuerying;

  const fetchData = async () => {
    const systemCategories = [];

    checkedCategories.forEach((checkedCategory) => {
      const category = categories.find(
        (category) => category.id === checkedCategory
      );
      if (category.id !== category.const_category_id) {
        systemCategories.push(category.const_category_id);
      }
    });

    const ordersParams = {
      selected_page: selectedPage,
      fixDate: true,
      branches_ids: checkedBranches.length
        ? checkedBranches
        : branches.map((branch) => branch.id),
      suppliers: checkedSuppliers,
      categories: [...checkedCategories, ...systemCategories],
      items_ids: selectedItems.length ? selectedItems.map(({ id }) => id) : -99,
    };

    if (selectedPage === SELECTED_PAGE_NOT_ORDERED) {
      ordersApi.getSingleDateData(ordersParams);
      return;
    }

    // queries splitted becaused the server timesout on a large data request
    dispatch(startLoading("טוען נתונים מהשרת, עלול לקחת עד דקה..."));
    await ordersApi.getDataSplittedByBranch(
      ordersParams,
      (responses) => {
        const sumBranchesData = [];
        const isReceivedPage = selectedPage === SELECTED_PAGE_RECEIVED;
        responses.forEach(({ data }) => {
          Object.values(data).forEach((item) => {
            const isItemExists = sumBranchesData.find(
              (existingItem) => existingItem.item_id === item.item_id
            );
            if (isItemExists) {
              isItemExists.amount +=
                item[isReceivedPage ? "item_amount" : "amount"];
              isItemExists.subRows = [...isItemExists.subRows, ...item.subRows];
            } else {
              sumBranchesData.push(item);
            }
          });
        });

        //calculating average and totals
        return sumBranchesData.map((item) => {
          let avg_price = 0,
            total_deposit = 0;

          if (isReceivedPage) {
            let total_received_without_vat = 0,
              total_ordered_without_vat = 0,
              total_received_with_vat = 0,
              total_ordered_with_vat = 0;

            let received_amount = 0;

            item.subRows.forEach((subRow) => {
              avg_price += subRow.received_price;
              total_received_without_vat += subRow.total_received_without_vat;
              total_ordered_without_vat += subRow.total_ordered_without_vat;
              total_received_with_vat += subRow.total_received_with_vat;
              total_ordered_with_vat += subRow.total_ordered_with_vat;
              total_deposit += subRow.total_deposit;
              received_amount += subRow.received_amount;
            });

            return {
              ...item,
              avg_price: avg_price / item.subRows.length,
              total_received_with_vat,
              total_ordered_with_vat,
              total_received_without_vat,
              total_ordered_without_vat,
              total_deposit,
              received_amount,
            };
          } else {
            let total_with_vat = 0,
              total_without_vat = 0;

            item.subRows.forEach((subRow) => {
              avg_price += subRow.order_price;
              total_with_vat += subRow.total_with_vat;
              total_without_vat += subRow.total_without_vat;
              total_deposit += subRow.total_deposit;
            });

            return {
              ...item,
              avg_price: avg_price / item.subRows.length,
              total_with_vat,
              total_without_vat,
              total_deposit,
            };
          }
        });
      },
      "branches_ids"
    );

    const incomesParams = {
      branchesIds: checkedBranches.length
        ? checkedBranches
        : branches.map((branch) => branch.id),
    };
    await incomesApi.getDataSplittedByBranch(
      incomesParams,
      (responses) => {
        const sumIncomes = {
          incomesWithVat: 0,
          incomesWithoutVat: 0,
        };

        responses.forEach(({ data }) => {
          const { incomes, incomeExtras } = data;

          const totalIncomes = incomes.reduce(
            (acum, curr) => {
              return {
                withVat: acum.withVat + curr.total.amount,
                withoutVat: acum.withoutVat + curr.total.amount / VAT,
              };
            },
            { withVat: 0, withoutVat: 0 }
          );

          const totalExtraIncomes = incomeExtras.reduce(
            (acum, curr) => {
              return {
                withVat: acum.withVat + curr.total_with_vat,
                withoutVat: acum.withoutVat + curr.total_without_vat,
              };
            },
            { withVat: 0, withoutVat: 0 }
          );

          sumIncomes.incomesWithVat +=
            totalIncomes.withVat + totalExtraIncomes.withVat;
          sumIncomes.incomesWithoutVat +=
            totalIncomes.withoutVat + totalExtraIncomes.withoutVat;
        });

        return sumIncomes;
      },
      "branchesIds",
      3
    );
    dispatch(stopLoading());
  };

  const pieData = useMemo(() => {
    if (isUnorderedPage) return { items: [] };

    let newData = {};
    var total = 0;
    if (selectedPage === SELECTED_PAGE_NOT_RECEIVED) {
      total = data.reduce(
        (result, curr) => curr.total_without_vat + curr.total_deposit + result,
        0
      );
    } else {
      total = data.reduce(
        (result, curr) =>
          curr.total_received_without_vat + curr.total_deposit + result,
        0
      );
    }

    data.forEach((element) => {
      const { item_id } = element;
      if (!newData[item_id]) {
        if (selectedPage === SELECTED_PAGE_NOT_RECEIVED) {
          const {
            name,
            amount,
            total_without_vat,
            deposit,
            total_deposit,
            avg_price,
          } = element;
          newData = {
            ...newData,
            [item_id]: {
              label: decodeHtml(name),
              value: amount,
              price: total_without_vat,
              percent: (total_without_vat * 100) / total,
              id: item_id,
              total_without_vat: total_without_vat + total_deposit,
              avg_price: avg_price + deposit,
            },
          };
        } else {
          const { name, received_amount, total_received_without_vat } = element;
          newData = {
            ...newData,
            [item_id]: {
              label: decodeHtml(name),
              value: received_amount,
              price: total_received_without_vat,
              percent: (total_received_without_vat * 100) / total,
              id: item_id,
            },
          };
        }
      } else {
        const newDataElem = newData[item_id];
        let value = 0,
          price = 0;
        if (selectedPage === SELECTED_PAGE_NOT_RECEIVED) {
          value = element.amount + newDataElem.value;
          price = element.total_without_vat + newDataElem.price;
        } else {
          value = element.received_amount + newDataElem.value;
          price = element.total_received_without_vat + newDataElem.price;
        }
        newData = {
          ...newData,
          [item_id]: {
            ...newDataElem,
            value,
            price,
            percent: (price * 100) / total,
          },
        };
      }
    });

    return { items: Object.values(newData), total };
  }, [data, selectedPage]);

  const { arrangedData, dataBySupplier, dataByCategory } = useMemo(() => {
    if (isUnorderedPage) return {};

    const arrangedData = [],
      dataByCategory = [],
      dataBySupplier = {};
    let totalOrderedWithoutVatSum = 0,
      totalReceivedWithoutVatSum = 0;

    data.forEach((row) => {
      let {
        item_id,
        catalog_number,
        scale,
        is_weighted,
        item_amount,
        item_package_items,
        name,
        box_type,
        category_id,
        supplier_name,
        description,
        network_price,
        by_scale,
        received_amount,
        avg_price,
        total_network_price,
        amount, // ordered
        deposit,
        total_deposit,
        total_received_without_vat,
        total_ordered_without_vat,
        total_without_vat, // ordered
        payment_condition,
        //discount
      } = row;

      if (!network_price) {
        network_price = 0;
      }

      if (!total_network_price) {
        total_network_price = 0;
      }

      const category_name =
        categories.find(
          (category) =>
            category.id === category_id ||
            category.const_category_id === category_id
        )?.name || "כללי";

      avg_price += deposit;

      let per_unit_price;
      let per_unit_amount =
        (received_amount || amount) * item_package_items * item_amount;

      if (scale === "גרם" || scale === 'מ"ל') {
        per_unit_amount /= 1000;
        scale = scale === "גרם" ? 'ק"ג' : "ליטר";
        per_unit_price =
          (avg_price / (item_package_items * item_amount)) * 1000;
      } else {
        per_unit_price = avg_price / (item_package_items * item_amount);
      }

      totalOrderedWithoutVatSum +=
        total_ordered_without_vat || total_without_vat || 0;
      totalReceivedWithoutVatSum += total_received_without_vat || 0;

      arrangedData.push({
        ...row,
        name,
        network_markup_recieved: received_amount * (avg_price - network_price),
        network_markup_not_recieved: amount * (avg_price - total_network_price),
        //discount: discount ? Number(discount).toFixed(0) : 0,
        category_name,
        total_received_without_vat: total_received_without_vat + total_deposit,
        total_ordered_without_vat: total_ordered_without_vat + total_deposit,
        avg_price,
        per_unit_amount,
        per_unit_price,
        scale,
        subRows: (() => {
          const subRows = row.subRows.map((subRow) => {
            const newSubRow = {
              ...subRow,
              total_received_without_vat:
                subRow.total_received_without_vat + subRow.total_deposit,
              total_ordered_without_vat:
                subRow.total_ordered_without_vat + subRow.total_deposit,
              received_price: subRow.received_price + deposit,
              ordered_price: subRow.ordered_price + deposit,
              order_price: subRow.order_price + deposit,
              total_without_vat:
                subRow.total_without_vat + subRow.total_deposit,
              item_id,
              week_day:
                HEBREW_DAYS[
                  moment(
                    subRow.sent_at || subRow.document_date,
                    "YYYY-MM-DD"
                  ).day()
                ],
              category_name,
              catalog_number,
              scale,
              by_scale,
              is_weighted,
              item_amount,
              item_package_items,
              name,
              box_type,
              category_id,
              supplier_name,
              description,
              avg_price,
              network_price,
              color:
                subRow.order_type === 2 || subRow.price_gap
                  ? COLOR_ABORT
                  : "inherit",
              sent_at: subRow.sent_at ? fixDate(subRow.sent_at) : "",
              due_date: subRow.due_date ? fixDate(subRow.due_date) : "",
            };

            return newSubRow;
          });

          if (selectedPage === SELECTED_PAGE_NOT_RECEIVED) {
            return subRows.filter((x) => x.order_type !== 2);
          }
          return subRows;
        })(),
      });

      const supplierId = row.supplier_id;
      const firstCategoryName = getSafe(
        () => JSON.parse(row.supplier_categories)[0],
        ["כללי"]
      );

      if (!(supplierId in dataBySupplier)) {
        dataBySupplier[supplierId] = {
          id: supplierId,
          supplier_name: row.supplier_name,
          payment_condition,
          category: firstCategoryName,
          total_without_vat: 0, //ordered
          total_with_vat: 0, // ordered
          total_received_without_vat: 0,
          total_received_with_vat: 0,
          total_price_gap: 0,
          total_received_refunds: 0,
        };
      }
      const supplierRef = dataBySupplier[supplierId];
      supplierRef.total_without_vat += row.total_without_vat;
      supplierRef.total_with_vat += row.total_with_vat;
      supplierRef.total_received_without_vat += row.total_received_without_vat;
      supplierRef.total_received_with_vat += row.total_received_with_vat;
      supplierRef.total_price_gap += row.total_price_gap;
      supplierRef.total_received_refunds += row.total_received_refunds;

      //bulding data by categories
      let category = dataByCategory.find(
        (cat) => cat.name === firstCategoryName
      );
      if (!category) {
        category = {
          name: firstCategoryName,
          total_without_vat: 0, //ordered
          total_with_vat: 0, // ordered
          total_received_without_vat: 0,
          total_received_with_vat: 0,
        };
        dataByCategory.push(category);
      }

      category.total_without_vat += row.total_without_vat;
      category.total_with_vat += row.total_with_vat;
      category.total_received_without_vat += row.total_received_without_vat;
      category.total_received_with_vat += row.total_received_with_vat;
    });

    const { incomesWithoutVat } = incomes;

    //percentage calculations for items, suppliers and categories tabs
    for (const item of arrangedData) {
      item.ordered_percentage_of_income =
        getSafeDivision(item.total_without_vat, incomesWithoutVat) * 100;

      item.received_percentage_of_income =
        getSafeDivision(item.total_received_without_vat, incomesWithoutVat) *
        100;
    }

    for (const supplier in dataBySupplier) {
      const supplierRef = dataBySupplier[supplier];

      supplierRef.total_without_vat_percentage =
        getSafeDivision(
          supplierRef.total_without_vat,
          totalOrderedWithoutVatSum
        ) * 100;

      supplierRef.total_received_without_vat_percentage =
        getSafeDivision(
          supplierRef.total_received_without_vat,
          totalReceivedWithoutVatSum
        ) * 100;

      supplierRef.received_percentage_of_income =
        getSafeDivision(
          supplierRef.total_received_without_vat,
          incomesWithoutVat
        ) * 100;

      supplierRef.ordered_percentage_of_income =
        getSafeDivision(supplierRef.total_without_vat, incomesWithoutVat) * 100;
    }

    for (const categoryData of dataByCategory) {
      categoryData.received_percentage_of_income =
        getSafeDivision(
          categoryData.total_received_without_vat,
          incomesWithoutVat
        ) * 100;

      categoryData.ordered_percentage_of_income =
        getSafeDivision(categoryData.total_without_vat, incomesWithoutVat) *
        100;
    }

    return {
      arrangedData,
      dataBySupplier: Object.values(dataBySupplier),
      dataByCategory,
    };
  }, [data, selectedPage, incomes]);

  const { monthData, priceData, months } = useMemo(() => {
    if (isUnorderedPage) return { monthData: [], priceData: [], months: [] };

    if (!data.length)
      return {
        monthData: {},
        priceData: {},
        months: [],
      };

    const rawData = data
      .map((row) => {
        let {
          item_id,
          category_id,
          catalog_number,
          scale,
          is_weighted,
          item_amount,
          item_package_items,
          name,
          box_type,
          supplier_name,
          deposit,
          current_price,
          catalog_price,
          description,
          current_price_with_vat,
          received_price,
          network_price,
          network_markup,
          by_scale,
          avg_price,
          //discount,
        } = row;

        const category_name =
          categories.find(
            (category) =>
              category.id === category_id ||
              category.const_category_id === category_id
          )?.name || "כללי";

        return row.subRows.map((subRow) => ({
          ...subRow,
          item_id,
          category_name,
          catalog_number,
          catalog_price,
          //discount: discount ? Number(discount).toFixed(0) : 0,
          scale,
          is_weighted,
          item_amount,
          item_package_items,
          name,
          box_type,
          supplier_name,
          current_price,
          description,
          deposit,
          current_price_with_vat,
          received_price,
          network_price,
          network_markup,
          by_scale,
          avg_price,
        }));
      })
      .flat();

    const priceData = {};
    const monthData = {};
    rawData.forEach((item) => {
      const itemId = item.item_id;
      const month = item.month;
      const rawDate = item.document_date ? item.document_date : item.sent_at;
      const date = fixDate(rawDate);

      const monthObj = {};
      const priceObj = {};

      let itemName = item.name;

      if (isNumber(+item.catalog_number) && +item.catalog_number !== 0) {
        itemName = item.name + " - " + item.catalog_number;
      }

      // const priceAfterDiscount = getPriceAfterDiscount(
      //   item.catalog_price,
      //   item.discount
      // );

      // Compare prices table
      if (!(itemId in priceData)) {
        priceData[itemId] = {
          name: itemName,
          category_name: item.category_name,
          catalog_number: item.catalog_number,
          description: item.description,
          supplier_name: item.supplier_name,
          deposit: item.deposit,
          avg_price: item.avg_price,
          months: {},
        };
      }

      if (!(month in priceData[itemId].months)) {
        priceData[itemId].months[month] = [];
      }
      // Compare months table
      if (!(itemId in monthData)) {
        monthData[itemId] = {
          name: itemName,
          category_name: item.category_name,
          catalog_number: item.catalog_number,
          description: item.description,
          supplier_name: item.supplier_name,
          catalog_price: item.catalog_price,
          current_price: item.current_price,
          deposit: item.deposit,
          month: item.month,
          year: item.year,
          months: {},
        };
      }

      if (!(month in monthData[itemId].months)) {
        monthData[itemId].months[month] = [];
      }

      // Price comapare
      priceObj.catalog_price = item.catalog_price;
      priceObj.received_price = item.avg_price;
      priceObj.network_price = item.network_price;
      priceObj.order_price = item.order_price;
      //priceObj.network_markup = priceAfterDiscount - item.network_price;
      priceObj.network_markup = item.avg_price - item.network_price;
      // priceObj.discount = item.discount;
      // priceObj.received_price = priceAfterDiscount;
      // Price comapre - dialog
      priceObj.date = date;
      priceObj.file_number = item.file_number;
      priceObj.amount = item.received_amount || item.amount;

      priceObj.branch_name = item.branch_name;
      priceObj.isRefund = !!item.received_refunds;

      // Months Compare
      monthObj.by_scale = item.by_scale;
      monthObj.box_type = item.box_type;
      monthObj.is_weighted = item.is_weighted;
      monthObj.scale = item.scale;
      monthObj.amount_for_avg = item.amount_for_avg;
      monthObj.avg_price = item.avg_price;

      // Months comapre - dialog
      monthObj.order_price = item.order_price;
      //monthObj.received_price = priceAfterDiscount;
      monthObj.received_price = item.avg_price;
      monthObj.date = date;
      monthObj.file_number = item.file_number;
      monthObj.amount = item.received_amount || item.amount;

      monthObj.branch_name = item.branch_name;
      monthObj.isRefund = !!item.received_refunds;

      priceData[itemId].months[month].push(priceObj);
      monthData[itemId].months[month].push(monthObj);
    });

    // Build columns per month
    const months = {};
    rawData.forEach((item) => {
      months[item.month] = item.month;
    });

    return {
      monthData: Object.values(monthData),
      priceData: Object.values(priceData),
      months: Object.values(months),
    };
  }, [data, selectedPage]);

  const memo_month_columns = useMemo(
    () => monthsColumns(months, selectedPage),
    [months, selectedPage]
  );

  const memo_comapre_price_columns = useMemo(
    () => priceComapreColumns(months, selectedPage),
    [months, selectedPage]
  );

  // ======== UI ========

  /*
    xs, extra-small: 0px
    sm, small: 500px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1536px
  */

  const RenderTable = () => {
    const { index } = selectedTab;

    const isItem = index === SELECTED_TAB_ITEMS;
    const isPrice = index === SELECTED_TAB_COMPARE_PRICE;
    const isSupplier = index === SELECTED_TAB_SUPPLIERS;
    const isCategories = index === SELECTED_TAB_CATEGORIES;
    const isMonth = index === SELECTED_TAB_COMAPRE_MONTHS;

    const isReceived = selectedPage === SELECTED_PAGE_RECEIVED;
    const isNotReceived = selectedPage === SELECTED_PAGE_NOT_RECEIVED;

    const styles = {
      wrapper: { maxHeight: "calc(100vh - 10rem)" },
      container: { maxHeight: "calc(100vh - 23.4rem)" },
    };

    const translation_endpoint = "itemsTable";

    let title, columnsRend, data, excelRowConfig;

    const excelColConfig =
      isReceived || isNotReceived
        ? (defaultColumns) => {
            const excelCols = [...defaultColumns];
            excelCols.splice(2, 0, { Header: "מק״ט", id: "catalog_number" });
            return excelCols;
          }
        : null;

    const extendedExcelColConfig =
      isReceived || isNotReceived
        ? (defaultColumns) => {
            const excelCols = [...defaultColumns];
            excelCols.splice(1, 0, {
              Header: "מק״ט",
              accessor: "catalog_number",
              id: "catalog_number",
            });
            excelCols.splice(6, 0, {
              Header: "יום בשבוע",
              accessor: "week_day",
              id: "week_day",
            });
            return excelCols;
          }
        : null;

    if (isItem) {
      title = `מוצרים ש${isNotReceived ? "נשלחו" : "נקלטו"}`;
      columnsRend = isNotReceived
        ? columns(show_network_privilege_columns)
        : receivedColumns(show_network_privilege_columns);
      data = arrangedData;
      excelRowConfig = null;
    }

    if (isPrice) {
      title = "השוואת מחירים";
      columnsRend = memo_comapre_price_columns;
      data = priceData;
      excelRowConfig = (row) => {
        if (row.months) {
          const months = { ...row.months };
          Object.entries(months).forEach(([monthKey, monthData]) => {
            const lastInMonth = monthData[monthData.length - 1];

            if (isReceived) {
              row[`month_${monthKey}_received_price`] =
                monthData.reduce(
                  (n, { received_price }) => n + received_price,
                  0
                ) / monthData.length;
            }

            if (isNotReceived) {
              row[`month_${monthKey}_received_price`] =
                monthData.reduce((n, { order_price }) => n + order_price, 0) /
                monthData.length;
            }

            row[`month_${monthKey}_network_price`] = lastInMonth.network_price;
          });
        }
        return row;
      };
    }

    if (isSupplier) {
      title = `ספקים ש${isNotReceived ? "נשלחו" : "נקלטו"}`;
      columnsRend = suppliersColumns(selectedPage);
      data = dataBySupplier;
      excelRowConfig = (row) => {
        const newExcelRow = { ...row };

        //fix each number column which has more than 2 digits after decimal point
        for (const column in newExcelRow) {
          if (
            typeof newExcelRow[column] === "number" &&
            newExcelRow[column].toString().split(".")[1]?.length > 2
          )
            newExcelRow[column] = newExcelRow[column].toFixed(2);
        }

        return newExcelRow;
      };
    }

    if (isCategories) {
      title = `קטגוריות ש${isNotReceived ? "נשלחו" : "נקלטו"}`;
      columnsRend = categoriesColumns(selectedPage);
      data = dataByCategory;
      excelRowConfig = null;
    }

    if (isMonth) {
      title = "השוואת חודשים";
      columnsRend = memo_month_columns;
      data = monthData;
      excelRowConfig = (row) => {
        if (row.months) {
          const months = { ...row.months };
          Object.entries(months).forEach(([monthKey, monthData]) => {
            if (isReceived) {
              row[`month_${monthKey}_avg_price`] =
                monthData.reduce(
                  (n, { received_price }) => n + received_price,
                  0
                ) / monthData.length;
            }

            if (isNotReceived) {
              row[`month_${monthKey}_avg_price`] =
                monthData.reduce((n, { order_price }) => n + order_price, 0) /
                monthData.length;
            }

            row[`month_${monthKey}_amount_for_avg`] = monthData.reduce(
              (n, { amount_for_avg }) => n + amount_for_avg,
              0
            );
          });
        }
        return row;
      };
    }

    let subColumnsRend = isReceived
      ? receivedSubColumns(show_network_privilege_columns)
      : subColumns(show_network_privilege_columns);

    return (
      <ReactTable
        {...{
          styles,
          ...((isSupplier || isItem) && {
            subCellsPropsById: (id) => {
              if (["new_order_id", "document_number"].includes(id)) {
                return {
                  setViewToShow,
                };
              }
            },
          }),
          cellsPropsById: (id) => {
            return {
              setDialogModal,
            };
          },
          footersPropsById: (id) => incomes,
          translation_endpoint,
          title,

          columns: columnsRend,

          ...(isItem && {
            subColumns: subColumnsRend,
            hiddenColumns: isReceived ? received_hiddenColumns : hiddenColumns,
            isExportedToExcelSub: true,
          }),
          data,
          isExportedToExcel: true,
          loading: isQuerying,
          isPaginate: true,
          showFooter: isSupplier || isItem || isCategories,
          showCheckbox: false,
          sortBy: [{ id: "name" }],
          aggregates,
          cellsConfig: excelCellsConfig,
          overflow: true,
          excelRowConfig,
          excelColConfig,
          extendedExcelColConfig,
          hideSubTableColumnsOptionsButton: true,
          hideSubTableColumns: true,
          serverSaveTitle: isReceived
            ? "OrderAnalysisReceived"
            : "OrderAnalysisOrdered",
        }}
      />
    );
  };

  const gridWrapper = (child, props = { xs: 6 }) => (
    <Grid
      container
      spacing={gridSpacing}
      style={{ marginTop: 10, marginBottom: 10 }}
    >
      <Grid {...props}> {child}</Grid>
    </Grid>
  );

  const renderTabs = gridWrapper(
    <SegmentedControl
      options={options}
      setSelected={(page) => handlePageSelect(page)}
      selected={selectedPage}
    />
  );

  const renderFilters = (
    <Grid
      container
      spacing={gridSpacing}
      style={{ marginTop: 10, marginBottom: 10 }}
      className="flex-column-center"
    >
      <Grid xs={2}>
        <Dropdown text={selectedDates.label}>
          <DateRangePicker
            selectedRange={selectedDates}
            setSelectedRange={setSelectedDates}
          />
        </Dropdown>
      </Grid>

      {!!branches.length && (
        <Grid xs={2}>
          <MultiSelectCheckbox
            title="סניפים"
            checked={checkedBranches}
            setChecked={setCheckedBranches}
            options={branches.map((branch) => ({
              label: branch.name,
              value: branch.id,
            }))}
          />
        </Grid>
      )}

      {!!suppliers.length && (
        <Grid xs={2}>
          <MultiSelectCheckbox
            title="ספקים"
            checked={checkedSuppliers}
            setChecked={setCheckedSuppliers}
            options={suppliers.map((supplier) => ({
              label: supplier.name,
              value: supplier.id,
            }))}
          />
        </Grid>
      )}

      {!!suppliers.length && (
        <Grid xs={2}>
          <MultiSelectCheckbox
            title="קטגוריות"
            checked={checkedCategories}
            setChecked={setCheckedCategories}
            options={categories
              .filter(({ enable }) => enable)
              .map((category) => ({
                label: category.name,
                value: category.id,
              }))}
          />
        </Grid>
      )}

      <Grid xs={2} className="flex-start">
        <ButtonIcon
          icon={FaSearchMinus}
          className="btn--normal"
          onClick={() => {
            setCheckedBranches([]);
            setCheckedSuppliers([]);
          }}
          alt="איפוס סינון"
        />
        <Button
          onClick={fetchData}
          style={{ height: "4rem" }}
          className="btn--submit"
          textValue={isQuerying ? "טוען..." : "אישור"}
          disabled={isQuerying}
        />
      </Grid>
      <Grid xs={4} className="flex-column-center">
        <AutoCompleteSearch {...{ setSelectedItems }} />
      </Grid>
    </Grid>
  );

  // Moved to renderFilters function. Still here in case will need to separate
  const renderFreeSearch = gridWrapper(
    <AutoCompleteSearch {...{ setSelectedItems }} />,
    { xs: 12, lg: 3, className: "margin-top" }
  );

  const renderPie = () => {
    const renderPieData = (props) => (
      <Grid xs={6} className="u-center-text">
        {isQuerying ? (
          <div style={{ padding: "5.5rem 0" }} className="flex-center">
            <Skeleton
              variant="circular"
              width="calc(32vw - 150px)"
              height="calc(32vw - 150px)"
            />
          </div>
        ) : (
          <Pie {...props} />
        )}
      </Grid>
    );

    const amountData = {
      isPrice: false,
      title: "המוצרים הנרכשים ביותר (יח')",
      data: pieData.items.sort((a, b) => b.value - a.value).slice(0, 5),
    };

    const worthData = {
      isPrice: true,
      title: "המוצרים הנרכשים ביותר (ש״ח)",
      total: pieData.total,
      data: pieData.items
        .sort((a, b) => b.price - a.price)
        .slice(0, 5)
        .map((x) => ({
          ...x,
          value: Number(x.price).toFixed(1),
        })),
    };

    return (
      <Grid
        container
        spacing={gridSpacing}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        {renderPieData(amountData)}
        {renderPieData(worthData)}
      </Grid>
    );
  };

  const RenderDialog = () => {
    const [serachValue, setSerachValue] = useState("");
    const [branchesData, setBranchesData] = useState(unorderedBranches);

    useEffect(() => {
      if (serachValue)
        setBranchesData(
          unorderedBranches.filter(
            (row) => row.id === +serachValue || row.name === serachValue
          )
        );
    }, [serachValue]);

    const handleClearClick = () => {
      setBranchesData(unorderedBranches);
      setSerachValue("");
    };

    const handleClose = () => {
      setOpenModal(false);
      setUnorderedBranches([]);
      setSerachValue("");
    };

    return (
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="xs"
        onClose={() => setOpenModal(false)}
      >
        <DialogTitle>רשימת הסניפים שלא שלחו הזמנה</DialogTitle>
        <DialogContent>
          <div className="margin-top">
            <TextField
              fullWidth
              label="חיפוש לפי שם או מספר סניף"
              id="fullWidth"
              value={serachValue}
              onChange={(e) => setSerachValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: serachValue ? "visible" : "hidden" }}
                    onClick={handleClearClick}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              sx={{
                "& .Mui-focused .MuiIconButton-root": { color: "primary.main" },
              }}
            />
          </div>

          <SimpleTable
            columns={[
              {
                title: "מספר",
                name: "id",
                render: (data, row) => {
                  return data;
                },
              },
              {
                title: "שם",
                name: "name",
                render: (data, row) => {
                  return data;
                },
              },
            ]}
            data={branchesData}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton
            variant="contained"
            color="secondary"
            size="large"
            onClick={handleClose}
          >
            סגור
          </MuiButton>
        </DialogActions>
      </Dialog>
    );
  };

  const RenderUnorderedItemsTable = () => {
    const token = useSelector((state) => state.auth.token);

    const styles = {
      wrapper: { maxHeight: "calc(100vh - 10rem)" },
      container: { maxHeight: "calc(100vh - 23.4rem)" },
    };

    const columns = [
      {
        Header: "שם",
        accessor: "name",
        filter: "fuzzyText",
      },
      {
        Header: "צורת מארז",
        accessor: "description",
        filter: "fuzzyText",
      },
      {
        Header: "מק״ט",
        accessor: "part_number",
        filter: "fuzzyText",
        Cell: ({ row }) => {
          const { part_number, manufacturer_number } = row.original;
          return part_number ?? manufacturer_number;
        },
      },
      {
        Header: "מחיר",
        accessor: "price",
        filter: "fuzzyText",
        Cell: PriceCell,
      },
      {
        Header: "קטגורייה",
        accessor: "category_name",
        filter: "fuzzyText",
      },
      {
        Header: "ספק",
        accessor: "supplier_name",
        filter: "fuzzyText",
      },

      {
        Header: "תאריך שליחת הזמנה אחרון",
        accessor: "lastSentDate",
        filter: "fuzzyText",
        Cell: ({ row }) => {
          const { last_order } = row.original;
          return (
            last_order ?? (
              <Typography component="div" variant="body2">
                <Box sx={{ color: "text.disabled" }}>
                  לא הוזמן בטווח התאריכים הנבחר
                </Box>
              </Typography>
            )
          );
        },
      },
      {
        Header: "פעילות",
        accessor: "actions",
        Cell: (props) => {
          const { handleClick } = props;
          const row = props.row;

          return (
            <ButtonIcon
              icon={AiOutlineBranches}
              className="btn--normal"
              onClick={() => handleClick(row.original)}
              alt='סניפים בסטטוס "פעיל" שלא שלחו את ההזמנה'
            />
          );
        },
      },
    ];

    const handleClick = (row) => {
      fetchLastOrder(row.id);
    };

    const fetchLastOrder = async (id) => {
      if (cancelToken) cancelToken.cancel("the request canceled");

      cancelToken = Axios.CancelToken.source();
      const ctoken = cancelToken.token;

      dispatch(startLoading("טוען..."));

      try {
        const { data } = await Axios.get(`v2/items/lastOrderItem/${id}`, {
          params: {
            startDate: selectedDates.startDate,
            endDate: selectedDates.endDate,
          },
          headers: { Authorization: `Bearer ${token}` },
          cancelToken: ctoken,
        });

        setUnorderedBranches(data);

        setOpenModal(true);
      } catch (err) {
        console.error(err);

        if (!Axios.isCancel(err)) {
          dispatch(setError(err?.response?.data?.message, "ארעה שגיאה בשרת"));
        }
      }
      dispatch(stopLoading());
    };

    return (
      <>
        <RenderDialog />
        <ReactTable
          cellsPropsById={(id) => {
            return {
              handleClick,
            };
          }}
          translation_endpoint="notOrderedItemsTable"
          {...{
            styles,
            title,
            columns,
            data,
            isExportedToExcel: true,
            loading: isQuerying,
            isPaginate: true,
            sortBy: [{ id: "name" }],
          }}
        />
      </>
    );
  };

  const renderTableSection = () => {
    /*
          When moving from items tab to other tabs 
          and subrow in items tab is expanded
          the browser will crush.
          Manually close the subrows (if expended) before moving on
          to other tabs.
        */

    const onClickHandler = (tab) => {
      const subRowButtons = document.querySelectorAll(".subRowButton");
      subRowButtons.forEach((subRowButton) => subRowButton.click());

      const isItem = selectedTab.index === SELECTED_TAB_ITEMS;
      const isSubRowOpen = !!subRowButtons.length;
      setTimeout(() => setSelectedTab(tab), isItem && isSubRowOpen ? 1000 : 0);
    };

    return (
      <>
        {isUnorderedPage ? (
          <RenderUnorderedItemsTable />
        ) : (
          <>
            <PageNav
              isMobile={false}
              options={tabOptions}
              onClick={onClickHandler}
            />
            <RenderTable />
          </>
        )}
      </>
    );
  };

  const renderModals = (
    <>
      <ViewReceivedOrder
        {...{
          new_received_order_id: viewToShow
            ? viewToShow.new_received_order_id
            : null,
          onClose: () => setViewToShow(null),
        }}
      />
      <ViewOrder
        {...{
          order_id: viewToShow ? viewToShow.new_order_id : null,
          onClose: () => setViewToShow(null),
        }}
      />
    </>
  );

  return (
    <div style={{ minHeight: "calc(100vh - 140px)" }} className="orders">
      {renderModals}

      <h1>ניתוח הזמנות</h1>

      {renderTabs}

      {renderFilters}

      {/* {renderFreeSearch} */}

      {!isUnorderedPage && renderPie()}
      {/* {renderPie()} */}

      {!!data.length && renderTableSection()}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { branches } = state.branches;
  const { privilege_id, privileges_access } = state.user_privileges;
  const show_network_privilege_columns =
    privilege_id === 1 && privileges_access.includes(NETWORK_MARKUP);
  const { suppliers } = state.network;
  const categories = state.goods.categories.data.master.data;

  return {
    branches,
    show_network_privilege_columns,
    suppliers,
    categories,
  };
};

export default connect(mapStateToProps, {
  setActive,
  setIsFrame,
  setTitle,
  getNetworkSuppliers,
  setDialogModal,
  fetchCategories,
})(OrdersAnalysis);
