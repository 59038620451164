import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  useTable,
  useSortBy,
  useRowSelect,
  usePagination,
  useExpanded,
  useAsyncDebounce,
} from "react-table";
import { connect } from "react-redux";
import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import Skeleton from "react-loading-skeleton";
import SearchBar from "../inputs/SearchBar";
import { cloneDeep, isEmpty, isFunction, mapKeys } from "lodash";
import TablePaginationActions from "./TablePaginationActions";
import SingleExcelFile from "./excel/SingleExcelFile";
import HideColumnsOptions from "./HideColumnsOptions";
import TableSearch from "./components/TableSearch";
import Axios from "axios";
import { logout } from "../../redux/actions/loginAction";
import { setError } from "../../redux/actions/errorAction";
import "./helpers";
import { arrangeData } from "./helpers";
import BtnTooltip from "../tooltips/BtnTooltip";
import { COLOR_GRAY_DARK, COLOR_GRAY_LIGHT } from "../../const/colors";
import { FaTrash } from "react-icons/fa";
import { log } from "../../helpers";
import SegmentedControl from "../../components/SegmentedControl";
import { AiOutlinePlus } from "react-icons/ai";
import ButtonIcon from "../buttons/ButtonIcon";

function DefaultColumnFilter({ column: { setFilter } }) {
  return (
    <SearchBar
      style={{ marginRight: ".5rem" }}
      fixedInputWidth={true}
      isCollapse={true}
      handleSearchChange={(value) => {
        setFilter(value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <label
        style={{ width: "fit-content", border: "none" }}
        className="dropdown-select--checkbox"
      >
        <input type="checkbox" ref={resolvedRef} {...rest} />
        <span className="dropdown-select--checkmark"></span>
      </label>
    );
  }
);

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  collapsedSearch,
  data,
  autoSearchOnDataChange,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  useEffect(() => {
    if (autoSearchOnDataChange) {
      setGlobalFilter(value);
    }
  }, [data]);

  return (
    <TableSearch
      isCollapse={collapsedSearch}
      handleSearchChange={(value) => {
        setValue(value || undefined); // Set undefined to remove the filter entirely
        onChange(value);
      }}
    />
  );
}

const multiSelect = (rows, id, filterValue) => {
  return rows.filter((row) => filterValue.includes(row.values[id]));
};

// Let the table remove the filter if the string is empty
multiSelect.autoRemove = (val) => !val.length;

const ReactTable = ({
  highLightId = null,
  highLight = false,
  branch_id,
  token,
  translation_endpoint = "",
  columns,
  title = "",
  titleDatesRange = "",
  titleFunction = false,
  isExportedToExcel = false,
  isExportedToExcelSub = false,
  data = [],
  additionalData = [],
  sortBy = [],
  loading = false,
  showFooter = false,
  showCheckbox = false,
  serverSaveTitle = null,
  footerColSpan,
  getCheckedRows = null,
  stickyHeader = true,
  styles = {},
  subColumns = [],
  dubSubColumns = [],
  SelectionFooter,
  SelectionHeader,
  footerColor = false,
  isPaginate = false,
  overflow = true,
  cellsConfig,
  footerConfig,
  customFooterTitle,
  hiddenColumns = [],
  aggregates = [],
  defaultVisibleColumns = [],
  headersPropsById = () => ({}),
  footersPropsById = () => ({}),
  cellsPropsById = () => ({}),
  subCellsPropsById = () => ({}),
  subDubCellsPropsById = () => ({}),
  subRowStyle = {},
  costumeValuePreparation,
  newValuePreparation,
  moreButtons = null,
  collapsedSearch = true,
  routineColors = false,
  showSearch = true,
  showFilters,
  endRowButtons = [],
  rowAction = () => {},
  setModalHiddenColumns = () => {},
  autoSearchOnDataChange = false,
  // Example  (id) => {
  //   if (["email", "name", "phone"].includes(id)) {
  //     return {
  //       onChange: (value, key) =>
  //         setContacts((prev) => ({
  //           ...prev,
  //           [key]: { ...prev[key], [id]: value },
  //         })),
  //     };
  //   }
  // }
  showHeader = true,
  //excelRowConfig=null,
  excelRowConfig = (val) => {
    Object.keys(val).forEach((inval) => {
      const excludeArray = [
        "date",
        "catalog_number",
        "document_number",
        "ordered_amount",
        "received_amount",
        "ordered_price",
        "received_price",
        "amount",
        "price",
        "total_ordered_without_vat",
        "total_received_without_vat",
        "total_sent_without_vat",
        "markup_sent",
        "markup_received",
        "income_precent_sent",
        "income_precent_received",
        "items_count",
        "total_sent_with_vat",
        "total_received_with_vat",
        "total_sent_with_vat",
        "total",
        "price_gap",
        "count_open_orders",
        "total_invoice_count",
        "total_without_vat",
        "catalog_number",
        "part_number",
        "current_count",
        "open_amounts",
        "consumed",
        "total_with_vat",
        "total_paid_and_refunds_with_vat",
        "vat",
        "open_refunds_orders_total",
        "income_percent",
      ];
      if (!isNaN(val[inval]) && !excludeArray.includes(inval)) {
        const intFixFloat = parseFloat(val[inval]).toFixed(2);
        val[inval] =
          isNaN(intFixFloat) || !isFinite(intFixFloat) ? 0 : intFixFloat;
      }
    });
    return val;
  },
  excelColConfig = null,
  extendedExcelColConfig = null,
  initialRowsPerPage = 50,
  alignToCenter = false,
  hideColumnsOptionsButton = false, // hides the button without losing functionality
  hideSubTableColumnsOptionsButton = false,
  hideSubTableColumns = false,
}) => {
  const [activeRow, setActiveRow] = useState(null);
  const [savedColumns, setSavedColumns] = useState({});

  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
      multiSelect: multiSelect,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const fetchBranchViewOptions = useMemo(
    () => async (branch_id, serverSaveTitle) => {
      try {
        if (!branch_id || !serverSaveTitle) return;
        const res = await Axios.get(`branch/${branch_id}/reportsOptions`, {
          params: {
            report_title: serverSaveTitle,
          },
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = mapKeys(
          res.data.filter((x) => x.active),
          "column_title"
        );

        if (res.data.length === 0) {
          setSavedColumns(mapKeys(defaultVisibleColumns));
        } else {
          setSavedColumns(data);
        }
      } catch (e) {
        console.error(e);

        if (e.response.status === 401) {
          logout();
        } else if (e.response.status === 500) {
          setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת");
        }
      }
    },
    [token]
  );

  useEffect(() => {
    const ac = new AbortController();
    if (branch_id && serverSaveTitle) {
      fetchBranchViewOptions(branch_id, serverSaveTitle);
    } else if (defaultVisibleColumns.length) {
      setSavedColumns(mapKeys(defaultVisibleColumns));
    }
    return () => ac.abort();
  }, [
    branch_id,
    serverSaveTitle,
    defaultVisibleColumns.length,
    fetchBranchViewOptions,
  ]);

  const arrangedData = useMemo(
    () =>
      arrangeData(
        cloneDeep(data),
        costumeValuePreparation,
        newValuePreparation
      ),
    [data]
  );

  const {
    getTableBodyProps,
    getTableProps,
    footerGroups,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    page,
    gotoPage,
    setPageSize,
    allColumns,
    visibleColumns,
    toggleHideColumn,
    setHiddenColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useTable(
    {
      defaultColumn,
      filterTypes,
      columns,
      data: arrangedData,
      autoResetPage: false,
      initialState: {
        sortBy,
        pageSize: initialRowsPerPage,
      },
      paginateExpandedRows: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (showCheckbox) {
        return hooks.visibleColumns.push((columns) => {
          return [
            // Let's make a column for selection
            {
              id: "selection",
              style: { width: "1px", textAlign: "right" },
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              ),
              Footer: SelectionFooter,
            },
            ...columns,
          ];
        });
      }
    }
  );

  useEffect(() => {
    const ac = new AbortController();
    if (hiddenColumns.length) {
      setHiddenColumns(hiddenColumns.filter((x) => !savedColumns[x]));
    }
    return () => ac.abort();
  }, [savedColumns, hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    const ac = new AbortController();
    if (isFunction(getCheckedRows)) {
      const checkedData = selectedFlatRows.map((d) => d.original);
      getCheckedRows(checkedData);
    }
    return () => ac.abort();
  }, [getCheckedRows, selectedFlatRows]);

  useEffect(() => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollLeft = 1000000;
      }
    }, 10);
  }, []);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  const containerRef = useRef();

  return loading ? (
    Array(9)
      .fill()
      .map((temp, index) => (
        <Skeleton
          key={index}
          height={50}
          style={{ borderRadius: "1rem", marginBottom: "1rem" }}
        />
      ))
  ) : (
    <div
      style={{
        ...(styles.wrapper
          ? {
              ...styles.wrapper,
              borderLeft: "1px solid rgba(224, 224, 224, 1)",
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }
          : {
              borderLeft: "1px solid rgba(224, 224, 224, 1)",
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }),
        boxShadow: "0px 5px 16px 0px rgba(0, 0, 0, 0.2)",
        marginTop: "1rem",
      }}
    >
      {title || isExportedToExcel || showSearch || hiddenColumns.length ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: !hideColumnsOptionsButton && "16px",
            borderTop: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          {titleFunction ? (
            <div>
              <h2 style={{ fontSize: "1.8rem" }}>
                {titleFunction.title + " לטווח תאריכים " + titleDatesRange}
              </h2>
              <SegmentedControl
                setSelected={titleFunction.func}
                options={[
                  { text: title.split("/")[0] },
                  { text: title.split("/")[1] },
                ]}
                selected={titleFunction.params}
              />
            </div>
          ) : (
            <h2
              style={
                styles.title || { fontSize: "1.8rem", whiteSpace: "nowrap" }
              }
            >
              {title}
            </h2>
          )}

          <div className="flex-end fit-content">
            {showCheckbox && SelectionHeader ? (
              SelectionHeader({ selectedFlatRows })
            ) : (
              <></>
            )}
            {showSearch && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                collapsedSearch={collapsedSearch}
                data={data}
                autoSearchOnDataChange={autoSearchOnDataChange}
              />
            )}

            {isExportedToExcelSub ? (
              <SingleExcelFile
                data={rows
                  .map((row) =>
                    row.subRows.map((subRow) => ({ ...subRow.original })).flat()
                  )
                  .flat()}
                fileName={`סיכום מורחב ${title}`}
                columns={subColumns.map((x) => ({ ...x, id: x.accessor }))}
                is_sub={true}
                {...{
                  footerConfig,
                  aggregates,
                  cellsConfig,
                  customFooterTitle,
                  translation_endpoint,
                  colConfig: extendedExcelColConfig,
                }}
              />
            ) : (
              <></>
            )}

            {isExportedToExcel ? (
              <SingleExcelFile
                data={rows.map((row) => ({ ...row.original }))}
                fileName={`סיכום ${title}`}
                columns={visibleColumns ? visibleColumns : []}
                {...{
                  footerConfig,
                  aggregates,
                  customFooterTitle,
                  cellsConfig,
                  translation_endpoint,
                  rowConfig: excelRowConfig,
                  colConfig: excelColConfig,
                }}
              />
            ) : (
              <></>
            )}
            {hiddenColumns.length && !hideColumnsOptionsButton ? (
              <HideColumnsOptions
                {...{
                  allColumns,
                  hiddenColumns,
                  containerRef,
                  serverSaveTitle,
                  toggleHideColumn,
                  defaultVisibleColumns: savedColumns,
                  translation_endpoint,
                  setModalHiddenColumns,
                }}
              />
            ) : (
              <></>
            )}
            {moreButtons}
          </div>
        </div>
      ) : (
        <></>
      )}
      <TableContainer
        ref={containerRef}
        style={{ direction: "ltr", ...styles.container }}
        component={Paper}
      >
        <Table
          {...{ stickyHeader }}
          aria-label={"sticky table"}
          {...getTableProps()}
          style={{ direction: "rtl" }}
          sx={{ ...styles.table }}
        >
          {showHeader && (
            <TableHead className={overflow ? "nowrap" : ""}>
              {headerGroups.map((headerGroup, index, self) => {
                const last = index === self.length - 1;
                return (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      if (column.hide) {
                        return <></>;
                      }
                      return (
                        <TableCell
                          align={alignToCenter ? "center" : "inherit"}
                          {...([
                            "action",
                            "title_0",
                            "title_1",
                            "selection",
                            "selection_placeholder_0",
                            "expander",
                          ].includes(column.id)
                            ? { ...column.getHeaderProps() }
                            : {
                                ...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                ),
                              })}
                          style={{
                            ...(column.hide ? { display: "none" } : {}),
                            ...column.style,
                            ...styles.header,
                            /*,top:column.id==="title_0"?0:"85px"*/
                          }}
                        >
                          <div
                            className={
                              ["selection"].includes(column.id)
                                ? ""
                                : alignToCenter
                                ? "flex-center"
                                : "flex-start"
                            }
                          >
                            <div style={{ margin: "0 5px" }}>
                              {[
                                "action",
                                "title_0",
                                "title_1",
                                "selection",
                                "selection_placeholder_0",
                                "expander",
                              ].includes(column.id) ||
                              column.noSort ||
                              !last ? (
                                <></>
                              ) : column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-sort-down" />
                                ) : (
                                  <i className="fa fa-sort-up" />
                                )
                              ) : (
                                <i className="fa fa-sort" />
                              )}
                            </div>
                            {column.render("Header", {
                              ...headersPropsById(column.id),
                            })}
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            ></div>
                          </div>

                          {column.Filter && showFilters
                            ? column.render("Filter")
                            : ""}
                        </TableCell>
                      );
                    })}
                    {endRowButtons?.length ? (
                      <TableCell
                        align={alignToCenter ? "center" : "inherit"}
                        style={{
                          ...styles.header,
                          maxWidth: 0,
                        }}
                      >
                        <div className={"flex-center"}>
                          <div style={{ margin: "0 5px" }}></div>
                        </div>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableHead>
          )}
          <TableBody {...getTableBodyProps()}>
            {arrangedData.length ? (
              (isPaginate ? page : rows)
                .filter((x) => x.depth === 0)
                .map((row, i) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.id}>
                      <TableRow
                        {...{
                          ...row.getRowProps(),
                          // className: overflow
                          //   ? "nowrap highlight-background "
                          //   : "",
                          className: overflow
                            ? `nowrap ${
                                highLightId == row.id &&
                                highLight &&
                                "highlight-background"
                              }`
                            : "",
                          style: routineColors
                            ? i & 1
                              ? {
                                  background: COLOR_GRAY_LIGHT,
                                }
                              : {}
                            : row.depth
                            ? {
                                background: "rgba(34, 183, 247, 0.2)",
                                color: row.original.color,
                              }
                            : row.isExpanded
                            ? {
                                background: "#00b3ff42",
                                color: row.original.color,
                              }
                            : { color: row.original.color },
                          selected: activeRow === i,
                          onClick: () => {
                            setActiveRow(i);
                            rowAction(row);
                          },
                        }}
                      >
                        {row.cells.map((cell, index, self) => {
                          return (
                            <TableCell
                              align={alignToCenter ? "center" : "inherit"}
                              style={{
                                ...cell.column.style,
                                ...(cell.column.hide
                                  ? { display: "none" }
                                  : {}),
                                ...(cell.column.noWrap
                                  ? { whiteSpace: "nowrap" }
                                  : {}),
                                ...(row.isExpanded
                                  ? {
                                      position: stickyHeader ? "sticky" : null,
                                      top: "56px",
                                      zIndex: 2,
                                      background: "#585858",
                                      color: "white",
                                    }
                                  : {}),
                                color:
                                  cell.column.Header === "הפרש"
                                    ? cell.value > 1
                                      ? "green"
                                      : cell.value < -1
                                      ? "red"
                                      : ""
                                    : "",
                              }}
                              {...cell.getCellProps()}
                            >
                              {}
                              {cell.column.isLong ? (
                                <BtnTooltip
                                  placement="right"
                                  maxWidth={"50vw"}
                                  text={cell.value}
                                >
                                  <div
                                    style={{ maxWidth: "15rem" }}
                                    className="limit-text-three-dots"
                                  >
                                    {cell.render("Cell", {
                                      ...cellsPropsById(cell.column.id),
                                    })}
                                  </div>
                                </BtnTooltip>
                              ) : (
                                cell.render("Cell", {
                                  ...cellsPropsById(cell.column.id),
                                })
                              )}
                            </TableCell>
                          );
                        })}
                        {endRowButtons?.length ? (
                          <TableCell
                            align={alignToCenter ? "center" : "inherit"}
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveRow(i);
                            }}
                          >
                            <div
                              className="flex"
                              style={{
                                justifyContent: "space-evenly",
                              }}
                            >
                              {endRowButtons?.map((button, index) => {
                                return (
                                  <Tooltip
                                    title={button.tooltip ?? ""}
                                    key={index}
                                    {...{
                                      ...(!button.tooltip
                                        ? { open: false }
                                        : null),
                                    }}
                                  >
                                    <IconButton
                                      className={
                                        button.showAlways ? "" : "showOnOver"
                                      }
                                      onClick={() => {
                                        button.action(row);
                                      }}
                                      size="medium"
                                    >
                                      {button.icon}
                                    </IconButton>
                                  </Tooltip>
                                );
                              })}
                            </div>
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align={alignToCenter ? "center" : "inherit"}
                          style={
                            row.isExpanded
                              ? {
                                  position: stickyHeader ? "sticky" : null,
                                  top: "110px",
                                  padding: 0,
                                  zIndex: 2,
                                }
                              : { padding: 0 }
                          }
                          colSpan={row.cells.length}
                        >
                          <Collapse
                            in={row.isExpanded}
                            timeout="auto"
                            unmountOnExit
                            style={{ backgroundColor: "white" }}
                          >
                            <ReactTable
                              alignToCenter
                              {...{
                                cellsPropsById: subCellsPropsById,
                                subCellsPropsById: subDubCellsPropsById,
                                stickyHeader: true,
                                data: row.original.subRows,
                                additionalData: additionalData,
                                hiddenColumns: hideSubTableColumns
                                  ? hiddenColumns
                                  : [],
                                hideColumnsOptionsButton:
                                  hideSubTableColumnsOptionsButton,
                                columns: subColumns,
                                showCheckbox: false,
                                subColumns: dubSubColumns,
                                showSearch: false,
                                sortBy: sortBy,
                                styles: {
                                  container: {
                                    width: "100%",
                                    maxHeight: "280px",
                                  },
                                  header: { background: "#EAEAEA" },
                                  ...subRowStyle,
                                },
                              }}
                            />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={100}>
                  <strong style={{ fontSize: "1.8rem", color: "inherit" }}>
                    לא נמצאו רשומות
                  </strong>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {showFooter ? (
            <TableFooter>
              {footerGroups
                .filter(
                  (footerGroup) =>
                    footerGroup.headers[0].originalId !== "title" &&
                    !footerGroup.headers[0].noFooterRow
                )
                .map((group) => {
                  if (group.headers[0].originalId !== "selection_placeholder") {
                    return (
                      <TableRow {...group.getFooterGroupProps()}>
                        {group.headers.map((column) => (
                          <TableCell
                            align={alignToCenter ? "center" : "inherit"}
                            style={{
                              ...(column.hide ? { display: "none" } : {}),
                              background: footerColor ? footerColor : "",
                            }}
                            colSpan={footerColSpan}
                            {...column.getFooterProps()}
                          >
                            {column.Footer
                              ? column.render("Footer", {
                                  ...footersPropsById(column.id),
                                  selectedFlatRows,
                                })
                              : ""}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  }
                  return null;
                })}
            </TableFooter>
          ) : (
            <></>
          )}
        </Table>
      </TableContainer>
      {isPaginate ? (
        <table className="fluid">
          <tbody className="fluid">
            <TableRow>
              <TablePagination
                className="fluid flex-end"
                style={{
                  border: "none",
                }}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} מתוך ${
                    count !== -1 ? count : `יותר מ- ${to}`
                  }`;
                }}
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  50,
                  100,
                  { label: "הכל", value: data.length },
                ]}
                labelRowsPerPage="מס' שורות בעמוד"
                colSpan={5}
                count={arrangedData.length}
                rowsPerPage={state.pageSize}
                page={state.pageIndex}
                SelectProps={{
                  inputProps: { "aria-label": "שורות בעמוד" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { token } = state.auth;
  const { id } = state.branches.selected_branch;

  return { token, branch_id: id };
};

export default connect(mapStateToProps, { logout, setError })(ReactTable);
