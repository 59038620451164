import { IconButton, Tooltip, Typography, Menu, MenuItem } from "@mui/material";
import { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { AiFillBell, AiOutlineCloudDownload } from "react-icons/ai";
import { BsClockHistory } from "react-icons/bs";
import {
  FaPencilAlt,
  FaPlus,
  FaStarOfDavid,
  FaUserTimes,
} from "react-icons/fa";
import ButtonIcon from "../../../../components/buttons/ButtonIcon";
import { sumFooter } from "../../../../components/tables/helpers";
import BtnTooltip from "../../../../components/tooltips/BtnTooltip";
import { COLOR_RESTIGO_PRIMARY, COLOR_SUBMIT } from "../../../../const/colors";
import {
  fixDate,
  fixHour,
  getHebDay,
  getShortHebDay,
  thousandSeparator,
  twoZeroAfterDot,
} from "../../../../helpers";
import { TYPE_SPECIAL_EVENT } from "../../../admin/holidays/HolidayView";
import { HOLIDAYS_ICONS } from "../../../schedule/manager/table/Header";
import { mergeEventRows } from "../tableColumns";
import { MdNoteAdd } from "react-icons/md";

export const event_hidden = [
  "enable",
  "employee_name",
  "role_name",
  "employee_number",
  "on_account",
  "identifier",
  "employee_accountancy_number",
  "hebrew_create_date",
  "hours_100",
  "hours_125",
  "hours_150",
  "hours_150_shabat",
  "hours_150_without_night",
  "hours_175",
  "hours_200",
  "break",
  "hours_total_minus_break",
  "id",
  "shift_id",
  "role_id",
  "notes",
  "deleted",
  "permanent",
  "department_id",
  "standby",
  "manual_start",
  "manual_end",
  "clock_start",
  "clock_end",
  "planned_start",
  "planned_end",
  "trim_start",
  "trim_end",
  "total_incentive_hours",
  "cost",
  "base_salary",
  "extra_shabat_salary",
  // "total_tip_calc",
  "bonus_note",
  "hours_night",
  "bonus_amount",
  "social_hours",
  "employee_number",
  "vacation",
  "vacation_for_paid",
  "sick",
  "sick_for_paid",
  "reserve",
  "hours_in_holiday",
  "hours_in_holiday_hours_150_shabat",
  "is_padded",
  "irregular_hours",
  "no_offset_hours",
  "submission_request",
  "employee_type",
];

export const event_aggregates = [
  { field: "hours_100", aggregate: "sum" },
  { field: "hours_125", aggregate: "sum" },
  { field: "hours_150", aggregate: "sum" },
  { field: "hours_150_shabat", aggregate: "sum" },
  { field: "hours_in_holiday", aggregate: "sum" },
  { field: "hours_175", aggregate: "sum" },
  { field: "hours_200", aggregate: "sum" },
  { field: "social_hours", aggregate: "sum" },
  { field: "hours_total", aggregate: "sum" },
  { field: "break", aggregate: "sum" },
  { field: "cost", aggregate: "sum" },
  // { field: "total_tip_calc", aggregate: "sum" },
  { field: "bonus_amount", aggregate: "sum" },
  { field: "on_account", aggregate: "sum" },
  { field: "total_incentive_hours", aggregate: "sum" },
  { field: "vacation", aggregate: "sum" },
  { field: "vacation_for_paid", aggregate: "sum" },
  { field: "sick", aggregate: "sum" },
  { field: "reserve", aggregate: "sum" },
  { field: "working_date", aggregate: "count" },
  { field: "hours_in_holiday_hours_150_shabat", aggregate: "sum" },
  { field: "hours_150_without_night", aggregate: "sum" },
  { field: "irregular_hours", aggregate: "sum" },
  { field: "no_offset_hours", aggregate: "sum" },
  { field: "hours_total_minus_break", aggregate: "sum" },
];

const SUBTRAHEND_FROM_IRREGULAR_HOURS = 10;

export const event_columns = [
  {
    Header: ({
      title,
      isManager,
      blocked_from_date,
      isAllowedToEdit,
      setAddAssignEmployee,
      cant_edit_self_id,
      rows,
    }) => {
      const disabled = !isManager || !isAllowedToEdit;
      return disabled ? (
        <h3>{title}</h3>
      ) : (
        <div className="flex-between">
          <h3>{title}</h3>
          <ButtonIcon
            style={{
              width: "3rem",
              height: "3rem",

              color: "#00a2ea",
              lineHeight: "0.8",
            }}
            alt="הוסף משמרת"
            icon={FaPlus}
            onClick={() => {
              let employee_id = "";
              rows.forEach((eachRow) => {
                if (eachRow.original.employee_id) {
                  employee_id = eachRow.original.employee_id;
                }
              });
              setAddAssignEmployee({
                name: rows[0].original["employee_name"],
                id: employee_id,
              });
            }}
          />
        </div>
      );
    },
    id: "title",
    Footer: "",
    columns: [
      {
        Header: "סטטוס",
        accessor: "enable",
        Cell: ({ value, row }) => {
          return value === undefined ? (
            ""
          ) : value === 1 ? (
            <span style={{ color: "green" }}>פעיל</span>
          ) : (
            <span style={{ color: "red" }}>לא פעיל</span>
          );
        },
      },
      {
        Header: "שם עובד",
        accessor: "employee_name",
        Footer: 'סה"כ',
      },
      {
        Header: "ת.ז / דרכון",
        accessor: "identifier",
      },
      {
        Header: "מ. עובד",
        accessor: "employee_number",
      },
      {
        Header: "מ. עובד מ. שכר",
        accessor: "employee_accountancy_number",
      },
      {
        Header: "תאריך הקמה",
        accessor: "hebrew_create_date",
        Cell: ({ row }) => {
          const { hebrew_create_date } = row.original;

          return (
            <span>
              {hebrew_create_date ? `${fixDate(hebrew_create_date)}` : ""}
            </span>
          );
        },
      },
      {
        Header: "תאריך",
        accessor: "working_date",
        Cell: ({ row, holidays }) => {
          if (row.original.is_week_summary) {
            return <span style={{ fontWeight: "bold" }}>סיכום שבועי</span>;
          }
          const { working_date, holiday, hours_total } = row.original;

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {`${fixDate(working_date)}`}

                {row.original?.logs?.length > 0 ? (
                  <div>
                    <ButtonIcon
                      style={{
                        width: "unset",
                        height: "unset",
                        color: COLOR_RESTIGO_PRIMARY,
                        backgroundColor: "transparent",
                        lineHeight: "0.8",
                      }}
                      alt="הסטוריית עדכונים"
                      icon={BsClockHistory}
                      iconSize="0.75em"
                    />
                  </div>
                ) : (
                  <></>
                )}

                {holiday && holiday.type != TYPE_SPECIAL_EVENT ? (
                  <BtnTooltip text={holiday.name}>
                    {HOLIDAYS_ICONS[holiday.type]()}
                  </BtnTooltip>
                ) : holidays?.some(
                    (holiday) =>
                      holiday.start_date <= working_date &&
                      holiday.end_date >= working_date &&
                      holiday.type != TYPE_SPECIAL_EVENT
                  ) ? (
                  <BtnTooltip
                    text={
                      holidays?.find(
                        (holiday) =>
                          holiday.start_date <= working_date &&
                          holiday.end_date >= working_date &&
                          holiday.type !== TYPE_SPECIAL_EVENT
                      ).name
                    }
                  >
                    {HOLIDAYS_ICONS[
                      holidays?.find(
                        (holiday) =>
                          holiday.start_date <= working_date &&
                          holiday.end_date >= working_date &&
                          holiday.type !== TYPE_SPECIAL_EVENT
                      ).type
                    ]()}
                  </BtnTooltip>
                ) : (
                  ""
                )}
              </span>
              {hours_total > 12 && (
                <Tooltip
                  sx={{
                    fontSize: "0.8rem",
                    maxWidth: "20rem",
                  }}
                  title={
                    <Typography style={{ color: "#ffffff" }}>
                      עבד/ה מעל 12 שעות
                    </Typography>
                  }
                >
                  <IconButton sx={{ fontSize: "1.6rem" }}>
                    <AiFillBell />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          );
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              [
                ...new Set(
                  self.rows
                    .filter(
                      (row) =>
                        !row.values.vacation &&
                        !row.values.sick &&
                        !row.values.reserve &&
                        !row.original.is_week_summary &&
                        !row.original.is_padded
                    )
                    .map((row) => row.values.working_date)
                ),
              ].length,
            [self.rows]
          );

          return <>{`סה"כ ${total} ימים`}</>;
        },
      },
      {
        Header: "יום",
        accessor: "week_day",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return <></>;
          }
          return getHebDay(props.row.original.week_day);
        },
      },
      {
        Header: "מחלקה",
        accessor: "employee_type",
        Cell: (props) => {
          console.log("props", props.row.original);
          return <> {props.row.original.employee_type}</>;
        },
      },
      {
        Header: "תפקיד",
        accessor: "role_name",
        Cell: (props) => {
          if (
            props.row.original.is_week_summary ||
            props.row.original.is_padded
          ) {
            return <></>;
          }
          return props.row.original.role_name
            ? props.row.original.role_name
            : "לא שובץ";
        },
      },
      // accessor: "is_padded" -> is needed for filter padded days in excel,
      {
        Header: "",
        accessor: "is_padded",
        Cell: (props) => <></>,
        disable_export_excel: true,
      },

      {
        Header: "שעת התחלה",
        accessor: "actual_start",
        Cell: (props) => `${fixHour(props.row.original.actual_start)}`,
      },
      {
        Header: "שעת סיום",
        accessor: "actual_end",
        Cell: (props) => `${fixHour(props.row.original.actual_end)}`,
      },
      {
        Header: "התחלה - תכנון",
        accessor: "planned_start",
        Cell: (props) => `${fixHour(props.row.original.planned_start)}`,
      },
      {
        Header: "סיום - תכנון",
        accessor: "planned_end",
        Cell: (props) => `${fixHour(props.row.original.planned_end)}`,
      },
      {
        Header: "התחלה - מנהל",
        accessor: "manual_start",
        Cell: (props) => `${fixHour(props.row.original.manual_start)}`,
      },
      {
        Header: "סיום - מנהל",
        accessor: "manual_end",
        Cell: (props) => `${fixHour(props.row.original.manual_end)}`,
      },
      {
        Header: "התחלה - שעון",
        accessor: "clock_start",
        Cell: (props) => `${fixHour(props.row.original.clock_start)}`,
      },
      {
        Header: "סיום - שעון",
        accessor: "clock_end",
        Cell: (props) => `${fixHour(props.row.original.clock_end)}`,
      },
      {
        Header: "התחלה - חיתוך",
        accessor: "trim_start",
        Cell: (props) => `${fixHour(props.row.original.trim_start)}`,
      },
      {
        Header: "סיום - חיתוך",
        accessor: "trim_end",
        Cell: (props) => `${fixHour(props.row.original.trim_end)}`,
      },
      {
        Header: "הערות",
        accessor: "notes",
        Cell: ({ value, row }) => {
          const style = { fontWeight: "bold" };
          return (
            <div>
              {value && (
                <p>
                  <span style={style}>הערות משמרת : </span>
                  {value}
                </p>
              )}
              {row.original.daily_notes && (
                <p>
                  <span style={style}>הערות יומיות : </span>
                  {row.original.daily_notes}
                </p>
              )}
              {row.original.employee_notes && (
                <p>
                  <span style={style}>הערות מכרטיס עובד : </span>
                  {row.original.employee_notes}
                </p>
              )}
            </div>
          );
        },
      },
      {
        Header: "100%",
        accessor: "hours_100",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_100")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_100");
        },

        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_100 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "125%",
        accessor: "hours_125",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_125")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_125");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_125 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "150%",
        accessor: "hours_150",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_150")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_150");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_150 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "שעות 150 ללא לילה",
        accessor: "hours_150_without_night",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_150_without_night")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_150_without_night");
        },

        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce(
                  (sum, row) => row.values.hours_150_without_night + sum,
                  0
                ),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "שעות שבת",
        accessor: "hours_150_shabat",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_150_shabat")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_150_shabat");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_150_shabat + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "שעות חג",
        accessor: "hours_in_holiday",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_in_holiday")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_in_holiday");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_in_holiday + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "שעות שבת + חג",
        accessor: "hours_in_holiday_hours_150_shabat",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_in_holiday_hours_150_shabat")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_in_holiday_hours_150_shabat");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce(
                  (sum, row) =>
                    row.values.hours_in_holiday_hours_150_shabat + sum,
                  0
                ),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "175%",
        accessor: "hours_175",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_175")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_175");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_175 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "200%",
        accessor: "hours_200",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_200")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_200");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_200 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip text={'סה"כ שעות 100% + שעות 100% בשבת'}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            שעות סוציאליות
          </>
        ),
        accessor: "social_hours",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "social_hours")}
              </span>
            );
          }
          return mergeEventRows(props, "social_hours");
        },
        width: 1,
        headerName: "שעות סוציאליות",
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.social_hours + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: 'תמריץ אמצ"ש',
        accessor: "total_incentive_hours",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "total_incentive_hours")}
              </span>
            );
          }
          return mergeEventRows(props, "total_incentive_hours");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce(
                  (sum, row) => row.values.total_incentive_hours + sum,
                  0
                ),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: 'סה"כ שעות',
        accessor: "hours_total",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_total")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_total");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_total + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip
              text={'הפרש בין סה"כ שעות עבודה בפועל ברמת כל יום לבין שעות תקן'}
            >
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            ש.חוסר תקן
          </>
        ),
        accessor: "irregular_hours",
        Cell: (props) => {
          if (props.row.original.irregular_hours) {
            return (
              <span style={{ unicodeBidi: "plaintext" }}>
                {twoZeroAfterDot(props.row.original.irregular_hours)}
              </span>
            );
          }
        },
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.irregular_hours + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip text={"ש.חוסר תקן פחות 10"}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            ש.חוסר לקיזוז
          </>
        ),
        accessor: "no_offset_hours",
        Cell: (props) => {
          if (props.row.original.no_offset_hours) {
            return (
              <span>{twoZeroAfterDot(props.row.original.no_offset_hours)}</span>
            );
          }
        },
        Footer: (self) => {
          let total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.irregular_hours + sum, 0),
            [self.rows]
          );

          total = total - SUBTRAHEND_FROM_IRREGULAR_HOURS;
          return <>{total > 0 ? twoZeroAfterDot(total) : null}</>;
        },
      },
      {
        Header: "הפסקות",
        accessor: "break",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "break")}
              </span>
            );
          }
          return mergeEventRows(props, "break");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.break + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip text={"רלוונטי לסניפים שההפסקה הינה בתשלום"}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            סה״כ שעות בניכוי הפסקות
          </>
        ),
        accessor: "hours_total_minus_break",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_total_minus_break")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_total_minus_break");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce(
                  (sum, row) => row.values.hours_total_minus_break + sum,
                  0
                ),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      // {
      //   Header: "חישוב טיפים",
      //   accessor: "total_tip_calc",
      //   Cell: (props) => {
      //     const { row } = props;
      //     if (!row.original.total_tip_calc) {
      //       return "";
      //     }
      //     return (
      //       <div className="money">
      //         {" "}
      //         <span>&#8362;</span>
      //         {thousandSeparator(
      //           twoZeroAfterDot(props.row.original.total_tip_calc)
      //         )}
      //       </div>
      //     );
      //   },
      //   width: 1,
      //   Footer: (self) => {
      //     const total = useMemo(
      //       () =>
      //         self.rows.reduce(
      //           (sum, row) => row.values.total_tip_calc + sum,
      //           0
      //         ),
      //       [self.rows]
      //     );

      //     return (
      //       <div className="money money__footer">
      //         <span>&#8362;</span>
      //         {twoZeroAfterDot(total)}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "בונוס",
        accessor: "bonus_amount",
        Cell: (props) => {
          const { row } = props;
          if (!row.original.bonus_amount) {
            return "";
          }
          return (
            <div className="money">
              {" "}
              <span>&#8362;</span>
              {thousandSeparator(
                twoZeroAfterDot(props.row.original.bonus_amount)
              )}
            </div>
          );
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => {
                  let bonus = row.values.bonus_amount
                    ? Number(row.values.bonus_amount)
                    : 0;
                  return bonus + sum;
                }, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "מפרעה",
        accessor: "on_account",
        Cell: (props) => {
          const { row } = props;
          if (!row.original.on_account) {
            return "";
          }
          return (
            <div className="money">
              {" "}
              <span>&#8362;</span>
              {thousandSeparator(
                twoZeroAfterDot(props.row.original.on_account)
              )}
            </div>
          );
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => {
                  let bonus = row.values.on_account
                    ? Number(row.values.on_account)
                    : 0;
                  return bonus + sum;
                }, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "הערות בונוס/מפרעה",
        accessor: "bonus_note",
      },
      {
        Header: "שעות לילה",
        accessor: "hours_night",
        Cell: ({ value }) => {
          return twoZeroAfterDot(value);
        },
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_night + sum, 0),
            [self.rows]
          );

          return twoZeroAfterDot(total);
        },
      },
      {
        Header: "ימי חופש",
        accessor: "vacation",
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "vacation"),
            [self.rows]
          );
          return total;
        },
      },
      {
        Header: "ימי חופשה לקיזוז",
        accessor: "vacation_for_paid",
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "vacation_for_paid"),
            [self.rows]
          );
          return total;
        },
      },
      {
        Header: "ימי מחלה",
        accessor: "sick",
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "sick"),
            [self.rows]
          );
          return total;
        },
      },
      {
        Header: "ימי מחלה לתשלום",
        accessor: "sick_for_paid",
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "sick_for_paid"),
            [self.rows]
          );
          return total;
        },
      },
      {
        Header: "ימי מילואים",
        accessor: "reserve",
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "reserve"),
            [self.rows]
          );
          return total;
        },
      },
      {
        Header: "עלות",
        accessor: "cost",
        Cell: (props) => {
          const { row } = props;
          if (!row.original.cost) {
            return "";
          }
          if (props.row.original.is_week_summary) {
            return (
              <div className="money" style={{ fontWeight: "bold" }}>
                {" "}
                <span>&#8362;</span>
                {thousandSeparator(twoZeroAfterDot(props.row.original.cost))}
              </div>
            );
          }
          return (
            <div className="money">
              {" "}
              <span>&#8362;</span>
              {thousandSeparator(twoZeroAfterDot(props.row.original.cost))}
            </div>
          );
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.cost + sum, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "אישור מחלה  / מילואים",
        accessor: "submission_request",
        disable_export_excel: true,
        Cell: ({ download, row }) => {
          const { submission_request } = row.original;
          const [anchorEl, setAnchorEl] = useState(null);
          const open = Boolean(anchorEl);

          const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
          };

          const handleDownload = async (file) => {
            const fileData = await download(file.id);
            const downloadLink = document.createElement("a");
            downloadLink.href = fileData;
            downloadLink.download = file.name;
            downloadLink.click();
          };

          const handleClose = () => {
            setAnchorEl(null);
          };
          return submission_request ? (
            <div>
              <IconButton
                size="medium"
                color="primary"
                onClick={handleClick}
                aria-controls="basic-menu"
                aria-haspopup="true"
                // aria-expanded={open ? "true" : undefined}
              >
                <AiOutlineCloudDownload />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {submission_request.files.map((file) => (
                  <MenuItem
                    key={file.id}
                    onClick={() => {
                      handleDownload(file);
                      handleClose();
                    }}
                  >
                    {file.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ) : (
            <></>
          );
        },
      },
      {
        Header: "",
        accessor: "buttons",
        Cell: (props) => {
          const {
            daily_notes,
            manual_start,
            manual_end,
            clock_start,
            clock_end,
            planned_start,
            planned_end,
            trim_start,
            trim_end,
            role_name,
            employee_id,
            employee_name,
            working_date,
            actual_end,
            actual_start,
            sick,
            reserve,
            vacation,
          } = props.row.original;
          const event = props.row.original;
          const {
            setEventToEdit,
            isManager,
            blocked_from_date,
            handleEventToDelete,
            isAllowedToEdit,
            setDataState,
            row,
            deleteEvent,
          } = props;
          let data = cloneDeep(props.data);
          const is_blocked_by_date = blocked_from_date
            ? working_date < blocked_from_date
            : false;

          let disabled =
            !isManager ||
            is_blocked_by_date ||
            !isAllowedToEdit ||
            sick ||
            vacation ||
            reserve;

          if (
            props.row.original.is_week_summary ||
            props.row.original.is_padded
          ) {
            disabled = true;
          }

          return disabled ? (
            event?.logs?.length > 0 ? (
              <ButtonIcon
                style={{
                  width: "unset",
                  height: "unset",
                  color: COLOR_RESTIGO_PRIMARY,
                  backgroundColor: "transparent",
                  lineHeight: "0.8",
                }}
                alt="הסטוריית עדכונים"
                icon={BsClockHistory}
                onClick={() =>
                  setEventToEdit({
                    typeLogs: true,
                    logs: event.logs,
                  })
                }
                iconSize="1.4em"
              />
            ) : (
              ""
            )
          ) : (
            <div>
              <div className="flex-center">
                <ButtonIcon
                  style={{
                    width: "unset",
                    height: "unset",
                    marginLeft: "0.5rem",
                    color: "#FFCE00",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid black",
                    borderRadius: "0",
                    lineHeight: "0.8",
                  }}
                  alt="ערוך שעות בפועל ידני"
                  icon={FaPencilAlt}
                  iconSize="1.4em"
                  onClick={() => {
                    setEventToEdit({
                      ...event,
                      hours: {
                        manual: {
                          start: manual_start,
                          end: manual_end,
                        },
                        trim: {
                          start: trim_start,
                          end: trim_end,
                        },
                        planned: {
                          start: planned_start,
                          end: planned_end,
                        },
                        clock: {
                          start: clock_start,
                          end: clock_end,
                        },
                      },
                      role: { name: role_name },
                      employee: { name: employee_name, id: employee_id },
                      openHoursEdit: true,
                    });
                  }}
                />
                <ButtonIcon
                  style={{
                    width: "unset",
                    height: "unset",
                    color: " rgb(219, 30, 30)",
                    backgroundColor: "transparent",
                    lineHeight: "0.8",
                  }}
                  alt="מחק משמרת"
                  icon={FaUserTimes}
                  iconSize="1.4em"
                  onClick={() => {
                    if (actual_start || actual_end) {
                      handleEventToDelete({ event, index: row.index });
                    } else {
                      deleteEvent({
                        event,
                        isReport: true,
                        onSuccess: () => {
                          data.splice(row.index, 1);
                          setDataState(data);
                        },
                      });
                    }
                  }}
                />

                {event?.logs?.length > 0 && (
                  <ButtonIcon
                    style={{
                      width: "unset",
                      height: "unset",
                      color: COLOR_RESTIGO_PRIMARY,
                      backgroundColor: "transparent",
                      lineHeight: "0.8",
                    }}
                    alt="הסטוריית עדכונים"
                    icon={BsClockHistory}
                    onClick={() =>
                      setEventToEdit({
                        typeLogs: true,
                        logs: event.logs,
                      })
                    }
                    iconSize="1.4em"
                  />
                )}
                <ButtonIcon
                  style={{
                    width: "unset",
                    height: "unset",
                    color: COLOR_RESTIGO_PRIMARY,
                    backgroundColor: "transparent",
                    lineHeight: "0.8",
                  }}
                  alt="הוספת הערה ליום"
                  icon={MdNoteAdd}
                  iconSize="1.4em"
                  onClick={() =>
                    setEventToEdit({
                      ...event,
                      daily_notes,
                    })
                  }
                />
              </div>
            </div>
          );
        },
      },
    ],
  },
];

export const event_columns_mobile = [
  {
    Header: ({
      title,
      isManager,
      blocked_from_date,
      isAllowedToEdit,
      setAddAssignEmployee,
      cant_edit_self_id,
      rows,
    }) => {
      const disabled = !isManager || !isAllowedToEdit;
      return disabled ? (
        <h3>{title}</h3>
      ) : (
        <div className="flex-between">
          <h3>{title}</h3>
          <ButtonIcon
            style={{
              width: "3rem",
              height: "3rem",

              color: "#00a2ea",
              lineHeight: "0.8",
            }}
            alt="הוסף משמרת"
            icon={FaPlus}
            onClick={() => {
              let employee_id = "";
              rows.forEach((eachRow) => {
                if (eachRow.original.employee_id) {
                  employee_id = eachRow.original.employee_id;
                }
              });
              setAddAssignEmployee({
                name: rows[0].original["employee_name"],
                id: employee_id,
              });
            }}
          />
        </div>
      );
    },
    id: "title",
    Footer: "",
    columns: [
      {
        Header: "סטטוס",
        accessor: "enable",
        Cell: ({ value, row }) => {
          return value === undefined ? (
            ""
          ) : value === 1 ? (
            <span style={{ color: "green" }}>פעיל</span>
          ) : (
            <span style={{ color: "red" }}>לא פעיל</span>
          );
        },
      },
      {
        Header: "שם עובד",
        accessor: "employee_name",
        Footer: 'סה"כ',
      },
      {
        Header: "ת.ז / דרכון",
        accessor: "identifier",
      },
      {
        Header: "מ. עובד",
        accessor: "employee_number",
      },
      {
        Header: "מ. עובד מ. שכר",
        accessor: "employee_accountancy_number",
      },
      {
        Header: "תאריך הקמה",
        accessor: "hebrew_create_date",
        Cell: ({ row }) => {
          const { hebrew_create_date } = row.original;

          return (
            <span>
              {hebrew_create_date ? `${fixDate(hebrew_create_date)}` : ""}
            </span>
          );
        },
      },
      {
        Header: "תאריך",
        accessor: "working_date",
        Cell: ({ row, holidays }) => {
          if (row.original.is_week_summary) {
            return <span style={{ fontWeight: "bold" }}>סיכום שבועי</span>;
          }
          const { working_date, holiday, hours_total } = row.original;
          return `${fixDate(working_date, "DD/MM")}`;
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              [
                ...new Set(
                  self.rows
                    .filter(
                      (row) =>
                        !row.values.vacation &&
                        !row.values.sick &&
                        !row.values.reserve &&
                        !row.original.is_week_summary &&
                        !row.original.is_padded
                    )
                    .map((row) => row.values.working_date)
                ),
              ].length,
            [self.rows]
          );

          return <>{`סה"כ ${total} ימים`}</>;
        },
      },
      {
        Header: "יום",
        accessor: "week_day",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return <></>;
          }
          return getShortHebDay(props.row.original.week_day);
        },
      },
      {
        Header: "תפקיד",
        accessor: "role_name",
        Cell: (props) => {
          if (
            props.row.original.is_week_summary ||
            props.row.original.is_padded
          ) {
            return <></>;
          }
          return props.row.original.role_name
            ? props.row.original.role_name
            : "לא שובץ";
        },
      },
      // accessor: "is_padded" -> is needed for filter padded days in excel,
      {
        Header: "",
        accessor: "is_padded",
        Cell: (props) => <></>,
      },

      {
        Header: "התחלה",
        accessor: "actual_start",
        Cell: (props) => `${fixHour(props.row.original.actual_start)}`,
      },
      {
        Header: "סיום",
        accessor: "actual_end",
        Cell: (props) => `${fixHour(props.row.original.actual_end)}`,
      },
      {
        Header: "התחלה - תכנון",
        accessor: "planned_start",
        Cell: (props) => `${fixHour(props.row.original.planned_start)}`,
      },
      {
        Header: "סיום - תכנון",
        accessor: "planned_end",
        Cell: (props) => `${fixHour(props.row.original.planned_end)}`,
      },
      {
        Header: "התחלה - מנהל",
        accessor: "manual_start",
        Cell: (props) => `${fixHour(props.row.original.manual_start)}`,
      },
      {
        Header: "סיום - מנהל",
        accessor: "manual_end",
        Cell: (props) => `${fixHour(props.row.original.manual_end)}`,
      },
      {
        Header: "התחלה - שעון",
        accessor: "clock_start",
        Cell: (props) => `${fixHour(props.row.original.clock_start)}`,
      },
      {
        Header: "סיום - שעון",
        accessor: "clock_end",
        Cell: (props) => `${fixHour(props.row.original.clock_end)}`,
      },
      {
        Header: "התחלה - חיתוך",
        accessor: "trim_start",
        Cell: (props) => `${fixHour(props.row.original.trim_start)}`,
      },
      {
        Header: "סיום - חיתוך",
        accessor: "trim_end",
        Cell: (props) => `${fixHour(props.row.original.trim_end)}`,
      },
      {
        Header: "הערות",
        accessor: "notes",
        Cell: ({ value, row }) => {
          const style = { fontWeight: "bold" };
          return (
            <div>
              {value && (
                <p>
                  <span style={style}>הערות משמרת : </span>
                  {value}
                </p>
              )}
              {row.original.daily_notes && (
                <p>
                  <span style={style}>הערות יומיות : </span>
                  {row.original.daily_notes}
                </p>
              )}
              {row.original.employee_notes && (
                <p>
                  <span style={style}>הערות מכרטיס עובד : </span>
                  {row.original.employee_notes}
                </p>
              )}
            </div>
          );
        },
      },
      {
        Header: "100%",
        accessor: "hours_100",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_100")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_100");
        },

        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_100 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "125%",
        accessor: "hours_125",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_125")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_125");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_125 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "150%",
        accessor: "hours_150",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_150")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_150");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_150 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "שעות 150 ללא לילה",
        accessor: "hours_150_without_night",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_150_without_night")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_150_without_night");
        },

        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce(
                  (sum, row) => row.values.hours_150_without_night + sum,
                  0
                ),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "שעות שבת",
        accessor: "hours_150_shabat",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_150_shabat")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_150_shabat");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_150_shabat + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "שעות חג",
        accessor: "hours_in_holiday",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_in_holiday")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_in_holiday");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_in_holiday + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "שעות שבת + חג",
        accessor: "hours_in_holiday_hours_150_shabat",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_in_holiday_hours_150_shabat")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_in_holiday_hours_150_shabat");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce(
                  (sum, row) =>
                    row.values.hours_in_holiday_hours_150_shabat + sum,
                  0
                ),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "175%",
        accessor: "hours_175",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_175")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_175");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_175 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: "200%",
        accessor: "hours_200",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_200")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_200");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_200 + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip text={'סה"כ שעות 100% + שעות 100% בשבת'}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            שעות סוציאליות
          </>
        ),
        accessor: "social_hours",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "social_hours")}
              </span>
            );
          }
          return mergeEventRows(props, "social_hours");
        },
        width: 1,
        headerName: "שעות סוציאליות",
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.social_hours + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: 'תמריץ אמצ"ש',
        accessor: "total_incentive_hours",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "total_incentive_hours")}
              </span>
            );
          }
          return mergeEventRows(props, "total_incentive_hours");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce(
                  (sum, row) => row.values.total_incentive_hours + sum,
                  0
                ),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: 'סה"כ שעות',
        accessor: "hours_total",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_total")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_total");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_total + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip
              text={'הפרש בין סה"כ שעות עבודה בפועל ברמת כל יום לבין שעות תקן'}
            >
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            ש.חוסר תקן
          </>
        ),
        accessor: "irregular_hours",
        Cell: (props) => {
          if (props.row.original.irregular_hours) {
            return (
              <span style={{ unicodeBidi: "plaintext" }}>
                {twoZeroAfterDot(props.row.original.irregular_hours)}
              </span>
            );
          }
        },
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.irregular_hours + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip text={"ש.חוסר תקן פחות 10"}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            ש.חוסר לקיזוז
          </>
        ),
        accessor: "no_offset_hours",
        Cell: (props) => {
          if (props.row.original.no_offset_hours) {
            return (
              <span>{twoZeroAfterDot(props.row.original.no_offset_hours)}</span>
            );
          }
        },
        Footer: (self) => {
          let total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.irregular_hours + sum, 0),
            [self.rows]
          );

          total = total - SUBTRAHEND_FROM_IRREGULAR_HOURS;
          return <>{total > 0 ? twoZeroAfterDot(total) : null}</>;
        },
      },
      {
        Header: "הפסקות",
        accessor: "break",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "break")}
              </span>
            );
          }
          return mergeEventRows(props, "break");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.break + sum, 0),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      {
        Header: () => (
          <>
            <BtnTooltip text={"רלוונטי לסניפים שההפסקה הינה בתשלום"}>
              <i
                style={{ fontSize: "1.4rem" }}
                className="fas fa-info-circle"
              ></i>
            </BtnTooltip>
            סה״כ שעות בניכוי הפסקות
          </>
        ),
        accessor: "hours_total_minus_break",
        Cell: (props) => {
          if (props.row.original.is_week_summary) {
            return (
              <span style={{ fontWeight: "bold" }}>
                {mergeEventRows(props, "hours_total_minus_break")}
              </span>
            );
          }
          return mergeEventRows(props, "hours_total_minus_break");
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce(
                  (sum, row) => row.values.hours_total_minus_break + sum,
                  0
                ),
            [self.rows]
          );

          return <>{twoZeroAfterDot(total)}</>;
        },
      },
      // {
      //   Header: "חישוב טיפים",
      //   accessor: "total_tip_calc",
      //   Cell: (props) => {
      //     const { row } = props;
      //     if (!row.original.total_tip_calc) {
      //       return "";
      //     }
      //     return (
      //       <div className="money">
      //         {" "}
      //         <span>&#8362;</span>
      //         {thousandSeparator(
      //           twoZeroAfterDot(props.row.original.total_tip_calc)
      //         )}
      //       </div>
      //     );
      //   },
      //   width: 1,
      //   Footer: (self) => {
      //     const total = useMemo(
      //       () =>
      //         self.rows.reduce(
      //           (sum, row) => row.values.total_tip_calc + sum,
      //           0
      //         ),
      //       [self.rows]
      //     );

      //     return (
      //       <div className="money money__footer">
      //         <span>&#8362;</span>
      //         {twoZeroAfterDot(total)}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "בונוס",
        accessor: "bonus_amount",
        Cell: (props) => {
          const { row } = props;
          if (!row.original.bonus_amount) {
            return "";
          }
          return (
            <div className="money">
              {" "}
              <span>&#8362;</span>
              {thousandSeparator(
                twoZeroAfterDot(props.row.original.bonus_amount)
              )}
            </div>
          );
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => {
                  let bonus = row.values.bonus_amount
                    ? Number(row.values.bonus_amount)
                    : 0;
                  return bonus + sum;
                }, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "מפרעה",
        accessor: "on_account",
        Cell: (props) => {
          const { row } = props;
          if (!row.original.on_account) {
            return "";
          }
          return (
            <div className="money">
              {" "}
              <span>&#8362;</span>
              {thousandSeparator(
                twoZeroAfterDot(props.row.original.on_account)
              )}
            </div>
          );
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => {
                  let bonus = row.values.on_account
                    ? Number(row.values.on_account)
                    : 0;
                  return bonus + sum;
                }, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "הערות בונוס/מפרעה",
        accessor: "bonus_note",
      },
      {
        Header: "שעות לילה",
        accessor: "hours_night",
        Cell: ({ value }) => {
          return twoZeroAfterDot(value);
        },
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.hours_night + sum, 0),
            [self.rows]
          );

          return twoZeroAfterDot(total);
        },
      },
      {
        Header: "ימי חופש",
        accessor: "vacation",
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "vacation"),
            [self.rows]
          );
          return total;
        },
      },
      {
        Header: "ימי חופשה לקיזוז",
        accessor: "vacation_for_paid",
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "vacation_for_paid"),
            [self.rows]
          );
          return total;
        },
      },
      {
        Header: "ימי מחלה",
        accessor: "sick",
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "sick"),
            [self.rows]
          );
          return total;
        },
      },
      {
        Header: "ימי מחלה לתשלום",
        accessor: "sick_for_paid",
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "sick_for_paid"),
            [self.rows]
          );
          return total;
        },
      },
      {
        Header: "ימי מילואים",
        accessor: "reserve",
        Footer: (self) => {
          const total = useMemo(
            () => sumFooter(self.rows, "reserve"),
            [self.rows]
          );
          return total;
        },
      },
      {
        Header: "עלות",
        accessor: "cost",
        Cell: (props) => {
          const { row } = props;
          if (!row.original.cost) {
            return "";
          }
          if (props.row.original.is_week_summary) {
            return (
              <div className="money" style={{ fontWeight: "bold" }}>
                {" "}
                <span>&#8362;</span>
                {thousandSeparator(twoZeroAfterDot(props.row.original.cost))}
              </div>
            );
          }
          return (
            <div className="money">
              {" "}
              <span>&#8362;</span>
              {thousandSeparator(twoZeroAfterDot(props.row.original.cost))}
            </div>
          );
        },
        width: 1,
        Footer: (self) => {
          const total = useMemo(
            () =>
              self.rows
                .filter((row) => row.original.is_week_summary !== true)
                .reduce((sum, row) => row.values.cost + sum, 0),
            [self.rows]
          );

          return (
            <div className="money money__footer">
              <span>&#8362;</span>
              {twoZeroAfterDot(total)}
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "buttons",
        Cell: (props) => {
          const {
            daily_notes,
            manual_start,
            manual_end,
            clock_start,
            clock_end,
            planned_start,
            planned_end,
            trim_start,
            trim_end,
            role_name,
            employee_id,
            employee_name,
            working_date,
            actual_end,
            actual_start,
            sick,
            reserve,
            vacation,
          } = props.row.original;
          const event = props.row.original;
          const {
            setEventToEdit,
            isManager,
            blocked_from_date,
            handleEventToDelete,
            isAllowedToEdit,
            setDataState,
            row,
            deleteEvent,
          } = props;
          let data = cloneDeep(props.data);
          const is_blocked_by_date = blocked_from_date
            ? working_date < blocked_from_date
            : false;

          let disabled =
            !isManager ||
            is_blocked_by_date ||
            !isAllowedToEdit ||
            sick ||
            vacation ||
            reserve;

          if (
            props.row.original.is_week_summary ||
            props.row.original.is_padded
          ) {
            disabled = true;
          }

          return disabled ? (
            event?.logs?.length > 0 ? (
              <ButtonIcon
                style={{
                  width: "unset",
                  height: "unset",
                  color: COLOR_RESTIGO_PRIMARY,
                  backgroundColor: "transparent",
                  lineHeight: "0.8",
                }}
                alt="הסטוריית עדכונים"
                icon={BsClockHistory}
                onClick={() =>
                  setEventToEdit({
                    typeLogs: true,
                    logs: event.logs,
                  })
                }
                iconSize="1.4em"
              />
            ) : (
              ""
            )
          ) : (
            <div>
              <div className="flex-center">
                <ButtonIcon
                  style={{
                    width: "unset",
                    height: "unset",
                    marginLeft: "0.5rem",
                    color: "#FFCE00",
                    backgroundColor: "transparent",
                    borderBottom: "1px solid black",
                    borderRadius: "0",
                    lineHeight: "0.8",
                  }}
                  alt="ערוך שעות בפועל ידני"
                  icon={FaPencilAlt}
                  iconSize="1.4em"
                  onClick={() => {
                    setEventToEdit({
                      ...event,
                      hours: {
                        manual: {
                          start: manual_start,
                          end: manual_end,
                        },
                        trim: {
                          start: trim_start,
                          end: trim_end,
                        },
                        planned: {
                          start: planned_start,
                          end: planned_end,
                        },
                        clock: {
                          start: clock_start,
                          end: clock_end,
                        },
                      },
                      role: { name: role_name },
                      employee: { name: employee_name, id: employee_id },
                      openHoursEdit: true,
                    });
                  }}
                />
                <ButtonIcon
                  style={{
                    width: "unset",
                    height: "unset",
                    color: " rgb(219, 30, 30)",
                    backgroundColor: "transparent",
                    lineHeight: "0.8",
                  }}
                  alt="מחק משמרת"
                  icon={FaUserTimes}
                  iconSize="1.4em"
                  onClick={() => {
                    if (actual_start || actual_end) {
                      handleEventToDelete({ event, index: row.index });
                    } else {
                      deleteEvent({
                        event,
                        isReport: true,
                        onSuccess: () => {
                          data.splice(row.index, 1);
                          setDataState(data);
                        },
                      });
                    }
                  }}
                />

                {event?.logs?.length > 0 && (
                  <ButtonIcon
                    style={{
                      width: "unset",
                      height: "unset",
                      color: COLOR_RESTIGO_PRIMARY,
                      backgroundColor: "transparent",
                      lineHeight: "0.8",
                    }}
                    alt="הסטוריית עדכונים"
                    icon={BsClockHistory}
                    onClick={() =>
                      setEventToEdit({
                        typeLogs: true,
                        logs: event.logs,
                      })
                    }
                    iconSize="1.4em"
                  />
                )}
                <ButtonIcon
                  style={{
                    width: "unset",
                    height: "unset",
                    color: COLOR_RESTIGO_PRIMARY,
                    backgroundColor: "transparent",
                    lineHeight: "0.8",
                  }}
                  alt="הוספת הערה ליום"
                  icon={MdNoteAdd}
                  iconSize="1.4em"
                  onClick={() =>
                    setEventToEdit({
                      ...event,
                      daily_notes,
                    })
                  }
                />
              </div>
            </div>
          );
        },
      },
    ],
  },
];
