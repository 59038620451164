import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dateReqFormat } from "../../helpers";
import Axios from "axios";
import { addWeeks, endOfWeek, format, startOfWeek } from "date-fns";
import { objectToFormData } from "../../components/Forms/helpers";
import { setConfirm, setSnackBar } from "../actions/confirmAction";
import { setError } from "../actions/errorAction";
import { startLoading, stopLoading } from "../../redux/actions/loaderAction";
import { action } from "mobx";
const currentDate = new Date();
const BASE_BRANCHES_URL = "pettyCashBranches";
const BASE_SHIFTS_URL = "pettyCashShifts";
const BASE_PETTY_CASH_FORM = "depositPettyCash";
const DEFAULT_PETTY_CASH_OPTIONS = [3];

const INITIAL_STATE = {
  filters: {
    selectedDates: {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ),
      key: "selection",
      label: "החודש",
    },
  },

  pettyCashObj: {
    id: null,
    branch: "",
    shift: "",
    date: dateReqFormat(new Date()),
    plot: "",

    cash: "",
  },
  checkedBranches: [],
  pettyCashData: [],
  pettyCashBranches: [],
  pettyCashShifts: [],
  isLoading: false,
  isEditMode: false,
  validationError: "",
  received_orders_sum_accepted: 0,
  petty_cash_deposit_sum: 0,
  petty_cash_balance: 0,
};

const pettyCashSlice = createSlice({
  name: "incomeCash",
  initialState: INITIAL_STATE,
  reducers: {
    setFilters: (state, action) => {
      const { name, value } = action.payload;
      state.filters[name] = value;
    },
    setEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setValidationError: (state, action) => {
      state.validationError = action.payload;
    },
    setPettCashBranches: (state, action) => {
      state.pettyCashBranches = action.payload;
    },
    changeBranchAmount: (state, action) => {
      state.pettyCashBranches = action.payload;
    },
    deleteBranch: (state, action) => {
      state.pettyCashBranches = action.payload;
    },
    setPettyCashShifts: (state, action) => {
      state.pettyCashShifts = action.payload;
    },
    changeShiftName: (state, action) => {
      state.pettyCashShifts = action.payload;
    },
    deleteShift: (state, action) => {
      state.pettyCashShifts = action.payload;
    },
    resetForm: (state, action) => {
      state.pettyCashObj = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getBranchesDefaultsValues.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBranchesDefaultsValues.fulfilled, (state, action) => {
      state.pettyCashBranches = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getBranchesDefaultsValues.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getShiftDefaults.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getShiftDefaults.fulfilled, (state, action) => {
      state.pettyCashShifts = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getShiftDefaults.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchAllDesposits.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllDesposits.fulfilled, (state, action) => {
      state.pettyCashData = action.payload.deposits;
      state.received_orders_sum_accepted = action.payload.acceptedOrdersSum;
      state.petty_cash_deposit_sum = action.payload.pettyCashDepositsSum;
      state.petty_cash_balance = action.payload.pettyCashBalance;

      state.isLoading = false;
    });
    builder.addCase(fetchAllDesposits.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchDeposit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDeposit.fulfilled, (state, action) => {
      const { id, branch, plot, cash, date, petty_cash_shift } = action.payload;

      const formBranch = {
        id: branch.id,
        value: branch.id,
        label: branch.name,
      };
      const petty_cash_shiftForm = {
        id: petty_cash_shift.id,
        value: petty_cash_shift.id,
        label: petty_cash_shift.shift_name,
      };

      const obj = {
        id,
        branch: formBranch,
        cash,
        plot,
        shift: petty_cash_shiftForm,
        date,
      };
      state.pettyCashObj = obj;
      state.isLoading = false;
    });
    builder.addCase(fetchDeposit.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getBranchesDefaultsValues = createAsyncThunk(
  "getBranches",
  async (payload, { dispatch, getState }) => {
    const branchIds = payload.map((branch) => branch.id);

    const token = getState().auth.token;
    const res = await Axios.get(`${BASE_BRANCHES_URL}`, {
      params: {
        branch_id: branchIds,
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;

    return data;
  }
);

export const updatePettyBranches = createAsyncThunk(
  "updatePettyBranches",
  async (branches, { dispatch, getState }) => {
    const token = getState().auth.token;
    const pettyCashBranches = getState().pettyCash.pettyCashBranches;

    let res;

    try {
      dispatch(startLoading());
      res = await Axios.post(`${BASE_BRANCHES_URL}`, pettyCashBranches, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status == 200) {
        dispatch(setConfirm("עדכון ערכים לסניפים בוצע בהצלחה !"));
      }
      dispatch(getBranchesDefaultsValues(branches));
    } catch (e) {
      dispatch(
        setError(
          "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          "ארעה שגיאה - סניפים לא נשמרו"
        )
      );
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const getShiftDefaults = createAsyncThunk(
  "getShiftDefaults",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const res = await Axios.get(`${BASE_SHIFTS_URL}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;

    return data;
  }
);

export const updatePettyCashShifts = createAsyncThunk(
  "updatePettyCashShifts",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const pettyCashShifts = getState().pettyCash.pettyCashShifts;

    let res;

    try {
      dispatch(startLoading());
      res = await Axios.post(`${BASE_SHIFTS_URL}`, pettyCashShifts, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status == 200) {
        dispatch(setConfirm("עדכון ערכים למשמרות בוצע בהצלחה !"));
      }
    } catch (e) {
      dispatch(
        setError(
          "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          "ארעה שגיאה - משמרות לא נשמרו"
        )
      );
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const submitPettyCashForm = createAsyncThunk(
  "submitPettyCashForm",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const pettyCashId = getState().pettyCash.pettyCashObj?.id;
    let res;

    try {
      dispatch(startLoading());

      if (pettyCashId) {
        res = await Axios.post(
          `${BASE_PETTY_CASH_FORM}/${pettyCashId}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        // If pettyCashId does not exist, perform a POST request
        res = await Axios.post(BASE_PETTY_CASH_FORM, payload, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }

      if (res.status === 200) {
        dispatch(setConfirm("הפקדה לקופה קטנה נוצרה בהצלחה!"));
      }
    } catch (e) {
      dispatch(
        setError(
          "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          "ארעה שגיאה הטופס לא מולא כמו שצריך"
        )
      );
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const fetchAllDesposits = createAsyncThunk(
  "fetchAllDesposits",
  async ({ checkedBranches, pettyCashOptions }, { dispatch, getState }) => {
    const token = getState().auth.token;
    const user = getState().auth.user;
    const filters = getState().pettyCash.filters;

    const res = await Axios.get(`${BASE_PETTY_CASH_FORM}`, {
      params: {
        branch_id: checkedBranches,
        pettyCashOptions: pettyCashOptions ?? DEFAULT_PETTY_CASH_OPTIONS,
        start_date: format(filters.selectedDates.startDate, "yyyy-MM-dd"),
        end_date: format(filters.selectedDates.endDate, "yyyy-MM-dd"),
      },
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const deletePettyCashDeposit = createAsyncThunk(
  "deletePettyCashDeposit",
  async ({ pettyCashFormId, checkedBranches }, { dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      dispatch(startLoading());
      const res = await Axios.delete(
        `${BASE_PETTY_CASH_FORM}/${pettyCashFormId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status == 200) {
        dispatch(setConfirm("מחיקת הפקדה לקופה קטנה בוצעה בהצלחה!"));
      }
      dispatch(fetchAllDesposits(checkedBranches));
    } catch (e) {
      dispatch(
        setError({
          title: "ארעה שגיאה בשרת",
          message: "ניתן לפנות לתמיכה הטכנית של רסטיגו",
        })
      );
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const fetchDeposit = createAsyncThunk(
  "fetchDeposit",
  async (pettyCashFormId, { dispatch, getState }) => {
    const token = getState().auth.token;
    const res = await Axios.get(`${BASE_PETTY_CASH_FORM}/${pettyCashFormId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const data = await res.data;
    return data;
  }
);

export const acceptOrder = createAsyncThunk(
  "acceptOrder",
  async (id, { dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      const res = await Axios.post(
        `newReceivedOrder/${id}/accept`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        dispatch(setConfirm("חשבונית אושרה בהצלחה"));
      }
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
        } else if (e.response.status === 500) {
          setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
        }
    }
  }
);

export const handleDeclineOrder = createAsyncThunk(
  "declineOrder",
  async (id, { dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      const res = await Axios.post(
        `newReceivedOrder/${id}/decline`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        dispatch(setConfirm("חשבונית בוטלה בהצלחה"));
      }
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
        } else if (e.response.status === 500) {
          setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
        }
    }
  }
);

export const downloadImageFromOrder = createAsyncThunk(
  "downloadImageFromOrder",
  async (file, { dispatch, getState }) => {
    let fileData;
    const token = getState().auth.token;
    await Axios.get(`newReceivedOrder/files/${file.id}/download`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        fileData = data;
        const downloadLink = document.createElement("a");
        downloadLink.href = fileData;
        downloadLink.download = file.name;
        downloadLink.click();
      })
      .catch((e) => {
        console.error(e);
        if (e.response)
          if (e.response.status === 500) {
            setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
          } else if (e.response.status === 404) {
            setError("תמונה לא קיימת", "מחיקת תמונה", false, true);
          }
      });
  }
);

export const { reducer: pettyCashReducer, actions: PettyCashActions } =
  pettyCashSlice;
