import {
  FaAddressBook,
  FaArchive,
  FaBalanceScale,
  FaBarcode,
  FaBlender,
  FaBook,
  FaBoxOpen,
  FaCalendarAlt,
  FaCalendarCheck,
  FaChartLine,
  FaChartPie,
  FaCogs,
  FaCoins,
  FaDollyFlatbed,
  FaEnvelope,
  FaEnvelopeOpenText,
  FaFileAlt,
  FaFileContract,
  FaFilePdf,
  FaGraduationCap,
  FaHandshake,
  FaHistory,
  FaHome,
  FaPaperPlane,
  FaRestroom,
  FaShoppingBasket,
  FaShoppingCart,
  FaSignInAlt,
  FaSignOutAlt,
  FaSnowflake,
  FaStarOfDavid,
  FaTruck,
  FaTv,
  FaUser,
  FaUserLock,
  FaUserPlus,
  FaWarehouse,
  FaUsersCog,
} from "react-icons/fa";
import { MdReceipt, MdScheduleSend } from "react-icons/md";
import { VscDebugDisconnect } from "react-icons/vsc";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import PermissionsView from "../pages/admin/permissions/PermissionsView";
import SubmissionsEmployee from "../pages/submissions_employee/SubmissionsEmployee";
import Loader from "./Loader";
import {
  TYPE_CLOTHES,
  TYPE_PROCEDURE,
  TYPE_WORK_AGREEMENT,
} from "../pages/BranchForms/consts";
import RequiredProcedures, {
  useRequiredProcedures,
} from "../middlewares/RequiredProcedures";
import Iframe from "../components/routes/Iframe";
import { useScreenSize } from "../Hooks";
import { useSelector } from "react-redux";
import TipsAproove from "../middlewares/TipsAproove";
import WorkAgreement from "../pages/BranchForms/WorkAgreement/Show";
import UserLogHistory from "../pages/admin/UserLogs/UserLogHistory";
import ExpenseViewSwitch from "../pages/NewDashboard/ExpenseViewSwitch";
import SexualHarrasment from "../pages/BranchForms/SexualHarrasment/Show";
import AccessibilityProcedure from "../pages/BranchForms/Accessibility/Show";
import SalesCost from "../pages/salesCost/SalesCost";
import DataAnlyzeDashboard from "../pages/DataAnalayze/DataAnalyzeDashboard";
const PurchaseSummaryGeneral = lazy(() =>
  import("../pages/purchaseSummary/PurchaseSummaryGeneral")
);
const SwitchSubmissionsManager = lazy(() =>
  import("../pages/submissions_manager/SwitchSubmissionsManager")
);
const Accountancy = lazy(() => import("../pages/accountancy/Accountancy"));
const PettyCash = lazy(() => import("../pages/accountancy/PettyCash"));

const BranchFees = lazy(() => import("../pages/BranchFees/BranchFees"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const IncomeViewSwitch = lazy(() => import("../pages/income/IncomeViewSwitch"));
const Inventory = lazy(() => import("../pages/inventory/Inventory"));
const ConstOrder = lazy(() => import("../pages/orders/constOrders/ConstOrder"));
const EventsOrders = lazy(() =>
  import("../pages/orders/eventsOrders/EventsOrders")
);
const ManageOrders = lazy(() =>
  import("../pages/orders/manageOrders/ManageOrders")
);
const NewOrder = lazy(() => import("../pages/orders/newOrders/NewOrder"));
const RecipesView = lazy(() => import("../pages/recipes/RecipesView"));
const OrdersAnalysis = lazy(() =>
  import("../pages/orders_analysis/OrdersAnalysis")
);
const ReportsEmployees = lazy(() =>
  import("../pages/reports/employees/ReportsEmployees")
);
const WorkSchedule = lazy(() => import("../pages/schedule/WorkSchedule"));
const ActiveUsers = lazy(() =>
  import("../pages/admin/activeUsers/ActiveUsers")
);
const CreateNewView = lazy(() =>
  import("../pages/admin/createNew/ManageNetworkView")
);
const PackageView = lazy(() => import("../pages/admin/packages/PackageView"));
const MessageView = lazy(() => import("../pages/admin/messages/MessageView"));
const SmsView = lazy(() => import("../pages/admin/sms/SmsView"));
const HolidayView = lazy(() => import("../pages/admin/holidays/HolidayView"));
const Index = lazy(() => import("../pages/admin/linkedInvoices/Index"));

const ScheduledTasks = lazy(() =>
  import("../pages/admin/scheduledTasks/generalTasks/ScheduledTasks")
);
const ScheduledEmailReports = lazy(() =>
  import("../pages/admin/scheduledTasks/emailTasks/ScheduledTasksEmailReports")
);

const AttendanceClocks = lazy(() =>
  import("../pages/admin/interfaces/attendanceClocks/AttendanceClocks")
);
const PointOfSales = lazy(() =>
  import("../pages/admin/interfaces/pointOfSales/PointOfSales")
);
const ExternalSystemOrders = lazy(() =>
  import("../pages/admin/interfaces/externalSystemOrders/ExternalSystemOrders")
);
const Permissions = lazy(() =>
  import("../pages/admin/permissionsNew/Permissions")
);
const SpecialRequest = lazy(() =>
  import("../pages/specialRequests/SpecialRequest")
);
const EmployeesDetails = lazy(() =>
  import("../pages/employeesDetails/EmployeesDetails")
);
const Procedures = lazy(() => import("../pages/BranchForms/Index"));

const Tasks = lazy(() => import("../pages/Tasks/TaskIndex"));
const NewHomePage = lazy(() => import("../pages/NewHomePage/NewHomePage"));

const Products = lazy(() => import("../pages/products/ProductsGeneral"));

const Goods = lazy(() => import("../pages/goods/GoodsGeneral"));
const Form101 = lazy(() => import("../pages/Form101/Form101"));
const ManagePositionGeneral = lazy(() =>
  import("../pages/managePositions/ManagePositionGeneral")
);

const Warehouse = lazy(() => import("../pages/warehouse/WarehouseGeneral"));

const NewRequisition = lazy(() =>
  import("../pages/purchaseRequisition/newRequisition/NewRequisitionGeneral")
);
const AdminRequisition = lazy(() =>
  import(
    "../pages/purchaseRequisition/adminRequisition/AdminRequisitionGeneral"
  )
);
const ExpenseSummaryGeneral = lazy(() =>
  import("../pages/expenseSummary/ExpenseSummaryGeneral")
);
const NewUsers = lazy(() => import("../pages/users/UsersGeneral"));
const Tickets = lazy(() => import("../pages/admin/activeUsers/Tickets"));
const useUserRoutes = () => {
  const { isMobile, isTablet, isDesktop } = useScreenSize();
  const {
    pages,
    admin,
    forms_not_filled,
    privileges_tabs,
    selected_branch,
    requiredProceduresTypes,
  } = useSelector((state) => {
    const { pages } = state.permissions;
    const { forms_not_filled, privileges_tabs } = state.user_privileges;
    const { selected_branch } = state.branches;
    const { requiredProcedures, requiredProceduresTypes } = state.generalData;
    const { admin } = state.authAdmin;
    return {
      pages,
      admin,
      forms_not_filled,
      privileges_tabs,
      selected_branch,
      requiredProcedures,
      requiredProceduresTypes,
    };
  });

  const filterRoutes = useCallback(
    ({ children, noMobile, noDesktop, id, element }) => {
      return (
        !(isMobile && !isTablet && noMobile) &&
        !(isDesktop && noDesktop) &&
        (!id || pages?.includes(id)) &&
        (children?.length || element)
      );
    },
    [isMobile, isDesktop, pages, isTablet]
  );

  const routes = useMemo(
    () =>
      insertIntoSuspense(
        [
          {
            name: "בית",
            icon: FaHome,
            index: true,
            element: <Dashboard />,
            isLagacy: true,
          },
          {
            name: "הזמנות",
            icon: FaShoppingBasket,
            path: "orders",
            children: [
              {
                id: 19,
                name: "הזמנות חדשות",
                path: "addorder",
                element: <NewOrder />,
                icon: FaShoppingBasket,
              },
              {
                id: 68,
                name: "הזמנות קבועות",
                path: "constOrder",
                element: <ConstOrder />,
                icon: FaShoppingCart,
              },
              {
                id: 20,
                name: "ניהול הזמנות",
                path: "manageorders",
                element: <ManageOrders />,
                icon: FaTruck,
              },
              {
                id: 73,
                name: "הזמנות לפי אירוע",
                path: "eventsOrders",
                element: <EventsOrders />,
                icon: FaTruck,
                noMobile: true,
              },
            ],
          },
          {
            id: 85,
            name: "דשבורד - תמונת מצב",
            path: "newHomePage",
            icon: FaHome,
            element: <NewHomePage />,
            noDesktop: true,
          },
          {
            name: "דרישות רכש",
            icon: FaShoppingCart,
            path: "purchaseRequisition",
            children: [
              {
                id: 82,
                name: "דרישת רכש חדשה",
                path: "newRequisition",
                element: <NewRequisition />,
                icon: FaShoppingBasket,
              },
              {
                id: 83,
                name: "ניהול דרישות רכש",
                path: "adminRequisition",
                element: <AdminRequisition />,
                icon: FaShoppingCart,
              },
            ],
          },
          // {
          //   name: "תמונת מצב",
          //   icon: FaChartLine,
          //   path: "snapshot",
          //   children: [
          //     {
          //       id: 10,
          //       name: "תמונת מצב",
          //       icon: FaChartLine,
          //       path: "dailymang",
          //       element: (
          //         <Iframe src="%D7%AA%D7%9E%D7%95%D7%A0%D7%AA-%D7%9E%D7%A6%D7%91.html" />
          //       ),
          //     },
          //     // {
          //     //   id: 38,
          //     //   name: "snapshot",
          //     //   path: "snapshot",
          //     //  icon: <FaChartLine ,
          //     //   element: <Iframe src="snapshot.html" />,
          //     // },
          //   ],
          // },
          {
            name: "הכנסות",
            path: "income",
            icon: FaSignInAlt,
            children: [
              {
                id: 70,
                name: "ניתוח הכנסות",
                path: "dashboard",
                icon: FaSignInAlt,
                element: <IncomeViewSwitch />,
              },
              {
                id: 71,
                name: "ניהול עמלות",
                path: "branchfees",
                icon: FaSignInAlt,
                element: <BranchFees />,
                noMobile: true,
              },
            ],
          },
          // {
          //   name: "ניתוח הוצאות חודשי",
          //   path: "expenses",
          //   icon: FaSignOutAlt,
          //   children: [
          //     {
          //       id: 84,
          //       name: "ניתוח הוצאות חודשי חדש",
          //       path: "expense-summary",
          //       icon: FaSignOutAlt,
          //       element: <ExpenseSummaryGeneral />,
          //       noMobile: false,
          //     },
          //   ],
          // },
          {
            name: "הוצאות",
            path: "expanse",
            icon: FaSignOutAlt,
            children: [
              // {
              //   id: 6,
              //   name: "הזנת הכנסות והוצאות",
              //   path: "dailyupdate",
              //   icon: FaSignOutAlt,
              //   element: (
              //     <Iframe src="%D7%94%D7%96%D7%A0%D7%AA-%D7%94%D7%9B%D7%A0%D7%A1%D7%95%D7%AA-%D7%95%D7%94%D7%95%D7%A6%D7%90%D7%95%D7%AA.html" />
              //   ),
              // },

              {
                id: 11,
                name: "ניתוח הוצאות חודשי",
                path: "monthlyExpenses",
                icon: FaSignOutAlt,
                element: <ExpenseSummaryGeneral />,
                noMobile: false,
              },

              // {
              //   id: 12,
              //   name: "ניתוח היסטורי",
              //   icon: FaSignOutAlt,
              //   path: "expanse_history",
              //   element: (
              //     <Iframe src="%D7%A0%D7%99%D7%AA%D7%95%D7%97-%D7%94%D7%99%D7%A1%D7%98%D7%95%D7%A8%D7%99.html" />
              //   ),
              //   noMobile: true,
              // },
              //requested to be hidden.
              // {
              //   id: 47,
              //   name: "דמי זיכיון והוצאות מטה",
              //   icon: FaSignOutAlt,
              //   path: "franchisefees",
              //   // element: <Iframe src="דמי-זיכיון-והוצאות-מטה.html" />,
              //   element: <ExpenseViewSwitch />,
              //   noMobile: true,
              // },
            ],
          },
          {
            name: "הנהלת חשבונות",
            path: "accounting",
            icon: FaBook,
            children: [
              {
                id: 22,
                name: "הנהלת חשבונות",
                path: "accountancy",
                icon: FaBook,
                element: <Accountancy />,
                noMobile: true,
              },
              {
                id: 89,
                name: "קופה קטנה",
                path: "pettyCash",
                icon: FaBook,
                element: <PettyCash />,
              },
              // {
              //   id: 64,
              //   name: "הפקת קבצים במבנה אחיד",
              //   path: "irsexport",
              //   icon: FaBook,
              //   element: (
              //     <Iframe src="הפקת-קבצים-במבנה-אחיד.html" />
              //   ),
              //   noMobile: true,
              // },
            ],
          },
          {
            name: "מלאי",
            icon: FaBarcode,
            path: "inventory",
            children: [
              // {
              //   id: 67,
              //   name: "ניהול מחסנים",
              //   icon: FaWarehouse,
              //   path: "inventoryStorageManage",
              //   element: <Iframe src="ניהול-מחסנים.html" />,
              //   noMobile: true,
              // },
              {
                id: 67,
                name: "ניהול מחסנים",
                icon: FaWarehouse,
                path: "warehouse",
                element: <Warehouse />,
                noMobile: false,
              },
              {
                id: 74,
                name: "ניהול מלאי",
                icon: FaBarcode,
                path: "inventoryV2",
                element: <Inventory />,
              },
              {
                id: 50,
                name: "ייצוא תנועות מלאי",
                path: "accountancyreports",
                icon: FaBook,
                element: (
                  <Iframe src="%D7%93%D7%95%D7%97%D7%95%D7%AA-%D7%94%D7%A0%D7%94%D7%9C%D7%AA-%D7%97%D7%A9%D7%91%D7%95%D7%A0%D7%95%D7%AA.html" />
                ),
                noMobile: true,
              },
            ],
          },
          {
            name: "הגדרות עסקיות",
            path: "business",
            icon: FaCogs,
            children: [
              {
                id: 8,
                name: "הגדרות עסקיות",
                icon: FaCogs,
                path: "netsettings",
                element: (
                  <Iframe src="%D7%94%D7%92%D7%93%D7%A8%D7%95%D7%AA-%D7%A2%D7%A1%D7%A7%D7%99%D7%95%D7%AA.html" />
                ),
                noMobile: true,
              },
              {
                id: 7,
                name: "ניהול עובדים",
                icon: FaUserPlus,
                path: "mangusers",
                element: <Iframe src="ניהול-עובדים.html" />,
              },
              {
                id: 9,
                name: "ניהול ספקים",
                icon: FaDollyFlatbed,
                path: "mangsuppliers",
                element: (
                  <Iframe src="%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%A1%D7%A4%D7%A7%D7%99%D7%9D.html" />
                ),
                noMobile: true,
              },
              {
                id: 65,
                name: "הוצאות קבועות",
                icon: FaCoins,
                path: "constExpenses",
                element: <Iframe src="הוצאות-קבועות.html" />,
              },
            ],
          },
          {
            name: "מוצרים",
            icon: FaSnowflake,
            path: "goods",
            children: [
              {
                id: 69,
                name: "ספר מתכונים",
                icon: FaBlender,
                path: "recipes",
                element: <RecipesView />,
                noMobile: false,
              },
              {
                id: 16,
                name: "ניהול מוצרים",
                path: "items",
                element: <Goods />,
                icon: FaSnowflake,
                noMobile: false,
              },
              // {
              //   id: 16,
              //   name: "ניהול מוצרים",
              //   icon: FaSnowflake,
              //   path: "mangitems",
              //   element: (
              //     <Iframe src="%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%9E%D7%95%D7%A6%D7%A8%D7%99%D7%9D.html" />
              //   ),
              //   noMobile: true,
              // },
              // {
              //   id: 17,
              //   name: "עץ מוצר",
              //   path: "itemproduct",
              //   element: (
              //     <Iframe src="%D7%A2%D7%A5-%D7%9E%D7%95%D7%A6%D7%A8.html" />
              //   ),
              //   noMobile: true,
              //   icon: FaSnowflake,
              // },
              //{
              //   id: 31,
              //   name: "עלות מכר",
              //   path: "salescost",
              //   element: (
              //     <Iframe src="%D7%A2%D7%9C%D7%95%D7%AA-%D7%9E%D7%9B%D7%A8.html" />
              //   ),
              //   noMobile: true,
              //   icon: FaSnowflake,
              // },
              // DEPRECATED PAGE - categories moved to ניהול מוצרים, expenditure types moved to ניהול מלאי
              // {
              //   id: 42,
              //   name: "ניהול קטגוריות",
              //   path: "mangcategories",
              //   element: (
              //     <Iframe src="%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%A7%D7%98%D7%92%D7%95%D7%A8%D7%99%D7%95%D7%AA.html" />
              //   ),
              //   icon: FaSnowflake,
              //   noMobile: true,
              // },
              //requested to be hidden
              // {
              //   id: 63,
              //   name: "ניהול יעדי מוצר",
              //   path: "manageitemgoals",
              //   element: <Iframe src="ניהול-יעדי-מוצר.html" />,
              //   icon: FaSnowflake,
              //   noMobile: true,
              // },
              {
                id: 17, //,76
                name: "עץ מוצר",
                path: "products",
                element: <Products />,
                icon: FaSnowflake,
                noMobile: false,
              },
            ],
          },
          {
            name: "דוחות רכש",
            path: "data",
            icon: FaChartPie,
            children: [
              // {
              //   id: 34,
              //   name: "ניתוח נתונים",
              //   icon: FaChartPie,
              //   path: "dataanalysis",
              //   element: (
              //     <Iframe src="%D7%A0%D7%99%D7%AA%D7%95%D7%97-%D7%A0%D7%AA%D7%95%D7%A0%D7%99%D7%9D.html" />
              //   ),
              //   noMobile: true,
              // },
              {
                id: 88,
                name: "ניתוח נתונים",
                icon: FaChartPie,
                path: "dataanalysisnew",
                element: <DataAnlyzeDashboard />,
                noMobile: true,
              },
              {
                id: 61,
                name: "ניתוח הזמנות",
                icon: FaChartPie,
                path: "orderdataanalysis",
                element: <OrdersAnalysis />,
                noMobile: true,
              },
              {
                id: 31,
                name: "עלות מכר",
                icon: FaChartPie,
                path: "salescost",
                element: <SalesCost />,
              },
              // {
              //   id: 87,
              //   name: "עלות מכר ישן",
              //   icon: FaChartPie,
              //   path: "salescostold",
              //   element: (
              //     <Iframe src="%D7%A2%D7%9C%D7%95%D7%AA-%D7%9E%D7%9B%D7%A8.html" />
              //   ),
              // },
              {
                id: 86,
                name: "רכש לפי ספק",
                icon: FaChartPie,
                path: "purchasesummary",
                element: <PurchaseSummaryGeneral />,
                noMobile: true,
              },
            ],
          },
          {
            name: "הפקת דוחות",
            icon: FaFilePdf,
            path: "reports",
            children: [
              {
                id: 54,
                name: "דוחות עובדים",
                icon: FaArchive,
                path: "employees",
                // element:(
                //   <TipsAproove>
                //     <ReportsEmployees/>
                //   </TipsAproove>
                // )
                //new reports page
                element: privileges_tabs.includes(25) ? (
                  <TipsAproove>
                    <ExpenseViewSwitch />
                  </TipsAproove>
                ) : (
                  <TipsAproove>
                    <ReportsEmployees />
                  </TipsAproove>
                ),
              },
            ],
          },
          {
            name: "שיבוץ עובדים",
            path: "employees/schedule",
            icon: FaCalendarAlt,
            children: [
              {
                id: 87,
                name: "ניהול עובדים חדש",
                icon: FaUsersCog,
                path: "newUsersInfo",
                element: <NewUsers />,
                noMobile: false,
              },
              {
                id: 40,
                name: "סידור עבודה",
                index: true,
                icon: FaCalendarAlt,
                element: <WorkSchedule />,
              },
              // {
              //   id: 48,
              //   name: "ניהול תפקידים",
              //   path: "mangpositions",
              //   icon: FaCalendarAlt,
              //   element: (
              //     <Iframe src="%D7%A0%D7%99%D7%94%D7%95%D7%9C-%D7%AA%D7%A4%D7%A7%D7%99%D7%93%D7%99%D7%9D.html" />
              //   ),
              //   noMobile: true,
              // },
              {
                id: 48,
                name: "ניהול תפקידים",
                path: "manage_positions",
                icon: FaCalendarAlt,
                element: <ManagePositionGeneral />,
                noMobile: false,
              },
              {
                id: 33,
                name: "הגשת משמרות",
                path: "submissions",
                element: (
                  <RequiredProcedures>
                    <SubmissionsEmployee />
                  </RequiredProcedures>
                ),
                iconClass: "fa fa-user-check",
              },
              {
                id: 72,
                name: "בקשות מיוחדות",
                path: "submissions/specialRequest",
                element: <SpecialRequest />,
                icon: FaEnvelopeOpenText,
              },
              {
                id: 62,
                name: "הגשת משמרות מנהל",
                path: "submissions/manager",
                element: <SwitchSubmissionsManager />,
                icon: FaCalendarCheck,
              },
              {
                id: 36,
                name: "פרטי עובדים",
                path: "details",
                element: <EmployeesDetails />,
                iconClass: <FaAddressBook />,
              },
            ],
          },
          {
            name: "אזור אישי",
            path: "personal",
            icon: FaUser,
            children: [
              {
                id: 15,
                name: "אזור אישי",
                path: "private",
                element: (
                  <Iframe src="%D7%90%D7%96%D7%95%D7%A8-%D7%90%D7%99%D7%A9%D7%99.html" />
                ),
                icon: FaUser,
              },
              ...(forms_not_filled?.includes(37)
                ? [
                    {
                      //for Route permission
                      id: 37,
                      name: "טופס 101",
                      path: "new101form",
                      element: <Form101 />,
                      icon: FaFileAlt,
                    },
                  ]
                : []),
              //Old 101 form
              // ...(forms_not_filled?.includes(37)
              //   ? [
              //       {
              //         id: 37,
              //         name: "טופס 101",
              //         path: "form101",
              //         element: (
              //           <Iframe src="%D7%98%D7%95%D7%A4%D7%A1-101.html" />
              //         ),
              //         icon: FaFileAlt,
              //       },
              //     ]
              //   : []),
              ...(selected_branch?.user_work_agreement &&
              forms_not_filled?.includes(41)
                ? [
                    {
                      id: 41,
                      name: "הסכם עבודה",
                      path: "workagreement",
                      element: (
                        <Iframe src="%D7%94%D7%A1%D7%9B%D7%9D-%D7%A2%D7%91%D7%95%D7%93%D7%94.html" />
                      ),
                      icon: FaHandshake,
                    },
                  ]
                : []),
            ],
          },

          {
            name: "ניהול ידע",
            path: "knowledge",
            icon: FaGraduationCap,
            children: [
              // {
              //   id: 51,
              //   name: "אישורי עסק",
              //   path: "certificate",
              //   element: (
              //     <Iframe src="%D7%90%D7%99%D7%A9%D7%95%D7%A8%D7%99-%D7%A2%D7%A1%D7%A7.html" />
              //   ),
              //   icon: FaFileContract,
              // },
              ...(forms_not_filled?.includes(41) &&
              !selected_branch?.user_work_agreement
                ? [
                    {
                      id: 41,
                      name: "הסכם עבודה חדש",
                      path: "procedures?open=true",
                      element: <WorkAgreement />,
                      icon: FaHandshake,
                    },
                  ]
                : []),
              ...(forms_not_filled?.includes(59)
                ? [
                    {
                      id: 59,
                      name: "תקנון הטרדה מינית",
                      path: "procedures?openHarrasment=true",
                      element: <SexualHarrasment />,
                      icon: FaRestroom,
                    },
                  ]
                : []),
              ...(forms_not_filled?.includes(52)
                ? [
                    {
                      id: 52,
                      name: "נגישות ושיוויון זכויות",
                      path: "procedures?accessibility=true",
                      element: <AccessibilityProcedure />,
                      icon: FaBalanceScale,
                    },
                  ]
                : []),
              {
                id: 75,
                name: "נהלים",
                path: "procedures",
                element: <Procedures />,
                icon: FaFileContract,
              },
            ],
          },
          // {
          //   id: 23,
          //   name: "דשבורד",
          //   path: "dashboard",
          //   icon: FaTv,
          //   element: <Iframe src="ראשי.html" />,
          //   noDesktop: true,
          // },
          {
            name: "משימות",
            path: "tasks",
            icon: FaCalendarAlt,
            children: [
              {
                id: 81,
                name: "צ׳ק ליסט",
                path: "checkLists",
                element: <Tasks />,
                icon: FaFileContract,
              },
            ],
          },
        ]
          .map(({ children, ...route }) => ({
            ...route,
            ...(!!children && {
              children: children.filter(filterRoutes),
            }),
          }))
          .filter(filterRoutes)
      ),
    [filterRoutes, selected_branch, forms_not_filled, requiredProceduresTypes]
  );

  const ROUTES_IDS = routes
    .flatMap((route) => {
      return route.id ?? route.children?.map(({ id }) => id);
    })
    .filter((route) => route);
  // const susRoutes = insertIntoSuspense(routes);
  return [ROUTES_IDS, routes];
};

// const ROUTES_IDS = routes
//   .flatMap((route) => {
//     return route.id ?? route.children?.map(({ id }) => id);
//   })
//   .filter((route) => route);

const useAdminRoutes = () => {
  const email = useSelector((state) => state.authAdmin.admin.email);

  const ADMIN_EMAIL = [
    "segevr@restigo.co.il",
    "amir@restigo.co.il",
    "alexla@restigo.co.il",
    "davidt@restigo.co.il",
    "aviva@restigo.co.il",
    "haimm@restigo.co.il",
    "ronik@restigo.co.il",
    "ronz@restigo.co.il",
    "adig@restigo.co.il",
  ];

  const adminRoutes = useMemo(
    () =>
      insertIntoSuspense([
        {
          index: true,
          name: "בית",
          icon: FaHome,
          element: <ActiveUsers />,
        },
        {
          name: "ניהול הרשאות",
          path: "permissions",
          icon: FaUserLock,
          element: <PermissionsView />,
        },
        {
          name: "CRM",
          path: "user-log-history",
          icon: FaHistory,
          // index: true,
          children: [
            {
              name: "כל הפניות",
              path: "all-tickets",
              icon: FaHistory,
              element: <Tickets />,
            },
            {
              name: "הסטוריית התחברויות",
              path: "user-log-history",
              icon: FaHistory,
              element: <UserLogHistory />,
            },
          ],
        },
        // {
        //   name: "ניהול הרשאות2",
        //   path: "permissions2",
        //  icon: <FaUserLock ,
        //   element: <Permissions />,
        // },

        ...(ADMIN_EMAIL.includes(email)
          ? [
              {
                name: "ניהול רשתות",
                path: "create",
                icon: FaUserPlus,
                element: <CreateNewView />,
              },
            ]
          : []),
        {
          name: "ניהול חבילות",
          path: "packages",
          icon: FaBoxOpen,
          element: <PackageView />,
        },
        {
          name: "הודעות",
          path: "messages",
          icon: FaEnvelope,
          element: <MessageView />,
        },
        {
          name: "סמס",
          path: "sms",
          icon: FaPaperPlane,
          element: <SmsView />,
        },
        {
          name: "חגים",
          path: "holidays",
          icon: FaStarOfDavid,
          element: <HolidayView />,
        },
        {
          name: "חשבוניות מקושרות",
          path: "linked-invoices",
          icon: MdReceipt,
          element: <Index />,
        },
        {
          name: "משימות מתוזמנות",
          path: "scheduled",
          icon: MdScheduleSend,
          children: [
            {
              name: "משימות כלליות",
              path: "scheduled-tasks",
              icon: MdScheduleSend,
              element: <ScheduledTasks />,
            },
            {
              name: "שליחת דוחות",
              path: "scheduled-email-reports",
              icon: MdScheduleSend,
              element: <ScheduledEmailReports />,
            },
          ],
        },
        {
          name: "ממשקים",
          path: "interfaces",
          icon: VscDebugDisconnect,
          children: [
            {
              name: "קופות",
              path: "point-of-sales",
              element: <PointOfSales />,
            },
            {
              name: "שעוני נוכחות",
              path: "attendance-clocks",
              element: <AttendanceClocks />,
            },
            {
              name: "הזמנות",
              path: "external-orders",
              element: <ExternalSystemOrders />,
            },
          ],
        },
      ]),
    [email]
  );

  return [adminRoutes];
};

const insertIntoSuspense = (routes) => {
  const susRoutes = routes.map(({ element, isLagacy, children, ...route }) => {
    if (route.path === "ניהול רשתות") {
    }
    return {
      ...route,
      ...(!!element && {
        isLagacy: isLagacy ?? element?.type === Iframe,
        element: <Suspense fallback={<Loader />}>{element}</Suspense>,
      }),
      ...(!!children && { children: insertIntoSuspense(children) }),
    };
  });
  return susRoutes;
};

// const susRoutes = insertIntoSuspense(routes);
// const susAdminRoutes = insertIntoSuspense(adminRoutes);

export { useUserRoutes, useAdminRoutes };
