import { FaAmbulance, FaUmbrellaBeach } from "react-icons/fa";
import soldier from "../images/soldier.png";
import React from "react";

export const SHEKEL_SIGN = "₪";
export const PRECENT_SIGN = "%";
export const EMOJIS_PATTERN =
  "/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g";
export const HEBREW_MONTHS = [
  "",
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "דצמבר",
];

export const HEBREW_DAYS = ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"];
export const NOT_REQUESTED = 0;
export const PENDING = 1;
export const APPROVED = 2;
export const CANCELED = 3;

export const VACATION_STATUS_SPEC_ARRAY = [
  { title: "לא ביקש" },
  { title: "ממתין לאישור" },
  { title: "מאושר" },
  { title: "ממתין לביטול חופש" },
];

export const SHIFT_REQUEST_TYPE_BY_SHIFT = 1;
export const SHIFT_REQUEST_TYPE_BY_ROLE = 2;

export const SPECIAL_REQUEST_TYPE = [
  { title: "חופשה", icon: () => <FaUmbrellaBeach /> },
  { title: "מחלה", icon: () => <FaAmbulance /> },
  {
    title: "מילואים",
    icon: () => <img style={{ width: "18px" }} src={soldier} alt="" />,
  },
];
export const SPECIAL_REQUEST_STATUS = [
  { title: "ממתין לאישור" },
  { title: "מאושר" },
  { title: "בוטל" },
];

export const SIZE_LIMIT_IN_SERVER = 1e8; // 1e8 =100 mb its the limit on the laravel server
