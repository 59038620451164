// privileges ids
export const NETWORK_MANAGER = 1;
export const BRANCH_MANAGER = 2;
export const SHIFT_MANAGER = 4;
export const KITCHEN_MANAGER = 5;
export const GENERAL_EMPLOYEE = 9;
export const SEND_ORDERS = 10;
export const PROCUREMENT_MANAGER = 11;
export const AREA_MANAGER = 12;
export const PROCUREMENT_AREA_MANAGER = 13;

export const HIDE_PRICES = 9;

// manage users
export const REQUIRE_PHONE_ID_ADDRESS = 24;
export const HIDE_SALARY = 65;

//manage orders
export const OVERRIDE_PRICES = 12; // if on user can update prices else refund is open automatically
export const SEND_REFUNDS_TO_SUPPLIER = 21;
export const DISABLE_DOCUMENT_DATE_CHANGE = 27;
export const CHOOSE_EXPENSE_TYPE = 30;
export const HIDE_RECEIVE_WITHOUT_BUTTON = 39;
export const MANDATORY_CHOSE_DOCUMENT_DATE = 28;
export const HIDE_TEMP_ITEM_BUTTON = 41;
export const ADD_ITEM_TO_CATALOG = 47;
export const UPDATE_CATALOG_ITEM_ATTRIBUTES = 44;
export const BLOCK_GAP_VALIDATION = 45;
export const CHANGE_BRANCH_ON_RECEIVE_WITHOUT = 46;
export const LAST_RECEIVED_PRICE_BUTTON = 52;
export const CONCENTRATOR_MULTI_BRANCHES = 53;
export const SHOW_RECEIVE_ORDER_BUTTON = 1;
export const SHOW_DUPLICATE_ORDER_BUTTON = 2;
export const SHOW_DUPLICATE_RECEIVE_ORDER_BUTTON = 58;
export const SHOW_VIEW_ORDER_BUTTON = 3;
export const SHOW_DELETE_ORDER_BUTTON = 26;
export const SHOW_DELETE_RECEIVE_ORDER_BUTTON = 55;
export const SHOW_SPLIT_ORDER_BUTTON = 42;
export const SHOW_UNITE_ORDER_BUTTON = 43;
export const AMOUNT_CHANGE_WITHOUT_REFUND = 54;
export const IGNORE_EXPENSE_RECEIVE = 56;
export const UPDATE_ITEM_CREDIT = 57;
export const EXPENDED_MANAGE_ORDERS = "expended_manage_orders"; // network privilege
export const MUST_UPLOAD_FILE = 17;
export const HIDE_UPDATE_VAT_BUTTON = 60;
export const AUTO_FILL_PAYMENT_FIELDS = "auto_fill_payment_fields"; // network privilege
export const ORDER_TO_INVENTORY = "order_to_inventory";
export const OCR_INVOICES = "ocr_invoices";
export const NEGATIVE_INVENTORY = "negative_inventory";
export const LOCK_ORDER = 72;
export const DISABLE_ORDER_BUTTON = 73;
export const ASSISGNED_NUMBER_NOT_REQUIRED = "assigned_number_not_required";
export const SHOW_ACCEPT_PETTY_CASH_DOCUMENT = 78;
//new orders
export const SHOW_FIX_INVENTORY_ON_ITEMS_TABLE = "fix_inventory_new_orders"; // network privilege
export const SHOW_DAILY_FIX_INVENTORY_ON_ITEMS_TABLE =
  "daily_fix_inventory_new_orders"; // network privilege

export const ALLOW_EDIT_FIX_INVENTORY_ON_ITEMS_TABLE = 62;

//reports
export const SHOW_ONLY_MY_EMPLOYEE_REPORTS = 63;

//tabs
export const MANAGE_ORDERS_EXPORT = 15;
export const INVENTORY_INVENTORY_COUNT = 16;
export const INVENTORY_TRANSFER = 17;
export const INVENTORY_PULL = 18;
export const INVENTORY_THROW = 19;
export const INVENTORY_EXPENDITURE = 20;
export const INVENTORY_DAILY_COUNT = 21;
export const INVENTORY_DASHBOARD = 22;
export const INVENTORY_PRODUCTION = 23;

//inventory
export const CONDITIONAL_TRANSFER = "conditional_transfer";
export const FIFO = "fifo";
export const PREVENT_UPDATE_COUNT_TYPE_INVENTORY = 76;

// products
export const PRODUCTS_VIEW_ONLY = 66;
export const PRODUCTS_HIDE_PRICES = 67;

// orders
export const MONTHLY_OBLIGATION = "monthly_obligation";

// copied from privilegesButtons.js file
export const HIDE_PRICE_PRIVILEGE = 9;
export const EDIT_SCHEDULE_PRIVILEGE = 8;
export const DISABLE_NOTES_PRIVILEGE = 10;
export const MUST_PICK_DATE_PRIVILEGE = 38;
export const EDIT_INSIDE_REPORTS_PRIVILEGE = 49;
export const LIMIT_CHANGE_SCHEDULE_PRIVILEGE = 23;
export const CALC_TIPS_ONLY_PRIVILEGE = 25;
export const BLOCK_TO_SEE_OTHERS_PRIVILEGE = 20;
export const CAN_LOCK_DAYS_PRIVILEGE = 48;
export const CANT_EDIT_SELF_PRIVILEGE = 34;
export const HIDE_DASHBOARD_HOME_PAGE = 33;

export const SHOW_WAITER_REPORT_PRIVILEGE = 59;
export const NETWORK_MARKUP = "network_markup";

//items priviligies
export const ENABLE_ITEM = 19;
export const SWEEPING_CHANGE = 5;
export const NEW_ITEM = 6;
export const ITEMS_EXPORT = 31;
export const EDIT_ITEM = 51;
export const EDIT_ITEM_PRICE = 79;

// pages
export const SPECIAL_REQUEST_SCHEDULE = 72;

//Knowledge Management
export const EDIT_PROCEDURES = 64;

//Tasks
export const EDIT_TASKS = 64;

export const HIDE_REPORTS_PRIVILEGE = 68;
export const SHOW_PLANNED_COSTS = 71;
export const HIDE_TABLE_SETTINGS = 75;

//employee types filter

export const FILTER_EMPLOYEE_TYPES = "employee_types";

// new received orders
export const REGISTER_PRICE_GAP_TO_FC = "registering_price_gap_to_fc";
export const ORDER_BY_SUM_DEPOSIT_AND_TAX = "order_by_sum_deposit_and_tax";
export const RECEIVE_ORDER_NO_ITEMS = "receive_order_no_items";
export const SWEEPING_STATUS = "sweeping_status";

export const SORT_PRODUCTS_BY_ORDER_COLLECTION =
  "sort_products_by_order_collection";

export const FILTER_RECEIVED_DATE = "filter_received_date";
