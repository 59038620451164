import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import ErrorMessage from "../ErrorMessage";
import { debounce, isFunction, isNil } from "lodash";
import moment from "moment";

const FormTextField = ({
  name,
  rules,
  defaultValue,
  type = null,
  fullWidth = false,
  disabledBlack = false,
  dibounced = false,
  handleChange = () => {},
  formatDisplayValue = (value) => value,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      {...{
        control,
        name,

        rules,
        ...(!!defaultValue && { defaultValue }),

        render: ({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error },
        }) => {
          return (
            <TextField
              value={
                value
                  ? isFunction(formatDisplayValue)
                    ? formatDisplayValue(value)
                    : value
                  : value === 0
                  ? 0
                  : ""
              }
              onChange={(e) => {
                if (type === "number") {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    onChange(e);
                    handleChange({ name, value: e.target.value });
                  }
                } else {
                  onChange(e);
                  handleChange({ name, value: e.target.value });
                }
              }}
              onWheel={(e) => e.target.blur()}
              required={!!rules?.required}
              {...{
                fullWidth,
                type,
                inputRef: ref,
                onBlur,
                name,
                helperText: <ErrorMessage>{error?.message}</ErrorMessage>,
                ...props,
              }}
              sx={
                disabledBlack
                  ? {
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black",
                      },
                      "& label": {
                        WebkitTextFillColor: "black",
                      },
                    }
                  : {}
              }
              error={!!error}
            />
          );
        },
      }}
    />
  );
};
export default FormTextField;
