import {
  SALARY_PER_HOUR,
  SALARY_BY_POSITION,
  SALARY_IS_VARIES,
  SALARY_BASIC_AND_BONUS,
  GLOBAL_SALARY,
  CONST_DAILY_TRAVEL_COST,
} from "./constants";
import { isWithinInterval, startOfYear, endOfYear } from "date-fns";
import { isObject } from "lodash";

export const getSalaryType = (salaryInfo) => {
  const { global_salary, salary, salary_type } = salaryInfo;
  let salaryType = null;
  let salaryAmount = null;
  if (global_salary != 0) {
    salaryType = "גלובאלי";
    salaryAmount = global_salary;
  } else if (salary != 0) {
    salaryType = "שעתי";
    salaryAmount = salary;
  }

  switch (salary_type) {
    case SALARY_BY_POSITION:
      salaryType = "לפי תפקיד";
      salaryAmount = null;
      break;
    case SALARY_IS_VARIES:
      salaryType = "שכר משתנה";
      salaryAmount = null;
      break;
    case SALARY_BASIC_AND_BONUS:
      salaryType = "בסיס + תוספת";
      salaryAmount = null;
      break;
    default:
      break;
  }
  return { type: salaryType, amount: salaryAmount };
};

export const getDefaultSalaryType = (salary_type, global_salary) => {
  const typeMap = {
    [SALARY_BY_POSITION]: SALARY_BY_POSITION,
    [SALARY_IS_VARIES]: SALARY_IS_VARIES,
    [SALARY_BASIC_AND_BONUS]: SALARY_BASIC_AND_BONUS,
  };
  let globalOrHourly = SALARY_PER_HOUR;
  if (global_salary != 0) {
    globalOrHourly = GLOBAL_SALARY;
  }

  return typeMap[salary_type] || globalOrHourly;
};

export const getSalaryTypeGlobalOrHourly = (global_salary, salary) => {
  // NOT IN USE
  let salaryType = null;
  let salaryAmount = null;
  if (global_salary != 0) {
    salaryType = "גלובאלי";
    salaryAmount = global_salary;
  } else if (salary != 0) {
    salaryType = "שעתי";
    salaryAmount = salary;
  }
  return { salaryType, salaryAmount };
};

export const getTravelType = (travelInfo) => {
  const { const_travel_cost, travel_cost, travel_cost_type } = travelInfo;
  let travelType = null;
  let travelAmount = null;
  if (const_travel_cost !== 0) {
    travelType = "קבוע";
    travelAmount = const_travel_cost;
  } else if (travel_cost_type === CONST_DAILY_TRAVEL_COST) {
    travelType = "יומי קבוע";
    travelAmount = travel_cost;
  } else {
    travelType = "יומי";
    travelAmount = travel_cost;
  }
  return { type: travelType, amount: travelAmount };
};

export const truncateText = (text, maxLengthOfString) => {
  if (typeof text !== "string") {
    return text;
  }
  return text.length > maxLengthOfString
    ? "..." + text.substring(0, maxLengthOfString)
    : text;
};

export const isNotInLastYear = (date) => {
  const today = new Date();
  const currentYear = today.getFullYear();

  const lastYearStart = startOfYear(new Date(currentYear, 0, 1));
  const lastYearEnd = endOfYear(new Date(currentYear, 11, 31));

  const isInLastYearRange = isWithinInterval(new Date(date), {
    start: lastYearStart,
    end: lastYearEnd,
  });

  return !isInLastYearRange ? 1 : 0;
};

export const generateSubtypeObject = (subtypes) => {
  const result = subtypes.reduce((acc, item) => {
    if (
      !item.subtype ||
      !item.subtype.value ||
      !item.salary ||
      !item.salary_type
    ) {
      return acc;
    }
    let salaryOfSubtype = item.salary;
    const salaryType = item.salary_type.value;
    if (isObject(salaryOfSubtype) && salaryType === SALARY_IS_VARIES) {
      salaryOfSubtype = Object.fromEntries(
        Object.entries(item.salary).map(([key, value]) => [key.slice(1), value])
      );
    }
    acc[item.subtype.value] = {
      subtype: item.subtype.value,
      salary: salaryOfSubtype,
      salary_type: item.salary_type.value,
    };
    return acc;
  }, {});
  return result;
};

export const updateField = (fields, key, value) => {
  fields[key] = value;
};

export const updateBranch = (fields, user) => {
  fields["branch_id"] = user.branch.value;
};

export const updatePrivilege = (fields, user) => {
  fields["privileges_id"] = user.privilege.value;
};

export const updateJobTitle = (fields, user, dirtyFields = null) => {
  if (dirtyFields?.job_title || user.job_title) {
    fields["type"] = user.job_title.value;
  }
};

export const updateInfo = (fields, user, dirtyFields = null) => {
  fields["info"] = {
    salary_type: user.info.salary_type?.value,
  };

  if ((dirtyFields ?? user)?.info?.new_salary_start_date) {
    fields.info.new_salary_start_date = user.info.new_salary_start_date;
  }

  if ((dirtyFields ?? user)?.info?.salary) {
    fields.info.salary = user.info.salary;
  }

  if ((dirtyFields ?? user)?.info?.global_salary) {
    fields.info.global_salary = user.info.global_salary;
  }

  if ((dirtyFields ?? user)?.info?.salary_by_subtype) {
    fields.info.salary_by_subtype = generateSubtypeObject(
      user.info.salary_by_subtype
    );
  }

  if ((dirtyFields ?? user)?.info?.base_salary) {
    fields.info.base_salary = user.info.base_salary;
  }

  if ((dirtyFields ?? user)?.info?.travel_cost) {
    fields.info.travel_cost = user.info.travel_cost;
  }
  if ((dirtyFields ?? user)?.info?.travel_cost_start_date) {
    fields.info.travel_cost_start_date = user.info.travel_cost_start_date;
  }
  if ((dirtyFields ?? user)?.info?.const_travel_cost) {
    fields.info.const_travel_cost = user.info.const_travel_cost;
  }
  if ((dirtyFields ?? user)?.info?.travel_cost_type) {
    fields.info.travel_cost_type = user.info.travel_cost_type.value;
  }

  if ((dirtyFields ?? user)?.info?.global_cost) {
    fields.info.global_cost = user.info.global_cost;
  }
  if (
    (dirtyFields ?? user)?.info?.repeated_expenses_id ||
    (dirtyFields ?? user)?.info?.repeated_expenses_id === 0
  ) {
    fields.info.repeated_expenses_id = user.info.repeated_expenses_id;
  }

  if ((dirtyFields ?? user)?.info?.extra_shabat) {
    fields.info.extra_shabat = user.info.extra_shabat;
  }

  if ((dirtyFields ?? user)?.info?.health_cost) {
    fields.info.health_cost = user.info.health_cost;
  }

  if ((dirtyFields ?? user)?.info?.holiday_cost) {
    fields.info.holiday_cost = user.info.holiday_cost;
  }

  if ((dirtyFields ?? user)?.info?.forced_work_stimulant) {
    fields.info.forced_work_stimulant = user.info.forced_work_stimulant;
  }

  if ((dirtyFields ?? user)?.info?.employer_cost) {
    fields.info.employer_cost = user.info.employer_cost;
  }
  if ((dirtyFields ?? user)?.info?.calc_hours_settings) {
    let calcHourObject = {};
    user.info.calc_hours_settings.map(
      (setting) => (calcHourObject[setting.id] = "on")
    );
    fields.info.calc_hours_settings = calcHourObject;
  }
  if ((dirtyFields ?? user)?.info?.day_length_level) {
    fields.info.day_length_level = user.info.day_length_level.value;
  }
  if ((dirtyFields ?? user)?.info?.day_length_type) {
    fields.info.day_length_type = user.info.day_length_type.value;
  }
  if ((dirtyFields ?? user)?.info?.day_length) {
    fields.info.day_length = user.info.day_length;
  }
  if ((dirtyFields ?? user)?.info?.has_break) {
    fields.info.has_break = user.info.has_break.value;
  }
  if ((dirtyFields ?? user)?.info?.fixed_hours) {
    fields.info.fixed_hours = user.info.fixed_hours.value;
  }
  if ((dirtyFields ?? user)?.info?.hours_calc_method) {
    fields.info.hours_calc_method = user.info.hours_calc_method.value;
  }
  if ((dirtyFields ?? user)?.info?.night_shift_length_level) {
    fields.info.night_shift_length_level =
      user.info.night_shift_length_level.value;
  }
  if ((dirtyFields ?? user)?.info?.night_shift_length) {
    fields.info.night_shift_length = user.info.night_shift_length;
  }
  if ((dirtyFields ?? user)?.info?.day_length_by_day) {
    fields.info.day_length_by_day = user.info.day_length_by_day;
  }
  if ((dirtyFields ?? user)?.info?.min_tip_method) {
    fields.info.min_tip_method = user.info.min_tip_method.value;
  }
  if ((dirtyFields ?? user)?.info?.min_tips) {
    fields.info.min_tips = user.info.min_tips;
  }
  if ((dirtyFields ?? user)?.info?.tips_allowance_method) {
    fields.info.tips_allowance_method = user.info.tips_allowance_method.value;
  }
  if ((dirtyFields ?? user)?.info?.tips_allowance) {
    fields.info.tips_allowance = user.info.tips_allowance;
  }
  if ((dirtyFields ?? user)?.info?.privileges_id) {
    fields.info.privileges_id = user.info.privileges_id.value;
  }
  if ((dirtyFields ?? user)?.info?.micpal_number) {
    fields.info.micpal_number = user.info.micpal_number;
  }
  if ((dirtyFields ?? user)?.info?.number) {
    fields.info.number = user.info.number;
  }
  if ((dirtyFields ?? user)?.info?.min_tips_by_shift_day) {
    fields.info.min_tips_by_shift_day = user.info.min_tips_by_shift_day;
  }
  if ((dirtyFields ?? user)?.info?.employer_cost) {
    fields.info.employer_cost = user.info.employer_cost.value;
  }
};

export const generateUniqueEmail = (
  prefix = "employee_",
  domain = "@restigo.co.il"
) => {
  // Generate a random unique string
  const uniqueString = Math.random().toString(36).substring(2, 10); // Generates an 8-character random string

  // Combine prefix, unique string, and domain
  const email = `${prefix}${uniqueString}${domain}`;

  return email;
};
