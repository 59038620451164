import axios from "axios";

// if (process.env.NODE_ENV === "development")
//   axios.defaults.baseURL = "http://restigo.test/api/";
// if (process.env.NODE_ENV === "production")
//   axios.defaults.baseURL = "https://php7.restigo.co.il/api/";
// axios.defaults.baseURL = "http://restigo.test/api/";
// axios.defaults.baseURL = ;

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
export const BASE_URL = axios.defaults.baseURL.replace("/api/", "");

class AuthService {
  login(email, pass, remember_me) {
    var formData = new FormData();
    formData.append("email", email);
    formData.append("password", pass);
    formData.append("remember_me", remember_me);
    return axios.post("jwtlogin", formData).then((res) => {
      if (res.status === 401) {
        throw new Error("Auth Error");
      }
      //  this.interceptAuthRequest(res.data.data.auth_token);
      return res.data.data;
    });
  }
  // interceptAuthResponse(setUser) {
  //   axios.interceptors.response.use(
  //     (res) => res,
  //     (error) => {
  //       if (error.response.status === 401) {
  //         setUser(null);
  //       }
  //       return error;
  //     }
  //   );
  // }
  // interceptAuthRequest(token) {
  //   axios.interceptors.request.use((config) => {
  //     config.headers = {Authorization:`Bearer ${token}`}
  //     return config;
  //   });
  // }
  // async tokenLogin() {
  //   // await this.interceptAuthRequest(token);
  //   const response = await axios.post("jwttokenlogin");
  //   return response.data;
  // }
}
export default new AuthService();
