import { Button, IconButton, Typography } from "@mui/material";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AiFillInfoCircle } from "react-icons/ai";
import React, { useMemo } from "react";
import {
  PriceCell,
  PriceFooter,
  CountFooterNum,
} from "../../components/tables/components/PriceFooter";
import {
  fixDate,
  fixPrice,
  thousandSeparator,
  twoZeroAfterDot,
  getSafeDivision,
} from "../../helpers";
import BtnTooltip from "../../components/tooltips/BtnTooltip";
import SimpleTable from "../orders/manageOrders/receiveOrder/SimpleTable";
import { userColumns } from "./userColumns";
import { sumFooter } from "../../components/tables/helpers";

export const dHiddenColumns = [
  "travel_cost",
  "meal_cost",
  "health_cost",
  "holiday_cost",
  "global_cost",
  "hours_100",
  "hours_125",
  "hours_150_shabat",
  "hours_150",
  "hours_175",
  "hours_200",
  "break",
  "hours_total",
  "tip_complete_cost",
  "bonus_cost",
  "total_cost_before_employer_cost",
  "employer_cost",
  "total_cost_after_employer_cost",
  "avg",
  "navg",
];

export const dDefaultVisibleColumns = [
  "hours_100",
  "hours_125",
  "hours_150_shabat",
  "hours_150",
  "hours_175",
  "hours_200",
  "hours_total",
  "tip_complete_cost",
  "bonus_cost",
  "total_cost_before_employer_cost",
  "employer_cost",
  "total_cost_after_employer_cost",
];

export const columnDepByDate = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: "",
    Footer: "סה״כ",
    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <IconButton
          {...row.getToggleRowExpandedProps({
            // style: {
            //   // We can even use the row.depth property
            //   // and paddingLeft to indicate the depth
            //   // of the row
            //   paddingRight: `${row.depth * 2}rem`,
            // },
          })}
          aria-label="expand row"
          size="small"
          style={{ color: "inherit" }}
        >
          {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </IconButton>
      ) : null,
  },
  {
    Header: "מחלקה",
    accessor: "department_name", //SORTTYPE
    Cell: (props) => {
      const { oDisplayTableMemo } = props;
      if (!("sub_rows" in props.row.original) && !oDisplayTableMemo)
        return <span>{props.row.original.department_name}</span>;
      else
        return (
          <Button
            onClick={() => {
              oDisplayTableMemo(
                Object.values(props.row.original?.sub_rows),
                " פירוט שעות חודשי עבור מחלקה " +
                  props.row.original.department_name,
                userColumns,
                false,
                props.row.original.department_name
              );
            }}
            style={{
              color: `#00a2ea`,
              textDecoration: "underline",
              fontSize: "100%",
            }}
          >
            {props.row.original.department_name}
          </Button>
        );
    },
  },
  {
    Header: "עלות מעביד",
    accessor: "employer_cost",
    Cell: (props) => {
      let tp = Number(
        ((props.row.original?.total_cost_after_employer_cost -
          props.row.original?.total_cost_before_employer_cost) /
          props.row.original.total_cost_before_employer_cost) *
          100
      );
      if (!props.row?.original?.employer_cost)
        props.row.original.employer_cost = Number(
          props.row.original.total_cost_after_employer_cost -
            props.row.original.total_cost_before_employer_cost +
            getSafeDivision(tp, 100) *
              (props.row?.original?.bonus_cost ||
                0 + props.row?.original?.monthly_bonus_amount ||
                0)
        ).toFixed(2);
      return (
        <div>
          <div className="money">
            {props.row.original?.employer_cost
              ? fixPrice(twoZeroAfterDot(props.row.original.employer_cost))
              : "₪" + 0}
          </div>
          <div style={{ margin: "auto" }}>
            ({!isNaN(tp) && isFinite(tp) ? tp.toFixed(2) + "%" : 0 + "%"})
          </div>
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () =>
          self.rows.reduce((a, { original }) => {
            if (!self.rows.length) return 0;
            if (typeof a == "object") {
              const v1 = !isNaN(parseFloat(a.original.employer_cost))
                ? parseFloat(a.original.employer_cost)
                : 0;
              const v2 = !isNaN(parseFloat(original.employer_cost))
                ? parseFloat(original.employer_cost)
                : 0;
              //console.log(v1 ,v2);
              return v1 + v2;
            }
            const v3 = !isNaN(parseFloat(original.employer_cost))
              ? parseFloat(original.employer_cost)
              : 0;
            //console.log(v3,a);
            return a + parseFloat(v3);
          }, 0),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: () => {
      //return <span><AiFillInfoCircle  title="שכר עובד+נסיעות+בונוס+השלמה לטיפ מינימום" alt="שכר עובד+נסיעות+בונוס+השלמה לטיפ מינימום"></AiFillInfoCircle> <label>שכר כולל ע״מ</label></span>;
      return (
        <BtnTooltip
          placement="top"
          text={"שכר עובד+נסיעות+בונוס+השלמה לטיפ מינימום"}
        >
          <div>שכר כולל ע״מ</div>
        </BtnTooltip>
      );
    },
    cleanHeader: "שכר כולל ע״מ",
    accessor: "total_cost_after_employer_cost",
    Cell: (props) => {
      const rval =
        props.row.original?.total_cost_after_employer_cost > 0
          ? props.row.original.total_cost_after_employer_cost
          : 0;
      const cost = props.row.original?.bonus_cost
        ? props.row.original.bonus_cost
        : 0;
      const monthly_bonus_amount = props.row.original?.monthly_bonus_amount
        ? props.row.original.monthly_bonus_amount
        : 0;
      const total_cost_after = rval + cost + monthly_bonus_amount;

      const goal_avg = props.row.original?.navg > 0;
      if (goal_avg > 0) {
        return (
          <b
            style={
              {
                //color:(rval<goal_avg) ? "#74cb74":"#e98b8e",
              }
            }
          >
            {fixPrice(total_cost_after)}
          </b>
        );
      } else {
        return fixPrice(total_cost_after);
      }
    },
    Footer: PriceFooter,
  },
  {
    Header: "אחוז מההכנסה",
    accessor: "total_precent",
    Cell: (props) => {
      const rval =
        props.row.original.total_precent > 0 &&
        isFinite(props.row.original.total_precent)
          ? props.row.original.total_precent
          : 0;
      const goal_avg =
        props.row.original?.avg > 0 ? props.row.original?.avg : 0;
      //console.log(rval,goal_avg);
      if (goal_avg > 0) {
        return (
          <b
            style={{
              color: rval > goal_avg ? "#e98b8e" : "#74cb74",
            }}
          >
            {rval + "%"}
          </b>
        );
      } else {
        return isNaN(rval) || !isFinite(rval) ? 0 + "%" : rval + "%";
      }
    },
  },
  // {
  //   Header: "צפי סוף חודש",
  //   accessor: "diners",
  // },
  {
    Header: "יעד ב₪",
    accessor: "navg",
    Cell: (props) => {
      const tc = props.row.original?.total_cost_after_employer_cost;
      if (props.row.original.navg) {
        if (props.row.original.navg > -1) {
          return (
            <b
              style={
                {
                  //color:(tc<props.row.original.navg) ? "#74cb74":"#e98b8e",
                }
              }
            >
              {"₪" + thousandSeparator(props.row.original.navg)}
            </b>
          );
        } else {
          return (
            <b
              style={
                {
                  //color:(tc>props.row.original.navg) ? "#74cb74":"#e98b8e",
                }
              }
            >
              {""}
            </b>
          );
        }
      } else {
        return (
          <b
            style={
              {
                //color:(tc>props.row.original.navg) ? "#74cb74":"#e98b8e",
              }
            }
          >
            {""}
          </b>
        );
      }
    },
  },
  {
    Header: "יעד ב%",
    accessor: "avg",

    Cell: (props) => {
      const pr = props.row.original?.total_precent;
      if (props.row.original.avg) {
        if (props.row.original.avg > -1) {
          return (
            <b
              style={
                {
                  //color:(pr<props.row.original.avg) ? "#74cb74":"#e98b8e",
                }
              }
            >
              {props.row.original.avg + "%"}
            </b>
          );
        } else {
          return (
            <b
              style={
                {
                  //color:(pr>props.row.original.avg) ? "#74cb74":"#e98b8e",
                }
              }
            >
              {""}
            </b>
          );
        }
      } else {
        return (
          <b
            style={
              {
                //color:(pr>props.row.original.avg) ? "#74cb74":"#e98b8e",
              }
            }
          >
            {""}
          </b>
        );
      }
    },
  },
  {
    Header: "100%",
    accessor: "hours_100",
    Cell: (props) => {
      const hours = props.row.original?.hours_100
        ? props.row.original.hours_100
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "125%",
    accessor: "hours_125",
    Cell: (props) => {
      const hours = props.row.original?.hours_125
        ? props.row.original.hours_125
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "150%",
    accessor: "hours_150",
    //Cell: (props) => Number(props.row.original.hours_150).toFixed(2),
    Cell: (props) => {
      const hours = props.row.original?.hours_150
        ? props.row.original.hours_150
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "שבת + חג",
    accessor: "hours_150_shabat",
    Cell: (props) => {
      const hours = props.row.original?.hours_150_shabat
        ? props.row.original.hours_150_shabat
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "175%",
    accessor: "hours_175",
    //Cell: (props) => Number(props.row.original.hours_175).toFixed(2),
    Cell: (props) => {
      const hours = props.row.original?.hours_175
        ? props.row.original.hours_175
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "200%",
    accessor: "hours_200",
    //Cell: (props) => Number(props.row.original.hours_200).toFixed(2),
    Cell: (props) => {
      const hours = props.row.original?.hours_200
        ? props.row.original.hours_200
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    Footer: CountFooterNum,
  },
  {
    Header: "הפסקה",
    accessor: "break",
    Footer: CountFooterNum,
  },
  {
    Header: "סה״כ שעות",
    accessor: "hours_total",
    Cell: (props) => {
      const hours = props.row.original?.hours_total
        ? props.row.original.hours_total
        : 0;
      return <div className="money">{`${twoZeroAfterDot(hours)}`}</div>;
    },
    //Footer: CountFooterNum,
    Footer: (self) => {
      const total = useMemo(
        () => self.rows.reduce((sum, row) => row.values.hours_total + sum, 0),
        [self.rows]
      );

      return <>{total.toFixed(2)}</>;
    },
  },

  {
    Header: "עלות נסיעות",
    accessor: "travel_cost",
    Cell: (props) => {
      const cost = props.row.original?.travel_cost
        ? props.row.original.travel_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },
    //Cell: (props) => (props.row?.original?.travel_cost) ?  "₪"+Number(props.row.original.travel_cost).toFixed(2):"₪"+0,
    Footer: PriceFooter,
  },
  {
    Header: "עלות אוכל",
    accessor: "meal_cost",
    Cell: (props) => "₪" + Number(props.row.original?.meal_cost).toFixed(2),
    Footer: PriceFooter,
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"דמי הבראה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        דמי הבראה
      </>
    ),
    accessor: "health_cost",
    Cell: (props) => {
      const cost = props.row.original?.health_cost
        ? props.row.original.health_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },

    width: 1,
    Footer: PriceFooter,
  },
  {
    Header: () => (
      <>
        <BtnTooltip text={"תעריף חופשה"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        תעריף חופשה
      </>
    ),
    accessor: "holiday_cost",
    Cell: (props) => {
      const cost = props.row.original?.holiday_cost
        ? props.row.original.holiday_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "holiday_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },

  {
    Header: () => (
      <>
        <BtnTooltip text={"תעריף שעות נוספות גלובלית"}>
          <i style={{ fontSize: "1.4rem" }} className="fas fa-info-circle"></i>
        </BtnTooltip>
        תעריף שעות נוספות גלובלית
      </>
    ),
    accessor: "global_cost",
    Cell: (props) => {
      const cost = props.row.original?.global_cost
        ? props.row.original.global_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },
    width: 1,
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "global_cost"),
        [self.rows]
      );

      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(total))}
        </div>
      );
    },
  },
  {
    Header: "השלמה לטיפ",
    accessor: "tip_complete_cost",

    //Cell: (props) => "₪"+Number(props.row.original?.tip_complete_cost).toFixed(2),
    Cell: (props) => {
      const cost = props.row.original?.tip_complete_cost
        ? props.row.original.tip_complete_cost
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${twoZeroAfterDot(cost)}`}
        </div>
      );
    },

    Footer: PriceFooter,
  },
  {
    Header: "עלות בונוס",
    accessor: "bonus_cost",
    Cell: (props) => {
      const cost = props.row.original?.bonus_cost
        ? props.row.original.bonus_cost
        : 0;
      const monthly_bonus_amount = props.row.original?.monthly_bonus_amount
        ? props.row.original.monthly_bonus_amount
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {`${thousandSeparator(twoZeroAfterDot(cost + monthly_bonus_amount))}`}
        </div>
      );
    },
    //Cell: (props) => (props.row?.original?.travel_cost) ?  "₪"+Number(props.row.original.travel_cost).toFixed(2):"₪"+0,
    Footer: (self) => {
      const totalBonus = useMemo(
        () => sumFooter(self.rows, "bonus_cost"),
        [self.rows]
      );
      const totalDashboardBonus = useMemo(
        () => sumFooter(self.rows, "monthly_bonus_amount"),
        [self.rows]
      );
      return (
        <div className="money money__footer">
          <span>&#8362;</span>
          {thousandSeparator(twoZeroAfterDot(totalBonus + totalDashboardBonus))}
        </div>
      );
    },
  },

  {
    Header: () => {
      //return <span><AiFillInfoCircle title="שכר עובד ללא נסיעות ללא בונוס ללא השלמה לטיפ מינימום" alt="שכר עובד ללא נסיעות ללא בונוס ללא השלמה לטיפ מינימום"></AiFillInfoCircle> <label>שכר לפני ע״מ</label></span>;
      return (
        <BtnTooltip
          placement="top"
          text={"שכר עובד ללא נסיעות ללא בונוס ללא השלמה לטיפ מינימום"}
        >
          <div>
            <label>שכר לפני ע״מ</label>
          </div>
        </BtnTooltip>
      );
    },
    accessor: "total_cost_before_employer_cost",
    cleanHeader: "שכר לפני ע״מ",
    Cell: (props) => {
      const { total_cost_before_employer_cost } = props.row.original;
      const cost = props.row.original?.bonus_cost
        ? props.row.original.bonus_cost
        : 0;
      const monthly_bonus_amount = props.row.original?.monthly_bonus_amount
        ? props.row.original.monthly_bonus_amount
        : 0;
      return fixPrice(
        total_cost_before_employer_cost + cost + monthly_bonus_amount
      );
    },
    Footer: PriceFooter,
  },
];
export const excellAggregates = [
  { field: "hours_100", aggregate: "sum" },
  { field: "hours_125", aggregate: "sum" },
  { field: "hours_150", aggregate: "sum" },
  { field: "hours_150_shabat", aggregate: "sum" },
  { field: "hours_175", aggregate: "sum" },
  { field: "hours_200", aggregate: "sum" },
  { field: "hours_total", aggregate: "sum" },
  { field: "cost_raw", aggregate: "sum" },
  { field: "cost_total", aggregate: "sum" },
  { field: "total_with_vat_after_fees", aggregate: "sum" },
];

export const dateBreakdownSubColumns = [
  {
    Header: "סוג",
    accessor: "type",
  },
  {
    Header: "סה״כ ללא  ע״מ",
    accessor: "cost_raw",
    Cell: PriceCell,
  },
  {
    Header: "סה״כ כולל ע״מ",
    accessor: "cost_total",
    Cell: PriceCell,
  },
  {
    Header: "עמלות",
    accessor: "travel_cost",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  {
    Header: "סה״כ אחרי עמלות",
    accessor: "total_including_tips",
    Cell: PriceCell,
    Footer: PriceFooter,
  },
  // {
  //   Header: "סועדים",
  //   accessor: "diners",
  // },
  // {
  //   Header: "ממוצע לסועד",
  //   accessor: "diners_avg",
  // },
  {
    Header: "חלוקה %",
    accessor: "percent",
    Cell: ({ value }) => `${value.toFixed(1)}%`,
  },
];

export const mobileDateBreakdownColumns = (setDialogModal) => [
  {
    Header: "",
    accessor: "id",
    Cell: ({ row }) => {
      const {
        hebrew_create_date,
        department_name,
        cost_raw,
        cost_total,
        z_with_vat,
        diners,
        diners_avg,
        z_numbers,
        transaction_date,
        date,
      } = row.original;
      return (
        <div>
          <Typography align="center" component="div">
            <h2 style={{ display: "inline" }}>{`${hebrew_create_date} ${fixDate(
              date,
              "DD/MM"
            )}`}</h2>
            {/* <h2 style={{ display: "inline" }}>{`${day} ${fixDate(hebrew_date,'MM/DD')}`}</h2> */}
            {/* <IconButton
              color="secondary"
              onClick={() =>
                setDialogModal({
                  iconJSX: () => IoMdPricetag({ size: "7em" }),
                  title: `נתונים נוספים לתאריך ${hebrew_date}`,
                  showConfirmButton: false,
                  showAbortButton: false,
                  text: (
                    <SimpleTable
                      columns={[
                        {
                          title: "פירוט",
                          name: "key",
                          render: (data, row) => {
                            return data;
                          },
                        },
                        {
                          title: "ערך",
                          name: "value",
                          render: (data, row) => {
                            return data;
                          },
                        },
                      ]}
                      data={[
                        { key: `Z כולל מע״מ`, value: fixPrice(z_with_vat) },
                        { key: `מס' סועדים`, value: diners },
                        { key: `ממוצע לסועד`, value: fixPrice(diners_avg) },
                        { key: `מס' Z`, value: z_numbers },
                      ]}
                    />
                  ),
                })
              }
            >
              <IoMdPricetag />
            </IconButton> */}
          </Typography>
          <div className="flex-between">
            <div>
              <strong>{fixPrice(cost_raw)}</strong>
              <small style={{ marginRight: "1rem" }}>ללא מע״מ</small>
            </div>
            <div>
              <strong>{fixPrice(cost_raw)}</strong>
              <small style={{ marginRight: "1rem" }}>כולל מע״מ</small>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    accessor: "day",
    header: "",
    hide: true,
  },
];
