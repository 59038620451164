import { SET_ERROR, HIDE_ERROR } from "./types";

//set error
export const setError =
  (
    error = "ניתן לפנות לתמיכה הטכנית",
    errorTitle = "אירעה שגיאה בשרת",
    timeout = false,
    warning = false,
    isHtml = false
  ) =>
  async (dispatch) => {
    dispatch({ type: SET_ERROR, error, errorTitle, timeout, warning, isHtml });
  };
//hide error
export const hideError = () => async (dispatch) => {
  dispatch({ type: HIDE_ERROR });
};
